import axios from "axios";
import Cookies from "js-cookie";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchUserInfo = async () => {
  const token = Cookies.get("token");
  if (!token) return null;

  try {
    const response = await axios.get(`${API_URL}user-info`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error al obtener información del usuario:", error);
    return null;
  }
};
