import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "./useAuth";
import { CheckCircle, OctagonX, CircleAlert, Check, X } from "lucide-react";
import "../styles/components/alertStyle.css";
import DistribucionCostos from "../components/FacturasForms/FacturasInternas/distribucionCostos";
import TramiteFactura from "../components/FacturasForms/FacturasInternas/tramiteFacturas";
import { fetchItemsFacturados } from "../services/OpenService"; // Asegúrate de que esta ruta sea correcta.

/* import { useAlert } from "../hooks/useAlert"; // Hook personalizado para alertas
 */
export default function AlertComponent({
  type,
  onClose,
  setRows,
  cufe,
  emisor,
}) {
  const [groupedArray, setGroupedArray] = useState([]); // Estado para almacenar los grupos
  const [visible, setVisible] = useState(false);
  const [allCheckedCache, setAllCheckedCache] = useState(false);

  const [alertType, setAlertType] = useState(type); // Manejar el tipo de alerta dinámicamente
  const [disabledCheckboxes, setDisabledCheckboxes] = useState([]); // Almacena los productos deshabilitados
  const [selectedProducts, setselectedProducts] = useState([]); // Almacena los productos deshabilitados
  const [groupedProducts, setGroupedProducts] = useState({}); // Almacena el grupo de cada producto
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0); // Índice del grupo actual
  const [allDisabled, setAllDisabled] = useState(false); // Verifica si todos los productos están deshabilitados
  const gruposFacturaItems = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split(""); // Array de letras del abecedario
  const [groupingByGroupClicked, setGroupingByGroupClicked] = useState(false);
  const [groupingByAllClicked, setGroupingByAllClicked] = useState(false); // Controla el estado de Agrupar Todo
  const [formData, setFormData] = useState({
    distribucionxItems: "",
    distribucionxGrupos: "",
    centroCostos: "",
    distribucionPorcentaje: "",
  });
  const [productOptions, setProductOptions] = useState([]);

  const [caller, setCaller] = useState(null);
  const { forcelogout } = useAuth();
  const timeoutId = useRef(null);

  const closessionActive2 = async () => {
    const response = await forcelogout(); // Llama a la función forcelogout
    if (response.ok && onClose) onClose(); // Cierra la alerta después de cerrar la sesión
  };

  useEffect(() => {
    if (!alertType) return;

    setVisible(true);

    // Configurar temporizadores si no es estática
    if (
      alertType !== "sessionActive" &&
      alertType !== "groupItems" &&
      alertType !== "tramiteFactura" &&
      alertType !== "distribucionCostos" // Incluye la nueva alerta
    ) {
      timeoutId.current = setTimeout(() => {
        setVisible(false);
        setTimeout(() => {
          if (onClose) onClose();
        }, 1000);
      }, 2000);
    }

    return () => {
      clearTimeout(timeoutId.current);
    };
  }, [alertType, onClose]);

  useEffect(() => {
    if (alertType === "tramiteFactura") {
      // Si ya se guardó previamente que todo estaba checkeado, mantenlo
      if (allCheckedCache) {
        setAllDisabled(true);
        return;
      }

      // Detectar si todos los productos están seleccionados
      if (Array.isArray(selectedProducts) && selectedProducts.length > 0) {
        const allProductsChecked = selectedProducts.every((product) => {
          const checkbox = document.getElementById(product.id); // Accede al `id`
          return checkbox?.checked === true; // Verifica si el checkbox está marcado
        });

        setAllDisabled(allProductsChecked);

        // Guarda el estado en el cache temporal
        if (allProductsChecked) {
          setAllCheckedCache(true);
        }

        if (emisor) {
          console.log(
            `Validando productos habilitados para el emisor: ${emisor}`,
          );
        }
      } else {
        setAllDisabled(false);
      }
    } else if (alertType === "distribucionCostos" && caller === "noAgrp") {
      // Lógica para distribucionCostos, solo si el caller es "noAgrp"
      if (Array.isArray(selectedProducts) && selectedProducts.length > 0) {
        console.log(
          "Productos recibidos para DistribucionCostos (sin restricciones):",
          selectedProducts,
        );
        setProductOptions(
          selectedProducts.map((product) => ({
            label: product.descripcion, // Texto visible en el desplegable
            value: product.id, // Valor asociado
          })),
        );
      } else {
        console.warn("No hay productos válidos para DistribucionCostos.");
        setProductOptions([]); // Limpia las opciones si no hay productos
      }
    }
  }, [
    alertType,
    caller,
    disabledCheckboxes,
    selectedProducts,
    emisor,
    allCheckedCache, // Agrega la dependencia para que se reactive correctamente
  ]);

  // Obtener los ítems facturados al recibir un CUFE válido
  useEffect(() => {
    if (cufe && alertType === "tramiteFactura") {
      fetchItemsFacturados(cufe)
        .then((data) => {
          // Mapea el id y la descripción en objetos, pero solo muestra la descripción
          const items = data.items_facturados.map((item) => ({
            id: item.id,
            descripcion: item.descripcion,
          }));
          setselectedProducts(items); // Almacena los objetos en el estado
          console.log(
            "Ítems facturados mapeados con id y descripcion en AGRUPAR X ITEMS DESDE ALERTS JS:",
            items,
          );
        })
        .catch((error) => {
          console.error("Error al obtener los ítems facturados:", error);
        });
    }
  }, [cufe, alertType]);

  const getIcon = () => {
    if (
      alertType === "success" ||
      alertType === "saved" ||
      alertType === "invoiceApproved" ||
      alertType === "generated"
    ) {
      return <CheckCircle className="check-icon" />;
    } else if (
      alertType === "sessionActive" ||
      alertType === "groupItems" ||
      alertType === "tramiteFactura" ||
      alertType === "distribucionCostos" // Nuevo caso
    ) {
      return <CircleAlert className="session-icon" />;
    } else if (alertType === "invoiceRejected") {
      // Nueva alerta para Factura Rechazada
      return <OctagonX className="error-icon" />;
    } else {
      return <OctagonX className="error-icon" />;
    }
  };

  const getTextAndClass = () => {
    let text = "";
    let className = "";

    switch (alertType) {
      case "success":
        text = "Orden Aprobada!";
        className = "approval-text";
        break;
      case "invoiceApproved": // Nueva alerta para Factura Aprobada
        text = "Factura Aprobada!";
        className = "approval-text";
        break;
      case "saved":
        text = "Guardado Exitoso!";
        className = "approval-text";
        break;
      case "generated":
        text = "Orden Generada!";
        className = "approval-text";
        break;
      case "sessionActive":
        text = "! Tienes otra Sesión Activa ¡";
        className = "session-text";
        break;
      case "groupItems":
        text = "¿Desea agrupar los Items de la Factura?";
        className = "session-text";
        break;
      case "tramiteFactura":
        text = "Tramite Factura";
        className = "session-text";
        break;
      case "distribucionCostos": // Nuevo caso
        text = "Distribución de Costos";
        className = "session-text";
        break;
      case "invoiceRejected": // Nueva alerta para Factura Rechazada
        text = "Factura Rechazada!";
        className = "error-text";
        break;
      default:
        text = "Orden Rechazada!";
        className = "error-text";
        break;
    }

    return { text, className };
  };

  const { text, className } = getTextAndClass();

  const handAgrupar = (selectedProducts) => {
    if (!selectedProducts || selectedProducts.length === 0) {
      console.log("No hay productos seleccionados para agrupar.");
      return;
    }
    console.log("Productos recibidos en Agrupar:", selectedProducts);

    setTimeout(() => {
      setAlertType("tramiteFactura"); // Cambia a la alerta "tramiteFactura"
      setVisible(true);
    }, 500); // Retardo para transición suave
  };
  // Función que dispara la alerta
  const handleNoAgrupar = (selectedProducts) => {
    if (!selectedProducts || selectedProducts.length === 0) {
      console.log("No hay productos seleccionados para No-Agrupar.");
      return;
    }

    console.log("Productos recibidos en No-Agrupar:", selectedProducts);

    // Actualiza el estado del caller
    setCaller("noAgrp");

    // Cambia el tipo de alerta
    setAlertType("distribucionCostos");
  };

  const handAgruparTodo = () => {
    const selected = selectedProducts.filter(
      (product) => !groupedProducts[product.id],
    );

    if (selected.length === 0) {
      console.log("No hay productos disponibles para agrupar.");
      return;
    }

    const currentGroup = gruposFacturaItems[currentGroupIndex];
    console.log("Productos agrupados en Agrupar Todo:", selected);
    console.log(`Asignando todos al grupo: Grupo ${currentGroup}`);

    selected.forEach((product) => {
      const checkbox = document.getElementById(product.id);
      if (checkbox) {
        checkbox.checked = true;
        checkbox.disabled = true;
      }
    });

    setDisabledCheckboxes((prev) => [
      ...prev,
      ...selected.map((product) => product.id),
    ]);

    setGroupedProducts((prev) => {
      const updatedGroups = { ...prev };

      const groupedData = {
        tipo: "grupo",
        grupos: [],
      };

      selected.forEach((product) => {
        updatedGroups[product.id] = `Grupo_${currentGroup}`;
      });

      const groupedArray = Object.keys(updatedGroups).reduce(
        (acc, productId) => {
          const group = updatedGroups[productId];
          if (!acc[group]) {
            acc[group] = {
              grupo_id: group,
              items: [],
            };
          }
          acc[group].items.push({ id: productId });
          return acc;
        },
        {},
      );

      groupedData.grupos = Object.values(groupedArray);

      console.log(
        "Productos agrupados organizados en la estructura jerárquica:",
        groupedData,
      );

      setGroupedArray(groupedData); // Aseguramos que se pase el formato jerárquico aquí

      return updatedGroups;
    });

    setCurrentGroupIndex((prevIndex) =>
      prevIndex + 1 < gruposFacturaItems.length ? prevIndex + 1 : prevIndex,
    );

    setGroupingByAllClicked(true);
    console.log("Índice del grupo actualizado a:", currentGroupIndex + 1);
  };

  const handAgruparxItems = () => {
    // Encuentra todos los checkboxes seleccionados
    const selected = selectedProducts.filter(
      (product) =>
        document.getElementById(product.id)?.checked && // Verifica si está seleccionado
        !groupedProducts[product.id], // Verifica si no está ya agrupado
    );

    if (selected.length === 0) {
      console.log("No hay nuevos productos seleccionados para agrupar.");
      return;
    }

    setGroupingByGroupClicked(true); // Indica que se agrupó por grupo

    console.log("Productos seleccionados para agrupar:", selected);

    // Deshabilita los checkboxes seleccionados y asigna grupo
    setDisabledCheckboxes((prev) => [
      ...prev,
      ...selected.map((product) => product.id),
    ]);

    setGroupedProducts((prev) => {
      const updatedGroups = { ...prev };

      // Crear una estructura jerárquica para los productos agrupados
      const groupedData = {
        tipo: "grupo",
        grupos: [],
      };

      selected.forEach((product) => {
        const currentGroup = gruposFacturaItems[currentGroupIndex]; // Obtiene el grupo actual

        // Asigna el grupo actual al producto
        updatedGroups[product.id] = `Grupo_${currentGroup}`;

        // Incrementa el índice del grupo para asignar a cada producto un grupo distinto
        setCurrentGroupIndex((prevIndex) =>
          prevIndex + 1 < gruposFacturaItems.length ? prevIndex + 1 : 0,
        );
      });

      // Organizar los productos agrupados como arrays por grupo en el formato deseado
      const groupedArray = Object.keys(updatedGroups).reduce(
        (acc, productId) => {
          const group = updatedGroups[productId];
          if (!acc[group]) {
            acc[group] = {
              grupo_id: group,
              items: [],
            };
          }
          acc[group].items.push({ id: productId }); // Agrega el ID al array de items
          return acc;
        },
        {},
      );

      // Convertir a array de grupos y asignar a la estructura jerárquica
      groupedData.grupos = Object.values(groupedArray);

      console.log(
        "Productos agrupados organizados en la estructura jerárquica:",
        groupedData,
      );

      // Actualiza la estructura jerárquica en el estado global o pasa los datos a donde se necesiten
      setGroupedArray(groupedData.grupos);

      return updatedGroups;
    });

    console.log("Índice del grupo actualizado a:", currentGroupIndex);
  };

  const onFieldChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    console.log(`${field} actualizado con:`, value);
  };
  useEffect(() => {
    if (alertType === "groupItems") {
      console.log(
        `CUFE en AlertComponent: ${cufe}, Emisor en AlertComponent: ${emisor}`,
      );
    }
  }, [alertType, cufe, emisor]);

  return (
    <div className={`container ${visible ? "visible" : ""}`}>
      {alertType === "distribucionCostos" ? (
        <DistribucionCostos
          className={className}
          productOptions={productOptions}
          setRows={setRows} // Pasa setRows al componente
          cufe={cufe}
          formData={formData}
          setCaller={setCaller} // Pasar setCaller
          text={text}
          caller={caller} // Aquí pasamos el valor de caller
          emisor={emisor}
          onFieldChange={onFieldChange}
          onClose={onClose}
          setGroupedArray={setGroupedArray} // Pasa el setter para actualizar el estado
          setAlertType={setAlertType} // Pasar la función para cambiar el estado
          groupedArray={groupedArray} // Pasar los grupos formados
        />
      ) : alertType === "tramiteFactura" ? (
        <TramiteFactura
          className={className}
          text={text}
          cufe={cufe}
          onClose={onClose}
          selectedProducts={selectedProducts}
          setCaller={setCaller} // Pasar setCaller
          emisor={emisor}
          disabledCheckboxes={disabledCheckboxes}
          groupedProducts={groupedProducts}
          allDisabled={allDisabled}
          groupingByAllClicked={groupingByAllClicked}
          groupingByGroupClicked={groupingByGroupClicked}
          handAgruparxItems={handAgruparxItems}
          handAgruparTodo={handAgruparTodo}
          setAlertType={setAlertType}
          setGroupedArray={setGroupedArray} // Función para actualizar los grupos
          groupedArray={groupedArray} // Pasar groupedArray para asegurar que el estado esté sincronizado
        />
      ) : (
        <div
          className={`approval-box ${
            alertType === "sessionActive" || alertType === "groupItems"
              ? "session-active"
              : ""
          }`}
        >
          {getIcon()}
          <p className={className}>{text}</p>
          {alertType === "sessionActive" && (
            <div className="button-container">
              <button onClick={onClose} className="alert-button red">
                <X className="icon" />
                Volver al Login
              </button>
              <button
                onClick={closessionActive2}
                className="alert-button green"
              >
                <Check className="icon" />
                Cerrar Sesión Activa
              </button>
            </div>
          )}
          {alertType === "groupItems" && (
            <div className="button-container">
              <button
                onClick={() => handleNoAgrupar(true)}
                className="alert-button red"
              >
                <X className="icon" />
                No Agrupar
              </button>
              <button
                onClick={() => handAgrupar(true)}
                className="alert-button green"
              >
                <Check className="icon" />
                Agrupar Items
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
