import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  verificarAsociadoPorNit,
  buscarProveedorPorNit,
} from "../services/apiService";

const DocumentoInputGroup = ({
  tipoDocumento,
  setTipoDocumento,
  tiposIdentificacion,
  nit,
  dv,
  handleNitChange,
  handleChange,
  onFieldChange,
  nitProveedor,
}) => {
  const executionCountRef = useRef(0);

  useEffect(() => {
    if (nitProveedor && executionCountRef.current < 2) {
      console.log(
        "NIT Proveedor recibido en DocumentoInputGroup:",
        nitProveedor,
      );
      handleNitChange({ target: { value: nitProveedor } });
      onFieldChange("numero", nitProveedor);
      executionCountRef.current += 1; // Incrementar el contador de ejecuciones
    }
  }, [nitProveedor, handleNitChange, onFieldChange]);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const validateNit = async () => {
    if (nit) {
      try {
        const existe = await verificarAsociadoPorNit(nit);
        if (existe) {
          setErrorMessage("El número de identificación ya existe.");
          setSuccessMessage("");
          handleNitChange({ target: { value: "" } });
          onFieldChange("numero", "");
        } else {
          setErrorMessage("");
          setSuccessMessage("Número de identificación válido.");
          const proveedor = await buscarProveedorPorNit(nit);
          if (proveedor) {
            if (proveedor.total === 0) {
              return;
            }
            onFieldChange("razonSocial", proveedor.razonSocial || "");
            onFieldChange("correo", proveedor.correo || "");
            onFieldChange("direccion", proveedor.direccion || "");
            onFieldChange("telefono", proveedor.telefono || "");
            onFieldChange("primerNombre", proveedor.primerNombre || "");
            onFieldChange("primerApellido", proveedor.primerApellido || "");
            onFieldChange("segundoApellido", proveedor.segundoApellido || "");
          }
        }
      } catch (error) {
        setErrorMessage("Error al verificar el número.");
        setSuccessMessage("");
      }
    }
  };

  return (
    <div className="three-column-grid">
      <FormControl className="input-form-control" required>
        <InputLabel id="tipo-documento-label" className="input-label">
          Tipo de Documento
        </InputLabel>
        <Select
          labelId="tipo-documento-label"
          id="tipo-documento-juridica"
          value={tipoDocumento}
          label="Tipo de Documento"
          required
          onChange={(e) => {
            setTipoDocumento(e.target.value);
            handleChange(e);
            onFieldChange("tipoDocumento", e.target.value);
          }}
          className="input-select"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {tiposIdentificacion.map((tipo) => (
            <MenuItem key={tipo.codigo_dian} value={tipo.codigo_dian}>
              {tipo.descripcion}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        id="numero-juridica"
        label="Número"
        variant="outlined"
        className="input-textfield"
        required
        value={nit}
        onChange={(e) => {
          handleNitChange(e);
          onFieldChange("numero", e.target.value);
        }}
        onBlur={validateNit}
        error={!!errorMessage}
        helperText={errorMessage || successMessage || " "}
      />

      <TextField
        disabled
        id="dv-juridica"
        label="DV"
        variant="outlined"
        value={dv}
        className="input-textfield"
      />
    </div>
  );
};

export default DocumentoInputGroup;
