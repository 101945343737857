import React, { useState, useEffect, useRef } from "react";
import { CustomSelect, CustomDateField } from "../../Forms/CustomAutocomplete";
import { Button } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {
  fetchEstadosCausacion,
  fetchDocumentosOpen,
} from "../../../services/OpenService";
import { useGlobalContext } from "../../../context/GlobalContext"; // Importar el contexto global

export const calculateTimeDifference = (docDate, docTime, currentDate) => {
  try {
    if (!docDate || !docTime) throw new Error("Fecha u hora inválida.");

    const [hour, minute, second] = docTime.split(":").map(Number);
    const [year, month, day] = docDate.split("-").map(Number);
    const documentDate = new Date(year, month - 1, day, hour, minute, second);

    if (isNaN(documentDate.getTime())) throw new Error("Fecha inválida.");

    if (currentDate < documentDate) {
      console.warn("La fecha/hora del documento es futura.");
      return null; // No calcular si el documento está en el futuro
    }

    let totalMs = 0;
    let tempDate = new Date(documentDate);
    tempDate.setHours(0, 0, 0, 0);

    while (tempDate < currentDate) {
      const dayOfWeek = tempDate.getDay();

      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        const startOfDay = new Date(tempDate);
        const endOfDay = new Date(tempDate);
        endOfDay.setHours(23, 59, 59, 999);

        if (tempDate.toDateString() === documentDate.toDateString()) {
          totalMs += Math.min(
            endOfDay - documentDate,
            currentDate - documentDate,
          );
        } else if (tempDate.toDateString() === currentDate.toDateString()) {
          totalMs += Math.min(
            currentDate - startOfDay,
            currentDate - documentDate,
          );
        } else {
          totalMs += 24 * 60 * 60 * 1000;
        }
      }

      tempDate.setDate(tempDate.getDate() + 1);
    }

    const totalHours = Math.floor(totalMs / (1000 * 60 * 60));
    const totalMinutes = Math.floor((totalMs % (1000 * 60 * 60)) / (1000 * 60));

    return {
      total: {
        hours: totalHours,
        minutes: totalMinutes,
      },
    };
  } catch (error) {
    console.warn("Error en cálculo de diferencia de tiempo:", error.message);
    return null;
  }
};

const getDayOfWeek = (date) => {
  if (!date) return "";
  const days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];

  const [year, month, day] = date.split("-").map(Number);
  const parsedDate = new Date(year, month - 1, day);

  return isNaN(parsedDate.getTime())
    ? "Fecha inválida"
    : days[parsedDate.getDay()];
};

const formatDate = (date) => {
  return date
    ? date.toLocaleString("es-CO", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
    : "";
};

const Dates1 = ({ handleFormDataChange, onBuscar, onFilterDocuments }) => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [formData, setFormData] = useState({
    fechaordenDesde: null,
    fechaordenHasta: null,
    estadOrden: "",
  });
  const [tipoDocumentoOptions, setTipoDocumentoOptions] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const documentosCargadosRef = useRef(false);
  const documentosPreviosRef = useRef(0);
  const { centro_costos } = useGlobalContext(); // Obtener los centro_costos del contexto global

  useEffect(() => {
    const fetchDatosIniciales = async () => {
      try {
        if (documentosCargadosRef.current) return;
        documentosCargadosRef.current = true;

        const documentosData = await fetchDocumentosOpen();
        const mappedDocumentos = documentosData.map((doc) => ({
          dirigir:
            doc.ultimo_centro_costos?.trim().toLowerCase() !== "generico"
              ? doc.ultimo_centro_costos
              : "Generico",
          emisor: doc.razon_social || "Desconocido",
          fecha: doc.fecha,
          cufe: doc.cufe,
          prefijo: doc.prefijo,
          consecutivo: doc.consecutivo,
          numfactura: `${doc.prefijo || ""}${doc.consecutivo || ""}`, // Concatenar prefijo y consecutivo
          valor: doc.valor,
          estadoFactura: doc.estado_descripcion,
          archivo: doc.archivo,
          centro_costos_id: doc.ultimo_centro_costos,
          hora: doc.hora,
          alerta: "",
          tramiteFactura:
            doc.estado_descripcion === "Proceso de aprobación"
              ? "pendiente"
              : "completado",
          centroCosto: null,
        }));

        console.log(
          "Todos los documentos mapeados desde el servicio:",
          mappedDocumentos,
        );

        setDocumentos(mappedDocumentos);

        const estados = await fetchEstadosCausacion();
        const filteredOptions = estados
          .filter(
            (estado) =>
              estado.estado_id !== 1 &&
              estado.estado_id !== 3 &&
              estado.estado_id !== 4,
          ) // Filtrar estados con estado_id 1 y 2
          .map((estado) => ({
            value: estado.estado_id,
            label: estado.nombre,
            descripcion: estado.descripcion,
          }));

        console.log(
          "Opciones de estados mapeados y filtrados:",
          filteredOptions,
        );

        setTipoDocumentoOptions(filteredOptions);

        const estadoPorDefecto = filteredOptions.find(
          (option) => option.label === "Proceso de aprobación",
        );

        if (!estadoPorDefecto) {
          console.warn(
            "Estado por defecto no encontrado. Verifica el valor de 'label'.",
          );
        } else {
          console.log(
            "Descripción del estado seleccionado por defecto:",
            estadoPorDefecto.descripcion,
          );

          setFormData((prev) => ({
            ...prev,
            estadOrden: estadoPorDefecto.value,
          }));

          // Filtrar documentos donde estadoFactura coincide con la descripción del estado seleccionado
          const documentosFiltrados = mappedDocumentos.filter(
            (doc) =>
              doc.estadoFactura?.trim().toLowerCase() ===
              estadoPorDefecto.descripcion?.trim().toLowerCase(),
          );
          console.log(
            "Documentos filtrados por estado por defecto:",
            documentosFiltrados,
          );

          // Pasar documentos filtrados al componente padre
          if (onFilterDocuments) {
            onFilterDocuments(documentosFiltrados, mappedDocumentos);
          }
        }
      } catch (error) {
        console.error("Error al cargar datos iniciales:", error);
      }
    };

    fetchDatosIniciales();
  }, [onFilterDocuments, centro_costos]); // Añadir centro_costos como dependencia

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (documentos.length > documentosPreviosRef.current) {
      documentos.forEach((doc) => {
        if (doc.fecha && doc.hora) {
          const tiempoTranscurrido = calculateTimeDifference(
            doc.fecha,
            doc.hora,
            currentDateTime,
          );
          if (tiempoTranscurrido) {
            const { total } = tiempoTranscurrido;
            const diaSemana = getDayOfWeek(doc.fecha); // Obtener día de la semana
            doc.tiempoTranscurrido = total; // Agregar la información de tiempo al documento
            // Imprimir el tiempo total, la fecha inicial y el día de la semana
            console.log(
              `Documento ${doc.numfactura || "sin número"} - ` +
                `Fecha inicial: ${doc.fecha}. ` +
                `Día de la semana: ${diaSemana}. ` +
                `Tiempo total: ${total.hours} horas, ${total.minutes} minutos.`,
            );
          }
        }
      });
      documentosPreviosRef.current = documentos.length;
      setDocumentos([...documentos]); // Actualizamos el estado con los tiempos calculados
    }
  }, [documentos, currentDateTime]);

  const handleFieldChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    handleFormDataChange(field, value);
  };

  const handleBuscar = () => {
    // Ajustar las fechas para incluir el día completo
    const fechaDesde = formData.fechaordenDesde
      ? new Date(new Date(formData.fechaordenDesde).setHours(0, 0, 0, 0))
      : null;
    const fechaHasta = formData.fechaordenHasta
      ? new Date(new Date(formData.fechaordenHasta).setHours(23, 59, 59, 999))
      : null;

    const estadoSeleccionado = tipoDocumentoOptions.find(
      (opt) => opt.value === formData.estadOrden,
    );

    if (!estadoSeleccionado) {
      console.warn("Estado seleccionado no encontrado.");
      return;
    }

    const documentosFiltrados = documentos.filter((doc) => {
      // Convertir la fecha del documento a objeto Date y ajustar a medianoche
      const [year, month, day] = doc.fecha.split("-").map(Number);
      const fechaDocumento = new Date(year, month - 1, day);

      const cumpleRangoFechas =
        (!fechaDesde || fechaDocumento >= fechaDesde) &&
        (!fechaHasta || fechaDocumento <= fechaHasta);
      const cumpleEstado =
        doc.estadoFactura?.trim().toLowerCase() ===
        estadoSeleccionado.descripcion?.trim().toLowerCase();

      // Si no hay fechas seleccionadas, solo validar por estado
      if (!fechaDesde && !fechaHasta) {
        return cumpleEstado;
      }

      // Validar por estado y rango de fechas
      return cumpleRangoFechas && cumpleEstado;
    });

    console.log(
      "Documentos filtrados por rango de fechas y/o estado:",
      documentosFiltrados,
    );

    if (onFilterDocuments) {
      onFilterDocuments(documentosFiltrados, documentos);
    }

    if (onBuscar) onBuscar(formData);
  };

  return (
    <div className="orden-date1-section">
      <div className="two-column-grid">
        <p>
          <strong>Fecha: </strong> {formatDate(currentDateTime)}
        </p>
      </div>
      <div className="three-column-grid">
        <CustomDateField
          id="fecha-desde"
          label="Fecha Factura (Desde)"
          value={formData.fechaordenDesde}
          onChange={(newValue) =>
            handleFieldChange("fechaordenDesde", newValue)
          }
        />
        <CustomDateField
          id="fecha-hasta"
          label="Fecha Factura (Hasta)"
          value={formData.fechaordenHasta}
          onChange={(newValue) =>
            handleFieldChange("fechaordenHasta", newValue)
          }
        />
        <CustomSelect
          id="estado-orden"
          label="Estado"
          value={formData.estadOrden}
          options={tipoDocumentoOptions}
          onChange={(e) => handleFieldChange("estadOrden", e.target.value)}
        />
      </div>
      <div className="generate-button-container-orden">
        <Button
          variant="contained"
          className="save-button"
          onClick={handleBuscar}
        >
          <SaveAltIcon />
          Buscar
        </Button>
      </div>
    </div>
  );
};

export default Dates1;
