// src/services/OpenService.js
import axios from "axios";
import Cookies from "js-cookie";

// Usar la variable de entorno para la URL base
const API_BASE_URL = process.env.REACT_APP_API_URL;
const token = Cookies.get("token");
// Función para obtener los estados de causación
export const fetchEstadosCausacion = async (estadoId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}estados-causacion`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: estadoId ? { estado_id: estadoId } : {}, // Agrega el parámetro si está definido
    });
    return response.data; // Devuelve la lista de estados
  } catch (error) {
    console.error("Error al consultar los estados de causación:", error);
    throw error;
  }
};

export const fetchDocumentosOpen = async (queryParams = "") => {
  try {
    let url = `${API_BASE_URL}openetl/lista_documentos_open`;

    // Si queryParams empieza con '?', lo dejamos tal cual, si no, añadimos '?'
    if (queryParams) {
      url += queryParams.startsWith("?") ? queryParams : `?${queryParams}`;
    }

    console.log("URL solicitada:", url);

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error en la solicitud: ${response.status}`);
    }

    const data = await response.json();
    return data || [];
  } catch (error) {
    console.error("Error en fetchDocumentosOpen:", error.message);
    return [];
  }
};

export const fetchPresignedFileUrl = async (fileKey) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}bucket/files/url/${fileKey}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    // Devuelve la URL firmada obtenida del backend
    return response.data.url;
  } catch (error) {
    console.error("Error al obtener la URL firmada:", error);
    throw error;
  }
};

export const moverDocumentoCentroCostos = async (cufe, centroCostosId) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}openetl/mover-documento-centro-costos`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: {
          cufe,
          centro_costos_id: centroCostosId,
        },
      },
    );

    // Devuelve el mensaje de éxito obtenido del backend
    return response.data;
  } catch (error) {
    console.error("Error al mover el documento al centro de costos:", error);
    throw error;
  }
};

export const fetchCentrosOperacion = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}informativa/centros`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Validación estricta para manejar la estructura de la respuesta
    if (
      !response.data ||
      !Array.isArray(response.data.data) ||
      response.data.data.length === 0
    ) {
      throw new Error(
        "Formato de respuesta no válido o centros no encontrados",
      );
    }

    // Extraer todos los centros de costos de cada entrada en `data`
    const centros = response.data.data.flatMap((item) => item.centro_costos);

    console.log("Centros de costos procesados PARA ESSCOGER:", centros);

    return centros;
  } catch (error) {
    console.error("Error al obtener los centros de operación:", error);
    throw error;
  }
};

export const fetchItemsFacturados = async (cufe) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}openetl/items-facturados/${cufe}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    // Validación estricta de la respuesta
    if (!response.data || !response.data.items_facturados) {
      throw new Error("No se encontraron ítems facturados en la respuesta.");
    }

    /*  console.log("Ítems facturados obtenidos:", response.data.items_facturados); */

    return response.data;
  } catch (error) {
    console.error("Error al obtener los ítems facturados:", error);
    throw error;
  }
};

export const guardarDistribucionCostos = async (cufe, distribucion) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}openetl/distribucion-costos/${cufe}`,
      distribucion,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      },
    );

    console.log("Distribución de costos guardada exitosamente:", response.data);

    // Retorna el mensaje de éxito desde el backend
    return response.data;
  } catch (error) {
    console.error("Error al guardar la distribución de costos:", error);

    // Lanza el error para manejarlo en el componente
    throw error;
  }
};

export const generarCausacionPorGrupo = async (cufe) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}causacion-sugerida-por-grupo/${cufe}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      },
    );

    // Retorna los datos de la respuesta
    return response.data;
  } catch (error) {
    console.error("Error al generar la causación sugerida por grupo:", error);
    throw error;
  }
};

export const generarCausacionPorItem = async (cufe) => {
  try {
    // Realiza la solicitud GET al endpoint
    const response = await axios.get(
      `${API_BASE_URL}causacion-sugerida-siesa-por-item/${cufe}`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Autorización con el token
        },
      },
    );

    // Retorna los datos de la respuesta
    return response.data;
  } catch (error) {
    console.error(
      "Error al generar la causación sugerida basada en ítems:",
      error,
    );

    // Lanza el error para manejarlo en el componente
    throw error;
  }
};

export const validarDistribucion = async (cufe) => {
  try {
    // Realiza la solicitud GET al endpoint
    const response = await axios.get(
      `${API_BASE_URL}validar-distribucion/${cufe}`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Autorización con el token
        },
      },
    );

    // Retorna los datos de la respuesta
    return response.data;
  } catch (error) {
    console.error("Error al validar la distribución del documento:", error);

    // Lanza el error para manejarlo en el componente
    throw error;
  }
};

// Servicio para consumir el endpoint `/puc`
export const fetchPucCausacion = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}puc`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error al obtener el listado del PUC Causación:", error);
    throw error;
  }
};

export const validarYMapearCausacion = async (cufe, updatedCausacion) => {
  if (!cufe) {
    throw new Error("CUFE es obligatorio para realizar esta solicitud.");
  }

  try {
    const response = await axios.post(
      `${API_BASE_URL}validar-y-mapear-causacion`,
      updatedCausacion, // El cuerpo de la solicitud
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { cufe }, // CUFE como parámetro de consulta
      },
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Error del backend:", error.response.data);
    } else {
      console.error("Error en la solicitud:", error.message);
    }

    throw error;
  }
};
