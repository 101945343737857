import React, { useState, useRef, useMemo } from "react";
import IconButton from "@mui/material/IconButton";
import Dates1 from "../components/FacturasForms/FacturasGeneral/Dates1FacturasGen.js";
import "../styles/pages/OrdenCompra.pages.css";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useGlobalContext } from "../context/GlobalContext.js";
import TableFacturaGeneral from "../components/FacturasForms/FacturasGeneral/TableFacturaGeneral.js";
import { Link } from "react-router-dom";

const FormFacturasGeneral = () => {
  const [filters, setFilters] = useState({
    fechaordenDesde: null,
    fechaordenHasta: null,
    estadOrden: "Pendiente",
  });

  const [filteredDocuments, setFilteredDocuments] = useState([]); // Estado para documentos filtrados
  const mappedDocumentosRef = useRef([]); // Ref para almacenar mappedDocumentos sin filtro

  const { user, company } = useGlobalContext();

  const formDateBasicRef = useRef(null);

  const handleFormDataChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const handleDocumentFiltering = (documents, mappedDocumentos) => {
    console.log(
      "Documentos filtrados se pasaron a FormFacturasGeneral:",
      documents,
    );
    console.log(
      "Todos los Documentos sin Filtros pasaron defecto a  FormFacturasGeneral:",
      mappedDocumentos,
    );

    // Actualizar `mappedDocumentosRef`
    mappedDocumentosRef.current = mappedDocumentos;

    // Actualizar documentos filtrados
    setFilteredDocuments(documents);
  };

  const Dates1Component = useMemo(
    () => (
      <Dates1
        handleFormDataChange={handleFormDataChange}
        onBuscar={(newFilters) =>
          setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }))
        }
        onFilterDocuments={handleDocumentFiltering} // Pasar función para manejar documentos filtrados // Pasar función para manejar documentos filtrados
      />
    ),
    [],
  );

  // Memorizar TableFacturaGeneral para evitar renderizaciones innecesarias
  const TableFacturaComponent = useMemo(
    () => (
      <TableFacturaGeneral
        filters={filters}
        documents={filteredDocuments}
        mappedDocumentos={mappedDocumentosRef.current}
      />
    ),
    [filters, filteredDocuments],
    mappedDocumentosRef.current, // Solo se recalculará si cambian `filters` o `filteredDocuments`
  );

  return (
    <div className="creation-form-container">
      <div className="form-header-ordencompra">
        <p>
          <strong>{company?.name || "Empresa no definida"}</strong>
        </p>
        <h1 className="form-title-ordencompra">Facturas Generales</h1>
        <Link to="/HomeWelcomeBoard">
          <IconButton aria-label="back" className="back-button">
            <KeyboardReturnIcon />
          </IconButton>
        </Link>
      </div>
      <form className="form-content">
        <div ref={formDateBasicRef} className="form-date-basic---orden">
          {Dates1Component}
          {TableFacturaComponent} {/* Memorizar este componente */}
          <div className="factura-section">
            <p>
              En este Panel podrá consultar las Facturas Generales que el
              sistema consulta de OPEN ETL, los usuarios de Contabilidad{" "}
              <strong>{user || "Usuario"}</strong>, podrán redireccionar a cada
              Centro de Costos y este está asociado a cada Jefe de Proceso para
              su debida gestión de Aprobar o Rechazar las Facturas, cuando los
              Jefes de Proceso gestionen la Factura, en este panel se verá
              reflejado el cambio de estado de la factura. Inicialmente al
              comienzo aparecerán las Facturas en las que su estado se
              encuentren en (Pendiente) para que el usuario de Contabilidad
              pueda redireccionar al Centro de Costos o pueda visualizar que la
              Factura no se ha gestionado por el respectivo Jefe de Proceso del
              Centro de Costos.
              <br />
            </p>
            <ol>
              <li>
                Si visualiza Facturas en estado Pendiente, por favor haga clic
                en el ícono PDF para revisar el documento y gestionar el
                proceso, si en la columna Dirigir no sale Centro de Costos
                seleccionado, seleccione uno para que se redireccione al area
                correspondiente.
              </li>
              <li>
                Las Facturas que sigan en Pendiente y el apartado de Dirigir
                este con el nombre de Centro de Costos selecionado, quiere decir
                que el Jefe de Proceso de ese Centro de Costo no ha gestionado
                la Factura.
              </li>
              <li>
                <strong>Nota Importante: </strong>Las Facturas que aparezcan en
                estado <strong>(Aprobado)</strong> para poder Causarlas debe ir
                al apartado Causar que aparecera en el Menu Izquierdo Procesos
                Financieros y dar Click en Facturas y Causar. Ahí podrá
                visualizar las facturas que han sido Aprobadas, listas para
                Causar.
              </li>
            </ol>
            Además, tendrá un indicador visual (alerta) para detectar el tiempo
            en que se gestiona la factura:
            <ul>
              <li>Verde: Inicio del tiempo para gestionar.</li>
              <li>Amarillo: Tardanza en su gestión.</li>
              <li>Rojo: Próximo vencimiento para gestionar.</li>
            </ul>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormFacturasGeneral;
