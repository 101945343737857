import React, { useEffect, useState, useRef } from "react";
import { useWebSocket } from "../context/websocket";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Paper,
  CircularProgress,
  ThemeProvider,
  createTheme,
  Fade,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "../styles/components/ListarNotificacionStyles.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    background: {
      default: "#ffffff",
    },
  },
});

const NotificationList = () => {
  const {
    messages,
    isConnected,
    readNotifications,
    markNotificationAsRead,
    maxRetriesReached,
  } = useWebSocket();
  const [showAll, setShowAll] = useState(false);
  const [formattedMessages, setFormattedMessages] = useState([]);
  const listRef = useRef(null);
  const messagesDatesRef = useRef({});

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);

      if (isNaN(date.getTime())) {
        return new Date().toLocaleString("es-ES", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
      }

      return date.toLocaleString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      });
    } catch (error) {
      console.error("Error al formatear la fecha:", error);
      return new Date().toLocaleString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      });
    }
  };

  useEffect(() => {
    Object.entries(messages).forEach(([id, data]) => {
      if (!messagesDatesRef.current[id]) {
        messagesDatesRef.current[id] = {
          fecha: data.fecha || new Date().toISOString(),
          formattedDate: formatDate(data.fecha || new Date().toISOString()),
        };
      }
    });

    const processedMessages = Object.entries(messages)
      .map(([id, data]) => {
        const storedDates = messagesDatesRef.current[id] || {
          fecha: data.fecha || new Date().toISOString(),
          formattedDate: formatDate(data.fecha || new Date().toISOString()),
        };

        return {
          id,
          ...data,
          fecha: storedDates.fecha,
          formattedDate: storedDates.formattedDate,
          unread: !readNotifications[id], // Indicar si no está leída
        };
      })
      .sort((a, b) => {
        const dateDiff =
          new Date(b.fecha).getTime() - new Date(a.fecha).getTime();
        if (dateDiff !== 0) {
          return dateDiff; // Ordenar primero por fecha
        }
        return b.id.localeCompare(a.id); // Si las fechas son iguales, ordenar por id (descendente)
      });

    setFormattedMessages(processedMessages);
  }, [messages, readNotifications]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
  }, [formattedMessages]);

  const handleMarkAsRead = async (notificacion_id) => {
    if (readNotifications[notificacion_id]) {
      return;
    }
    const markasreadUrl = process.env.REACT_APP_API_URL;
    try {
      const response = await fetch(
        `${markasreadUrl}notifications/${notificacion_id}/mark_as_read`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      if (!response.ok) {
        throw new Error("Error al marcar la notificación como leída");
      }

      markNotificationAsRead(notificacion_id);
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const renderContent = () => {
    if (maxRetriesReached) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h6" gutterBottom align="center" color="primary">
            Notificaciones
          </Typography>
          <Typography variant="body1" color="error" align="center">
            No se pudo establecer conexión con el servidor. Por favor, recarga
            la página.
          </Typography>
        </Box>
      );
    }

    if (!isConnected) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h6" gutterBottom align="center" color="primary">
            Notificaciones
          </Typography>
          <CircularProgress color="primary" />
        </Box>
      );
    }

    return (
      <>
        <Typography variant="h6" gutterBottom align="center" color="primary">
          Notificaciones
        </Typography>
        {formattedMessages.length === 0 ? (
          <Typography variant="body2" align="center" color="textSecondary">
            No hay notificaciones
          </Typography>
        ) : (
          <List ref={listRef} className="styled-list">
            {(showAll ? formattedMessages : formattedMessages.slice(0, 10)).map(
              ({ id, mensaje, formattedDate, unread }) => (
                <Fade in={true} key={id}>
                  <ListItem
                    className={`styled-list-item ${unread ? "unread" : "read"}`}
                    onClick={() => handleMarkAsRead(id)}
                  >
                    <ListItemText
                      primary={mensaje}
                      secondary={formattedDate}
                      primaryTypographyProps={{ color: "primary" }}
                      secondaryTypographyProps={{ color: "textSecondary" }}
                    />
                    {!unread && <CheckCircleIcon color="primary" />}
                  </ListItem>
                </Fade>
              ),
            )}
          </List>
        )}
        {formattedMessages.length > 10 && !showAll && (
          <Box className="show-more-button">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setShowAll(true)}
            >
              Mostrar más
            </Button>
          </Box>
        )}
      </>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper className="styled-paper">{renderContent()}</Paper>
    </ThemeProvider>
  );
};

export default NotificationList;
