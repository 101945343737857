import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { useAuth } from "../hooks/useAuth";
import { useGlobalContext } from "../context/GlobalContext";

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const { isAuthenticated, token, user } = useAuth();
  const [socket, setSocket] = useState(null);
  const { userInfo } = useGlobalContext();
  const [messages, setMessages] = useState({});
  const [readNotifications, setReadNotifications] = useState({});
  const [isConnected, setIsConnected] = useState(false);
  const socketRef = useRef(null);
  const reconnectAttemptsRef = useRef(0); // Cambiado a useRef
  const reconnectTimeoutRef = useRef(null);
  const usuario_id = userInfo?.user_id || user?.id;
  const [maxRetriesReached, setMaxRetriesReached] = useState(false);

  const addMessage = (newMessage) => {
    try {
      const { notificacion_id, mensaje, fecha } = newMessage;

      if (!notificacion_id) {
        console.error("Notificación recibida sin ID:", newMessage);
        return;
      }

      setMessages((prevMessages) => ({
        ...prevMessages,
        [notificacion_id]: {
          mensaje,
          fecha,
          isNew: true,
        },
      }));

      setReadNotifications((prev) => ({
        ...prev,
        [notificacion_id]: false,
      }));
    } catch (error) {
      console.error("Error al procesar nueva notificación:", error);
    }
  };

  const cleanupConnection = () => {
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
      reconnectTimeoutRef.current = null;
    }

    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
    }

    setSocket(null);
    setIsConnected(false);
    reconnectAttemptsRef.current = 0; // Resetear intentos al limpiar
  };

  const connectWebSocket = () => {
    const socket = process.env.REACT_APP_WEBSOCKET_URL;
    if (isConnected || !usuario_id || socketRef.current) {
      return;
    }

    console.log(
      "Iniciando nueva conexión WebSocket...",
      reconnectAttemptsRef.current,
    );

    const socketConnection = new WebSocket(`${socket}${usuario_id}`);

    console.log("Conectando a:", socketConnection.url);

    socketConnection.onopen = () => {
      console.log("WebSocket conectado exitosamente");
      setIsConnected(true);
      reconnectAttemptsRef.current = 0; // Resetear intentos al conectar exitosamente
    };

    socketConnection.onmessage = (event) => {
      try {
        const messageData = JSON.parse(event.data);
        console.log("Mensaje WebSocket recibido:", messageData);
        addMessage(messageData);
      } catch (error) {
        console.error("Error al procesar mensaje WebSocket:", error);
      }
    };

    socketConnection.onclose = (event) => {
      console.log("Conexión WebSocket cerrada", event);
      setIsConnected(false);
      socketRef.current = null;

      if (reconnectAttemptsRef.current < 1 && isAuthenticated && token) {
        const delay = Math.min(
          3000 * Math.pow(2, reconnectAttemptsRef.current),
          30000,
        );
        reconnectAttemptsRef.current += 1;
        console.log(
          `Intento de reconexión ${reconnectAttemptsRef.current} en ${delay}ms`,
        );

        reconnectTimeoutRef.current = setTimeout(() => {
          connectWebSocket();
        }, delay);
      } else {
        console.log(
          "Máximo número de intentos de reconexión alcanzado o desconexión intencional",
        );
        setMaxRetriesReached(true);
      }
    };

    socketConnection.onerror = (error) => {
      console.error("Error en WebSocket:", error);
    };

    socketRef.current = socketConnection;
    setSocket(socketConnection);
  };

  const markNotificationAsRead = (notificacion_id) => {
    setReadNotifications((prev) => ({
      ...prev,
      [notificacion_id]: true,
    }));

    setMessages((prev) => {
      if (prev[notificacion_id]) {
        return {
          ...prev,
          [notificacion_id]: {
            ...prev[notificacion_id],
            isNew: false,
          },
        };
      }
      return prev;
    });
  };

  useEffect(() => {
    const fetchUnreadNotifications = async () => {
      if (!usuario_id) return;

      const notificationsUrl = process.env.REACT_APP_API_URL;

      try {
        const response = await fetch(
          `${notificationsUrl}notificaciones/${usuario_id}`,
          {
            headers: { "Content-Type": "application/json" },
          },
        );

        if (response.status === 204) {
          setMessages({});
          setReadNotifications({});
          return;
        }

        if (response.ok) {
          const { notifications } = await response.json();
          const formattedMessages = notifications.reduce((acc, notif) => {
            acc[notif.notificacion_id] = {
              mensaje: notif.mensaje,
              fecha: notif.fecha,
            };
            return acc;
          }, {});
          setMessages((prev) => ({ ...prev, ...formattedMessages }));

          const readStatuses = notifications.reduce((acc, notif) => {
            acc[notif.notificacion_id] = notif.leido;
            return acc;
          }, {});
          setReadNotifications((prev) => ({ ...prev, ...readStatuses }));
        }
      } catch (error) {
        console.error("Error al obtener las notificaciones iniciales:", error);
      }
    };

    if (isConnected && usuario_id) {
      fetchUnreadNotifications();
    }
  }, [isConnected, usuario_id]);

  useEffect(() => {
    if (isAuthenticated && token && usuario_id && !isConnected) {
      connectWebSocket();
    }

    return () => {
      cleanupConnection();
    };
  }, [isAuthenticated, token, usuario_id]);

  return (
    <WebSocketContext.Provider
      value={{
        socket,
        messages,
        isConnected,
        readNotifications,
        markNotificationAsRead,
        maxRetriesReached,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);

export default WebSocketContext;
