import React, { useState, useCallback, useRef } from "react";
import ReactDOM from "react-dom";
import AlertComponent from "./Alerts.js";
import AlertCausacionComponent from "../components/FacturasForms/FacturasCausar/AlertCausar.js";

export function useAlert() {
  const [alertState, setAlertState] = useState({
    type: null,
    cufe: null, // Estado para el CUFE
    emisor: null, // Estado para el emisor
    caller: null,
    fetchCausacionData: null, // Función para obtener datos de causación
    causacionResponse: null, // Función para obtener datos de causación
  });
  const timeoutRef = useRef(null);

  const triggerAlert = useCallback(
    (
      type,
      {
        cufe = null,
        emisor = null,
        setRows = null,
        caller = null,
        fetchCausacionData = null,
        causacionResponse = null,
      },
    ) => {
      clearTimeout(timeoutRef.current);

      const isStaticAlert =
        type === "causacionDetails" ||
        type === "sessionActive" ||
        type === "groupItems" ||
        type === "tramiteFactura" ||
        type === "distribucionCostos";

      setAlertState({
        type,
        cufe,
        emisor,
        setRows,
        caller,
        fetchCausacionData,
        causacionResponse,
      });

      // No configurar temporizador para alertas estáticas
      if (isStaticAlert) {
        console.log(`Alerta estática configurada: ${type}`);
        return;
      }

      // Configurar temporizador para alertas dinámicas
      timeoutRef.current = setTimeout(() => {
        setAlertState({
          type: null,
          cufe: null,
          emisor: null,
          setRows: null,
          caller: null,
          fetchCausacionData: null,
          causacionResponse: null,
        });
      }, 2000);
    },
    [],
  );

  const closeAlert = useCallback(() => {
    clearTimeout(timeoutRef.current);
    setAlertState({
      type: null,
      cufe: null,
      emisor: null,
      caller: null,
      fetchCausacionData: null,
      causacionResponse: null,
    });
  }, []);

  const Alert = useCallback(() => {
    if (!alertState.type) return null;

    // Seleccionar qué componente de alerta renderizar
    if (alertState.type === "causacionDetails") {
      return ReactDOM.createPortal(
        <AlertCausacionComponent
          type={alertState.type}
          cufe={alertState.cufe}
          emisor={alertState.emisor}
          caller={alertState.caller}
          causacionResponse={alertState.causacionResponse}
          fetchCausacionData={alertState.fetchCausacionData} // Pasa la función para obtener datos
          onClose={closeAlert}
        />,
        document.getElementById("modal-root"),
      );
    }

    // Renderizar AlertComponent por defecto
    return ReactDOM.createPortal(
      <AlertComponent
        type={alertState.type}
        cufe={alertState.cufe}
        emisor={alertState.emisor}
        setRows={alertState.setRows}
        caller={alertState.caller}
        onClose={closeAlert}
      />,
      document.getElementById("modal-root"),
    );
  }, [alertState, closeAlert]);

  return { triggerAlert, Alert };
}
