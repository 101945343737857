import React, { createContext, useState, useContext, useEffect } from "react";
import { fetchUserInfo } from "../services/GlobalContextSerive";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [user, setUser] = useState(""); // Estado inicial vacío
  const [userid, setUserId] = useState(""); // Estado inicial vacío
  const [area, setArea] = useState(""); // Estado inicial vacío
  const [centro_costos, setCentroCostos] = useState([]); // Estado inicial como array
  const [isCompanySelected, setIsCompanySelected] = useState(false); // Simplificar la inicialización
  const [company, setCompany] = useState(() => {
    const storedCompany = localStorage.getItem("selectedCompany");

    try {
      return storedCompany ? JSON.parse(storedCompany) : null;
    } catch (error) {
      console.warn("Error al parsear el JSON de 'selectedCompany':", error);
      return null; // Retorna null si el JSON no es válido
    }
  });

  const [userInfo, setUserInfo] = useState(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isLoadingUserInfo, setIsLoadingUserInfo] = useState(true); // Nuevo estado
  const [shouldReload, setShouldReload] = useState(false); // Para refresco automático

  useEffect(() => {
    const loadUserInfo = async () => {
      setIsLoadingUserInfo(true); // Inicia la carga
      try {
        const data = await fetchUserInfo();
        if (data) {
          console.log("Usuario cargado correctamente:", data); // Log para depurar
          setUser(`${data.nombre} ${data.apellido}`);
          setUserId(`${data.user_id}`);
          setArea(data.area || "Desarrollador FullStack");
          setCentroCostos(data.centros_costos || []); // Guardar centros de costos como array
          setUserInfo(data);
          setIsUserLoggedIn(true);
          if (shouldReload) {
            setShouldReload(false); // Reinicia el estado de recarga
            window.location.reload(); // Fuerza un refresco completo
          }
        } else {
          console.warn(
            "Token ausente o no válido. El usuario no está autenticado.",
          );
          setUserInfo(null);
          setIsUserLoggedIn(false);
        }
      } catch (error) {
        console.error("Error al cargar la información del usuario:", error);
        setUserInfo(null);
        setIsUserLoggedIn(false);
      } finally {
        setIsLoadingUserInfo(false); // Finaliza la carga
      }
    };

    loadUserInfo();
  }, [shouldReload]); // Detecta cambios en `shouldReload`

  const updateCompany = (companyName, companyId) => {
    if (!companyName && !companyId) {
      handleResetCompany();
      return;
    }

    const newCompany = { name: companyName, id: companyId };
    setCompany(newCompany);
    setIsCompanySelected(true); // Asegurarse que esto se llama como función
    localStorage.setItem("selectedCompany", JSON.stringify(newCompany));
  };

  const handleResetCompany = () => {
    setCompany(null);
    setIsCompanySelected(false); // Asegurarse que esto se llama como función
    localStorage.removeItem("selectedCompany");
  };

  return (
    <GlobalContext.Provider
      value={{
        user,
        userid,
        area,
        centro_costos,
        company,
        setCompany: updateCompany,
        userInfo,
        isUserLoggedIn,
        isLoadingUserInfo,
        setShouldReload, // Exponer el estado para forzar recarga
        isCompanySelected, // Exponer el estado
        setIsCompanySelected, // Exponer el setter
        handleResetCompany, // Agregar esta función al contexto
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

export default GlobalContext;
