import React, { useEffect, useState, useMemo, memo } from "react";
import { FileText } from "lucide-react";
import ReactVirtualizedTable from "../../ReactVirtualizedTable";
import PaginationComponent from "../../PaginationComponent";
import "../../../styles/components/tableFacturaInt.css";
import IconButton from "@mui/material/IconButton";
import Loading from "../../../hooks/loading";
import { useAlert } from "../../../hooks/useAlert";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import {
  fetchPresignedFileUrl,
  generarCausacionPorGrupo,
  generarCausacionPorItem,
  validarDistribucion,
} from "../../../services/OpenService";

const TableCausarFactura = memo(
  ({ customColumns, documents, mappedDocumentos }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [timeLeft, setTimeLeft] = useState(30);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedProducts] = useState([]);
    const { triggerAlert, Alert } = useAlert();
    const rowsPerPage = 10;

    useEffect(() => {
      console.log("Documentos filtrados recibidos en la tabla:", documents);
      console.log(
        "Todos los documentos recibidos por defecto en la tabla desde el EndPoint del primer componente:",
        mappedDocumentos,
      );
    }, [mappedDocumentos, documents]);

    useEffect(() => {
      const timer = setInterval(() => {
        setTimeLeft((prev) => (prev > 0 ? prev - 1 : 30));
      }, 1000);

      return () => clearInterval(timer);
    }, []);
    const getPaginatedRows = useMemo(() => {
      const startIndex = (currentPage - 1) * rowsPerPage;
      const endIndex = startIndex + rowsPerPage;
      return documents.slice(startIndex, endIndex);
    }, [currentPage, rowsPerPage, documents]);

    const handleChangePage = (page) => {
      setCurrentPage(page);
    };

    const handleAprobar = async (cufe) => {
      setIsLoading(true);

      try {
        // Validar la distribución del documento
        const distribucionResponse = await validarDistribucion(cufe);
        console.log(
          `Distribución validada: El tipo es "${distribucionResponse.tipo}".`,
        );

        // Variable para almacenar la respuesta de la causación
        let causacionResponse;

        // Generar causación según el tipo de distribución
        if (distribucionResponse.tipo === "grupo") {
          causacionResponse = await generarCausacionPorGrupo(cufe);
          console.log(
            "Causación por grupo generada exitosamente:",
            causacionResponse,
          );
        } else if (distribucionResponse.tipo === "item") {
          causacionResponse = await generarCausacionPorItem(cufe);
          console.log(
            "Causación por ítems generada exitosamente:",
            causacionResponse,
          );
        } else {
          console.warn("El tipo de distribución no es válido.");
          return;
        }

        // Disparar la alerta con los datos del documento, el tipo como caller, y la respuesta de la causación
        const row = documents.find((row) => row.cufe === cufe);
        if (row) {
          console.log(
            `Disparando alerta con CUFE: ${row.cufe}, Emisor: ${row.emisor}, Caller: ${distribucionResponse.tipo}`,
          );

          triggerAlert("causacionDetails", {
            cufe: row.cufe || "CUFE no definido",
            emisor: row.emisor || "Emisor no definido",
            caller: distribucionResponse.tipo,
            causacionResponse, // Incluye la respuesta de la causación
          });

          console.log(
            `Alerta disparada con CUFE: ${row.cufe}, Emisor: ${row.emisor}, Caller: ${distribucionResponse.tipo}, Respuesta: ${causacionResponse}`,
          );
        }

        console.log(`Se pasó el CUFE: ${cufe}`);
      } catch (error) {
        console.error("Error en el proceso de aprobación:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const getSemaforoColor = () => {
      if (timeLeft > 20) return "verde";
      if (timeLeft > 10) return "amarillo";
      return "rojo";
    };
    const handleFetchPresignedUrl = async (fileKey) => {
      try {
        setIsLoading(true);
        const url = await fetchPresignedFileUrl(fileKey);
        window.open(url, "_blank");
      } catch (error) {
        console.error("Error al obtener la URL firmada:", error);
      } finally {
        setIsLoading(false);
      }
    };
    const customRenderers = useMemo(
      () => ({
        documento: (rowData) => (
          <IconButton
            onClick={() => handleFetchPresignedUrl(rowData.archivo)}
            title="Ver documento"
          >
            <FileText size={20} />
          </IconButton>
        ),
        tramiteFactura: (rowData) => {
          if (rowData.tramiteFactura === "completado") {
            return (
              <div className="button-container">
                <button
                  type="button"
                  className="tramitar-button green"
                  onClick={() => handleAprobar(rowData.cufe)}
                >
                  Causar
                </button>
              </div>
            );
          } else {
            return <span style={{ color: "#757575" }}>Completado</span>;
          }
        },

        alerta: () => (
          <div className={`semaforo ${getSemaforoColor()}`}>
            <span
              className={`semaforo-circulo ${
                timeLeft > 20 ? "verde" : timeLeft > 10 ? "amarillo" : "rojo"
              }`}
              title="Semáforo"
            ></span>
          </div>
        ),
        backfactura: (rowData) => (
          <IconButton
            onClick={() => {
              const updatedRow = { ...rowData, centroCosto: null };
              console.log("Centro de Costos eliminado para: ", updatedRow);
            }}
            title="Quitar Centro de Costos"
          >
            <AssignmentReturnIcon />
          </IconButton>
        ),
      }),
      [timeLeft, documents],
    );

    return (
      <div className="table-section up">
        {isLoading && <Loading />}
        <ReactVirtualizedTable
          columns={
            customColumns || [
              { dataKey: "dirigir", label: "GESTIONÓ", width: "auto" },
              { dataKey: "tramiteFactura", label: "CAUSAR", width: "auto" },
              { dataKey: "emisor", label: "EMISOR", width: "auto" },
              { dataKey: "numfactura", label: "#FACTURA", width: "auto" },
              { dataKey: "fecha", label: "FECHA", width: "auto" },
              { dataKey: "valor", label: "VALOR", width: "auto" },
              { dataKey: "estadoFactura", label: "ESTADO", width: "auto" },
              { dataKey: "documento", label: "PDF", width: "auto" },
              { dataKey: "alerta", label: "ALERTA", width: "auto" },
            ]
          }
          data={getPaginatedRows}
          customRenderers={customRenderers}
        />

        <PaginationComponent
          totalItems={documents.length}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          onPageChange={handleChangePage}
        />
        <Alert selectedProducts={selectedProducts} />
      </div>
    );
  },
);

TableCausarFactura.displayName = "TableCausarFactura";

export default TableCausarFactura;
