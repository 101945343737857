import React, { useState, useEffect } from "react";
import { CustomTextField } from "../Forms/CustomAutocomplete";
import { useParams } from "react-router-dom";
import {
  fetchOrdenCompraById,
  fetchAsociadoById,
} from "../../services/apiService"; // Importa las APIs necesarias

const Dates3 = ({ handleFormDataChange }) => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const { id: numeroOrden } = useParams(); // Obtén el número de orden desde la URL
  const [formData, setFormData] = useState({
    fechaHoraActual: new Date(), // Campo para capturar la fecha y hora actual
  });

  useEffect(() => {
    // Actualiza la fecha y hora actual cada segundo
    const timer = setInterval(() => {
      const newDate = new Date();
      setCurrentDateTime(newDate); // Actualiza la fecha actual
      handleFormDataChange("fechaHoraActual", newDate); // Propaga cambios al padre si es necesario
    }, 1000);

    // Limpia el intervalo al desmontar el componente
    return () => clearInterval(timer);
  }, [handleFormDataChange]);

  useEffect(() => {
    const cargarOrden = async () => {
      try {
        const data = await fetchOrdenCompraById(numeroOrden);
        if (data) {
          const asociadoId = data.documento.proveedor.asociado_de_negocio_id;

          setFormData((prev) => ({
            ...prev,
            centroCostos: data.documento.centroCostos, // Centro de Costos desde la API
            nit: "", // Inicializa NIT como vacío
            senores: "", // Inicializa Señor(es) como vacío
            sucursales: "", // Inicializa sucursales como vacío
            telefono: "", // Inicializa teléfono como vacío
          }));

          handleFormDataChange("centroCostos", data.documento.centroCostos);

          // Busca el proveedor por el asociado_id
          const asociadoData = await fetchAsociadoById(asociadoId);
          if (asociadoData) {
            const numeroIdentificacion =
              asociadoData.asociado_negocio.numero_identificacion;
            const razonSocial =
              asociadoData.asociado_negocio.razon_social || "";
            const direccion =
              asociadoData.asociado_negocio.centro_operaciones?.[0]
                ?.direccion || "No disponible";
            const telefono =
              asociadoData.asociado_negocio.centro_operaciones?.[0]
                ?.telefonos?.[0] || "Sin teléfono disponible";

            setFormData((prev) => ({
              ...prev,
              nit: numeroIdentificacion, // Actualiza el NIT
              senores: razonSocial, // Actualiza Señor(es) con razón social
              sucursales: direccion, // Actualiza sucursales con dirección
              telefono: telefono, // Actualiza teléfono con el primer teléfono encontrado
            }));

            handleFormDataChange("nit", numeroIdentificacion); // Propaga el cambio al padre
            handleFormDataChange("senores", razonSocial); // Propaga el cambio al padre
            handleFormDataChange("sucursales", direccion); // Propaga el cambio al padre
            handleFormDataChange("telefono", telefono); // Propaga el teléfono al padre
          }
        }
      } catch (error) {
        console.error("Error al cargar la orden o el asociado:", error);
      }
    };

    cargarOrden();
  }, [numeroOrden, handleFormDataChange]);

  const formatDate = (date) => {
    return new Date(date).toLocaleString("es-CO", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  return (
    <div className="orden-date1-section">
      <div className="two-column-grid">
        <p>
          <strong>Numero de Orden: </strong> {numeroOrden}
        </p>
        <p>
          <strong>Fecha: </strong> {formatDate(currentDateTime)}
        </p>
      </div>
      <div className="three-column-grid">
        <CustomTextField
          id="centro-costos"
          className="item-1"
          label="Centro de Costos"
          value={formData.centroCostos || ""} // Valor recibido o vacío
          disabled
        />

        <CustomTextField
          id="nit"
          label="Número de Identificación del Proveedor"
          className="item-2"
          value={formData.nit || ""} // Muestra el NIT desde la API
          disabled
        />

        <CustomTextField
          className="item-3"
          options={[]} // No hay opciones disponibles
          value={formData.senores || ""} // Muestra la razón social obtenida de la API
          disabled
          label="Señor(es)"
        />
      </div>

      <div className="two-column-grid">
        <CustomTextField
          value={formData.sucursales || ""} // Muestra la dirección obtenida de la API
          disabled
          label="Sucursales"
        />

        <CustomTextField
          id="telefono"
          label="Teléfono"
          value={formData.telefono || "Sin teléfono disponible"} // Muestra el primer teléfono o un mensaje predeterminado
          disabled
        />
      </div>
    </div>
  );
};

export default Dates3;
