import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import {
  CustomAutocomplete,
  CustomTextField,
} from "../Forms/CustomAutocomplete";
import useNitDV from "../../hooks/useNitDV";
import DocumentoInputGroup from "../DocumentInGroup";
import { fetchTiposIdentificacion, fetchCiiu } from "../../services/apiService";

const PersonaNatural = ({
  handleChange,
  handleFormDataChange,
  nitProveedor,
}) => {
  const { nit, dv, handleNitChange } = useNitDV();
  const [tipoDocumento, setTipoDocumento] = useState("");
  const [tipoCiuu, setTipoCiuu] = useState(null);
  const [tiposIdentificacion, setTiposIdentificacion] = useState([]);
  const [ciiuOptions, setCiiuOptions] = useState([]);

  useEffect(() => {
    if (nitProveedor) {
      console.log("NIT Proveedor recibido en PersonaNatural:", nitProveedor);
      // Usa nitProveedor según sea necesario
    }
  }, [nitProveedor]);

  useEffect(() => {
    const fetchData = async () => {
      setTiposIdentificacion(await fetchTiposIdentificacion());
      setCiiuOptions(await fetchCiiu());
    };
    fetchData();
  }, []);

  const onFieldChange = (field, value) => {
    handleFormDataChange(field, value); // Asegura que siempre envíe el value al formData
  };

  return (
    <div className="personal-natural-section">
      <DocumentoInputGroup
        nitProveedor={nitProveedor} // Pasamos nitProveedor al componente DocumentoInputGroup
        tipoDocumento={tipoDocumento}
        setTipoDocumento={setTipoDocumento}
        tiposIdentificacion={tiposIdentificacion}
        nit={nit}
        dv={dv}
        handleNitChange={handleNitChange}
        handleChange={handleChange}
        onFieldChange={onFieldChange}
      />

      <div className="three-column-grid">
        <TextField
          id="primer-nombre-natural"
          label="Primer Nombre"
          variant="outlined"
          className="input-textfield"
          required
          onChange={(e) => onFieldChange("primerNombre", e.target.value)}
        />
        <TextField
          id="segundo-nombre-natural"
          label="Segundo Nombre"
          variant="outlined"
          className="input-textfield"
          onChange={(e) => onFieldChange("segundoNombre", e.target.value)}
        />
        <CustomAutocomplete
          options={ciiuOptions.map((ciiu) => ({
            label: `${ciiu.clase} - ${ciiu.descripcion}`,
            value: ciiu.ciiu_id, // Asegura que el value sea el ID
          }))}
          value={tipoCiuu}
          onChange={(event, value) => {
            setTipoCiuu(value || null);
            onFieldChange("ciiu", value?.value || ""); // Envía el value al formData
          }}
          label="CIIU"
          required
        />
      </div>

      <div className="three-column-grid">
        <CustomTextField
          id="primer-apellido-natural"
          label="Primer Apellido"
          variant="outlined"
          className="input-textfield"
          required
          onChange={(e) => onFieldChange("primerApellido", e.target.value)}
        />

        <CustomTextField
          id="segundo-apellido-natural"
          label="Segundo Apellido"
          variant="outlined"
          className="input-textfield"
          onChange={(e) => onFieldChange("segundoApellido", e.target.value)}
        />
        <CustomTextField
          id="correo-natural"
          label="Correo"
          variant="outlined"
          className="input-textfield"
          required
          onChange={(e) => onFieldChange("correo", e.target.value)}
        />
      </div>
      <CustomTextField
        disabled
        id="ciiu-completo-natural"
        label="CIIU Completo"
        variant="outlined"
        value={tipoCiuu?.label || ""} // Mostrar el label pero no enviarlo al formData
        className="input-textfield"
      />
    </div>
  );
};

export default PersonaNatural;
