import React, { useEffect, useState, useRef } from "react";
import "../../../styles/components/alertStyle.css";
import CausacionDetails from "../FacturasCausar/CausacionDetails.js"; // Componente para detalles específicos de causación

export default function AlertCausacionComponent({
  type,
  onClose,
  cufe,
  caller,
  emisor,
  causacionResponse,
}) {
  const [visible, setVisible] = useState(false);
  const [alertType] = useState(type);
  const timeoutId = useRef(null);

  useEffect(() => {
    if (!alertType) return;

    setVisible(true);

    // Configurar temporizador si no es estática
    if (alertType !== "causacionDetails") {
      timeoutId.current = setTimeout(() => {
        setVisible(false);
        setTimeout(() => {
          if (onClose) onClose();
        }, 1000);
      }, 3000); // La alerta se cierra después de 3 segundos
    }

    return () => {
      clearTimeout(timeoutId.current);
    };
  }, [alertType, onClose]);

  const getTextAndClass = () => {
    let text = "";
    let className = "";

    switch (alertType) {
      case "causacionDetails":
        text = "Detalles de la Causación";
        className = "session-text";
        break;
      default:
        text = "Acción desconocida.";
        className = "session-text";
        break;
    }

    return { text, className };
  };

  const { text, className } = getTextAndClass();

  return (
    <div className={`container ${visible ? "visible" : ""}`}>
      {alertType === "causacionDetails" ? (
        <CausacionDetails
          className={className}
          cufe={cufe}
          emisor={emisor}
          caller={caller}
          causacionResponse={causacionResponse}
          onClose={onClose}
        />
      ) : (
        <div className={`approval-box ${className}`}>
          <p className={className}>{text}</p>
          {alertType === "error" && (
            <div className="button-container">
              <button onClick={onClose} className="alert-button red">
                Cerrar
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
