import React, { useState, useEffect } from "react";
import {
  fetchTiposIdentificacion,
  fetchTiposProveedor,
  fetchClasesProveedor,
  fetchFormasPago,
  fetchCentrosCostos,
  fetchBancos,
  fetchCiiu,
} from "../../services/apiService";

const InfoRow = ({ label, value }) => {
  if (!value) return null;
  return (
    <p>
      <strong>{label}:</strong> {value}
    </p>
  );
};

const AsociadosVerify = ({ formData = {}, documentData = [] }) => {
  const [data, setData] = useState({
    tiposProveedor: [],
    clasesProveedor: [],
    formasPago: [],
    centrosCostos: [],
    bancos: [],
  });
  const [tiposIdentificacion, setTiposIdentificacion] = useState([]);
  const [ciiuList, setCiiuList] = useState([]); // Estado para los CIIU
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          tiposProveedor,
          clasesProveedor,
          formasPago,
          centrosCostos,
          bancos,
          ciiu, // Agregar fetchCiiu
        ] = await Promise.all([
          fetchTiposProveedor(),
          fetchClasesProveedor(),
          fetchFormasPago(),
          fetchCentrosCostos(),
          fetchBancos(),
          fetchCiiu(), // Llamada a la API de CIIU
        ]);

        setData({
          tiposProveedor,
          clasesProveedor,
          formasPago,
          centrosCostos,
          bancos,
        });
        setTiposIdentificacion(await fetchTiposIdentificacion());
        setCiiuList(ciiu); // Guardar los CIIU
      } catch (error) {
        console.error("Error al cargar datos:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Buscar descripción del tipo de documento
  const tipoDocumentoDescripcion =
    tiposIdentificacion.find(
      (tipo) => String(tipo.codigo_dian) === String(formData.tipoDocumento),
    )?.descripcion || "Desconocido";

  // Funciones auxiliares para obtener descripciones
  const getDescripcion = (lista, id) =>
    lista.find((item) => String(item.value) === String(id))?.label ||
    "Desconocido";

  console.log("formData.ciiu", formData.ciiu);
  console.log("ciiuList", ciiuList);

  const ciiuDescripcion = formData.ciiu
    ? ciiuList.find((ciiu) => ciiu.ciiu_id === formData.ciiu)
      ? `${ciiuList.find((ciiu) => ciiu.ciiu_id === formData.ciiu).clase} - ${ciiuList.find((ciiu) => ciiu.ciiu_id === formData.ciiu).descripcion}`
      : "Desconocido"
    : "Desconocido";

  const tipoProveedorDescripcion = getDescripcion(
    data.tiposProveedor,
    formData.tipoProveedor,
  );
  const claseProveedorDescripcion = getDescripcion(
    data.clasesProveedor,
    formData.claseProveedor,
  );
  const metodoPagoDescripcion = getDescripcion(
    data.formasPago,
    formData.metodoPago,
  );
  const centroCostosDescripcion = getDescripcion(
    data.centrosCostos,
    formData.centroCostos,
  );
  const bancoDescripcion = getDescripcion(data.bancos, formData.banco);

  const hasProviderData =
    formData.isProveedorCertificado ||
    formData.isAutoretenedor ||
    formData.isAutoretenedorICA ||
    formData.isResponsableIVA ||
    formData.tipoProveedor ||
    formData.claseProveedor ||
    formData.metodoPago ||
    formData.numeroResolucion ||
    formData.centroCostos ||
    formData.banco ||
    formData.tipoCuenta ||
    formData.numeroCuenta ||
    formData.titularCuenta;

  const hasAttachments =
    formData.adjuntos && Object.keys(formData.adjuntos).length > 0;

  const renderAttachment = (key) => {
    const documentInfo = documentData.find((doc) => doc.id === parseInt(key));
    const documentName = documentInfo?.document || "Documento desconocido";

    return (
      <p key={key}>
        <strong>{documentName}:</strong>{" "}
        <a
          href={formData.adjuntos[key].url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {formData.adjuntos[key].name}
        </a>
      </p>
    );
  };

  if (isLoading) {
    return <p>Cargando datos...</p>;
  }

  return (
    <div className="form-data">
      <h3 className="section-title-ver">Datos de la Persona</h3>
      <InfoRow label="Natural" value={formData.isNatural ? "Sí" : null} />
      <InfoRow label="Jurídica" value={formData.isJuridica ? "Sí" : null} />
      <InfoRow label="Tipo de Documento" value={tipoDocumentoDescripcion} />
      <InfoRow label="Número" value={formData.numero} />
      <InfoRow label="DV" value={formData.dv} />
      <InfoRow label="Razón Social" value={formData.razonSocial} />
      <InfoRow label="Primer Nombre" value={formData.primerNombre} />
      <InfoRow label="Segundo Nombre" value={formData.segundoNombre} />
      <InfoRow label="Primer Apellido" value={formData.primerApellido} />
      <InfoRow label="Segundo Apellido" value={formData.segundoApellido} />
      <InfoRow label="CIIU" value={ciiuDescripcion} />
      <InfoRow label="Correo" value={formData.correo} />

      <h3 className="section-title-ver">Sucursal</h3>
      <InfoRow label="Nombre" value={formData.name} />
      <InfoRow label="Email" value={formData.email} />
      <InfoRow
        label="País"
        value={formData.country?.label || formData.country}
      />
      <InfoRow label="Ciudad" value={formData.city?.label || formData.city} />
      <InfoRow label="Dirección" value={formData.address} />
      <InfoRow label="Teléfono 1" value={formData.phone1} />
      <InfoRow label="Teléfono 2" value={formData.phone2} />

      <h3 className="section-title-ver">Roles Seleccionados</h3>
      <InfoRow label="Empleado" value={formData.isEmpleado ? "Sí" : null} />
      <InfoRow label="Cliente" value={formData.isCliente ? "Sí" : null} />
      <InfoRow label="Proveedor" value={formData.isProveedor ? "Sí" : null} />

      {hasProviderData && (
        <>
          <h3 className="section-title-ver">Datos del Proveedor</h3>
          <InfoRow
            label="Proveedor Calificado"
            value={formData.isProveedorCertificado ? "Sí" : null}
          />
          <InfoRow
            label="Autoretenedor"
            value={formData.isAutoretenedor ? "Sí" : null}
          />
          <InfoRow
            label="Autoretenedor ICA"
            value={formData.isAutoretenedorICA ? "Sí" : null}
          />
          <InfoRow
            label="Responsable IVA"
            value={formData.isResponsableIVA ? "Sí" : "No"}
          />
          <InfoRow label="Tipo de Proveedor" value={tipoProveedorDescripcion} />
          <InfoRow
            label="Clase de Proveedor"
            value={claseProveedorDescripcion}
          />
          <InfoRow label="Método de Pago" value={metodoPagoDescripcion} />
          <InfoRow
            label="Número de Resolución"
            value={formData.numeroResolucion}
          />
          <InfoRow label="Centro de Costos" value={centroCostosDescripcion} />
          <InfoRow label="Banco" value={bancoDescripcion} />
          <InfoRow label="Tipo de Cuenta" value={formData.tipoCuenta} />
          <InfoRow label="Número de Cuenta" value={formData.numeroCuenta} />
          <InfoRow
            label="Titular de la Cuenta"
            value={formData.titularCuenta}
          />
        </>
      )}

      {hasAttachments && (
        <>
          <h3 className="section-title-ver">Archivos Adjuntos</h3>
          {Object.keys(formData.adjuntos).map(renderAttachment)}
        </>
      )}
    </div>
  );
};

export default AsociadosVerify;
