import React from "react";
import { Box, Typography, Paper, CircularProgress } from "@mui/material";
import { useGlobalContext } from "../context/GlobalContext";
import "../styles/components/alertStyle.css";

const HomeWelcomeBoard = () => {
  const { user, area, isUserLoggedIn, userInfo, company, isLoadingUserInfo } =
    useGlobalContext();

  if (isLoadingUserInfo) {
    return (
      <Box className="container-welcome loading-container">
        <CircularProgress />
        <Typography ml={2}>Cargando información del usuario...</Typography>
      </Box>
    );
  }

  if (!isUserLoggedIn) {
    return (
      <Box className="container-welcome error-container">
        <Typography variant="h6">
          El usuario no está autenticado. Por favor, inicie sesión nuevamente.
        </Typography>
      </Box>
    );
  }

  return (
    <Box className="container-welcome">
      <Typography variant="h4" gutterBottom>
        ¡Bienvenido {user}!
      </Typography>
      <Typography variant="body1" gutterBottom>
        {area}
      </Typography>
      <Typography variant="body2" gutterBottom>
        Empresa: {company?.name}
        <br />
        Empresa ID: {company?.id}
      </Typography>

      <Typography variant="h6" mt={2} gutterBottom>
        Centros de Costo Asociados:
      </Typography>
      <Box className="centros-costos-container">
        {userInfo?.centros_costos?.map((centro, index) => (
          <Paper key={index} className="centro-costos" elevation={2}>
            <Typography>
              <strong>ID:</strong> {centro.centro_costos_id}
            </Typography>
            <Typography>
              <strong>Tipo:</strong> {centro.tipo_centro_costos}
            </Typography>
            <Typography>
              <strong>Estado:</strong> {centro.estado}
            </Typography>
            <Typography>
              <strong>Rol:</strong> {centro.rol}
            </Typography>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

export default HomeWelcomeBoard;
