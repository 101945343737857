import React, { useState, useEffect, useMemo } from "react";
import { X, Check } from "lucide-react";
import IconButton from "@mui/material/IconButton";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import {
  CustomAutocomplete,
  CustomTextField,
} from "../../Forms/CustomAutocomplete";
import Loading from "../../../hooks/loading";
import { Link } from "react-router-dom";
import { useAlert } from "../../../hooks/useAlert";
import {
  fetchCentrosOperacion,
  fetchItemsFacturados,
  guardarDistribucionCostos,
} from "../../../services/OpenService";

const DistribucionCostos = ({
  className,
  text,
  formData,
  productOptions,
  groupedArray,
  onClose,
  caller,
  emisor,
  cufe,
  setAlertType,
  onFieldChange,
}) => {
  useEffect(() => {
    if (caller === "noAgrp" && Array.isArray(productOptions)) {
      console.log(
        "Opciones de productos recibidas para No Agrupar:",
        productOptions,
      );
      console.log("el cufe de recibidas para No Agrupar:", cufe);
      if (productOptions.length > 0) {
        console.log("Opciones disponibles:", productOptions);
        console.log("Se recibió desde las opciones de No Agrupar Items.");
      } else {
        console.warn("No hay opciones de productos disponibles.");
      }
    } else if (caller === "distribucionCostos" && !productOptions.length) {
      console.warn("No hay productos válidos para distribucionCostos.");
    }
  }, [caller, productOptions]);

  const [centrosOptions, setCentrosOptions] = useState([]);
  const [totalPercentage, setTotalPercentage] = useState(null);
  const [sections, setSections] = useState(1);
  const { triggerAlert, Alert } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [mappedProducts, setMappedProducts] = useState([]);

  useEffect(() => {
    const fetchCentros = async () => {
      try {
        const centros = await fetchCentrosOperacion();
        const mappedOptions = centros.map((c) => ({
          label: c.tipo_centro_costos,
          value: c.centro_costos_id,
        }));
        setCentrosOptions(mappedOptions);
      } catch (error) {
        console.error("Error al obtener centros de operación:", error);
      }
    };
    fetchCentros();
  }, []);

  useEffect(() => {
    const fetchItems = async () => {
      if (cufe) {
        try {
          const data = await fetchItemsFacturados(cufe);
          console.log(
            "Ítems facturados obtenidos para el CUFE:",
            data.items_facturados,
          );
          const products = data.items_facturados.map((item) => ({
            label: `${item.id} - ${item.descripcion}`,
            value: item.id,
          }));
          setMappedProducts(products);
          if (caller === "noAgrp") {
            const jsonStructure = {
              tipo: "item",
              items: data.items_facturados.map((i) => ({
                id: i.id,
                descripcion: i.descripcion,
              })),
            };
            console.log("JSON jerárquico generado:", jsonStructure);
          }
        } catch (error) {
          console.error("Error al obtener los ítems facturados:", error);
        }
      }
    };
    fetchItems();
  }, [cufe, caller]);

  const groupOptions = useMemo(() => {
    if (!groupedArray || !groupedArray.grupos) return [];
    const ops = groupedArray.grupos.map((g) => ({
      value: g.grupo_id,
      label: g.grupo_id,
      content: g,
    }));
    console.log("Opciones generadas en groupOptions:", ops);
    return ops;
  }, [groupedArray]);

  function getTotalAssignedForItem(v) {
    let t = 0;
    Object.keys(formData)
      .filter((k) => k.startsWith("selectedProduct"))
      .forEach((k) => {
        const i = k.replace("selectedProduct", "");
        if (formData[k] === v) {
          t += Number(formData[`distribucionPorcentaje${i}`] || 0);
        }
      });
    return t;
  }
  function getTotalAssignedForGroup(v) {
    let t = 0;
    Object.keys(formData)
      .filter((k) => k.startsWith("distribucionxGrupos"))
      .forEach((k) => {
        const i = k.replace("distribucionxGrupos", "");
        if (formData[k] === v) {
          t += Number(formData[`distribucionPorcentaje${i}`] || 0);
        }
      });
    return t;
  }
  function getUsedCostCentersForItem(v) {
    const arr = [];
    Object.keys(formData)
      .filter((k) => k.startsWith("selectedProduct"))
      .forEach((k) => {
        const i = k.replace("selectedProduct", "");
        if (formData[k] === v) {
          const c = formData[`centroCostos${i}`];
          if (c) arr.push(c);
        }
      });
    return arr;
  }
  function getUsedCostCentersForGroup(v) {
    const arr = [];
    Object.keys(formData)
      .filter((k) => k.startsWith("distribucionxGrupos"))
      .forEach((k) => {
        const i = k.replace("distribucionxGrupos", "");
        if (formData[k] === v) {
          const c = formData[`centroCostos${i}`];
          if (c) arr.push(c);
        }
      });
    return arr;
  }

  const handlePercentageChange = (e, sectionIndex) => {
    const iv = e.target.value.replace(/\D/g, "");
    const curr = Number(formData[`distribucionPorcentaje${sectionIndex}`] || 0);
    if (caller === "costos") {
      const sg = formData[`distribucionxGrupos${sectionIndex}`];
      if (!sg) {
        console.error(
          `No hay un grupo seleccionado para la sección ${sectionIndex + 1}.`,
        );
        return;
      }
      const groupCurrent = Object.keys(formData)
        .filter((k) => k.startsWith("distribucionPorcentaje"))
        .reduce((acc, k) => {
          const gk = k.replace("distribucionPorcentaje", "distribucionxGrupos");
          return formData[gk] === sg ? acc + Number(formData[k] || 0) : acc;
        }, 0);
      if (groupCurrent >= 100) {
        console.warn(
          `El grupo ${sg} ya alcanzó el 100%. No se pueden ingresar más valores.`,
        );
        return;
      }
      const nv = Math.min(Number(iv), 100 - groupCurrent + curr);
      const newGroupTotal = groupCurrent - curr + nv;
      if (newGroupTotal > 100) {
        console.error(
          `El porcentaje acumulado para el grupo ${sg} excede el límite de 100%. Acción detenida.`,
        );
        return;
      }
      const rem = 100 - newGroupTotal;
      onFieldChange(`distribucionPorcentaje${sectionIndex}`, nv.toString());
      setTotalPercentage((p) => (p || 0) - curr + nv);
      if (sections === sectionIndex + 1) {
        if (rem > 0) {
          setSections((prev) => prev + 1);
        }
      }
      console.log(
        `Porcentaje actualizado grupo ${sg}: ${newGroupTotal}, faltan ${rem}%`,
      );
    } else if (caller === "noAgrp") {
      const si = formData[`selectedProduct${sectionIndex}`];
      if (!si) {
        console.error(
          `No hay un item seleccionado para la sección ${sectionIndex + 1}.`,
        );
        return;
      }
      const itemCurrent = Object.keys(formData)
        .filter((k) => k.startsWith("distribucionPorcentaje"))
        .reduce((acc, k) => {
          const itKey = k.replace("distribucionPorcentaje", "selectedProduct");
          return formData[itKey] === si ? acc + Number(formData[k] || 0) : acc;
        }, 0);
      if (itemCurrent >= 100) {
        console.warn(
          `El item ${si} ya alcanzó el 100%. No se pueden ingresar más valores.`,
        );
        return;
      }
      const nv = Math.min(Number(iv), 100 - itemCurrent + curr);
      const newItemTotal = itemCurrent - curr + nv;
      if (newItemTotal > 100) {
        console.error(
          `El porcentaje acumulado para el item ${si} excede el límite de 100%. Acción detenida.`,
        );
        return;
      }
      const rem = 100 - newItemTotal;
      onFieldChange(`distribucionPorcentaje${sectionIndex}`, nv.toString());
      setTotalPercentage((p) => (p || 0) - curr + nv);
      if (sections === sectionIndex + 1) {
        const selI = Array.from({ length: sections }).map(
          (_, idx) => formData[`selectedProduct${idx}`],
        );
        const unI = (mappedProducts || []).filter(
          (o) => !selI.includes(o.value),
        );
        if (rem > 0 && unI.length >= 0) {
          setSections((prev) => prev + 1);
        }
      }
      console.log(
        `Porcentaje actualizado item ${si}: ${newItemTotal}, faltan ${rem}%`,
      );
    }
  };

  useEffect(() => {
    if (caller === "costos") {
      const allGroupsComplete = groupOptions.every((g) => {
        const gt = Object.keys(formData)
          .filter((k) => k.startsWith("distribucionPorcentaje"))
          .reduce((acc, k) => {
            const gk = k.replace(
              "distribucionPorcentaje",
              "distribucionxGrupos",
            );
            return formData[gk] === g.value
              ? acc + Number(formData[k] || 0)
              : acc;
          }, 0);
        return gt === 100;
      });
      if (allGroupsComplete) {
        console.log("Todos los grupos al 100%. Eliminando inputs vacíos.");
        setSections((prev) => {
          return Array.from({ length: prev }).filter((_, idx) => {
            const sg = formData[`distribucionxGrupos${idx}`];
            const v = Number(formData[`distribucionPorcentaje${idx}`] || 0);
            return sg && v > 0;
          }).length;
        });
      }
    } else if (caller === "noAgrp") {
      const hasAny = Object.keys(formData).some(
        (k) =>
          k.startsWith("distribucionPorcentaje") &&
          Number(formData[k] || 0) > 0,
      );
      if (!hasAny) {
        console.log(
          "No hay valores en porcentajes. Renderizando sin restricciones.",
        );
        return;
      }
      const allItemsComplete = (mappedProducts || []).every((i) => {
        const t = Object.keys(formData)
          .filter((k) => k.startsWith("distribucionPorcentaje"))
          .reduce((acc, k) => {
            const ik = k.replace("distribucionPorcentaje", "selectedProduct");
            return formData[ik] === i.value
              ? acc + Number(formData[k] || 0)
              : acc;
          }, 0);
        return t === 100;
      });
      if (allItemsComplete) {
        console.log("Todos los items al 100%. Eliminando inputs vacíos.");
        setSections((prev) => {
          return Array.from({ length: prev }).filter((_, idx) => {
            const si = formData[`selectedProduct${idx}`];
            const v = Number(formData[`distribucionPorcentaje${idx}`] || 0);
            return si && v > 0;
          }).length;
        });
      } else {
        console.log(
          "No todos los items al 100%. No se eliminan inputs vacíos.",
        );
      }
    }
  }, [formData, groupOptions, mappedProducts, caller]);

  const sectionsToRender = Array.from({ length: sections }).filter(
    (_, index) => {
      if (caller === "costos") {
        const sg = formData[`distribucionxGrupos${index}`];
        const val = Number(formData[`distribucionPorcentaje${index}`] || 0);
        const groupPct = Object.keys(formData)
          .filter((k) => k.startsWith("distribucionPorcentaje"))
          .reduce((acc, k) => {
            const gk = k.replace(
              "distribucionPorcentaje",
              "distribucionxGrupos",
            );
            return formData[gk] === sg ? acc + Number(formData[k] || 0) : acc;
          }, 0);
        const incomplete = groupOptions.some((g) => {
          const gt = Object.keys(formData)
            .filter((k) => k.startsWith("distribucionPorcentaje"))
            .reduce((a, kk) => {
              const gg = kk.replace(
                "distribucionPorcentaje",
                "distribucionxGrupos",
              );
              return formData[gg] === g.value
                ? a + Number(formData[kk] || 0)
                : a;
            }, 0);
          return gt < 100;
        });
        if (totalPercentage < 100) return true;
        if (groupPct < 100) return true;
        if (val > 0) return true;
        if (groupPct === 100 && incomplete) return true;
        return false;
      } else if (caller === "noAgrp") {
        const si = formData[`selectedProduct${index}`];
        const val = Number(formData[`distribucionPorcentaje${index}`] || 0);
        const itemPct = Object.keys(formData)
          .filter((k) => k.startsWith("distribucionPorcentaje"))
          .reduce((acc, k) => {
            const itKey = k.replace(
              "distribucionPorcentaje",
              "selectedProduct",
            );
            return formData[itKey] === si
              ? acc + Number(formData[k] || 0)
              : acc;
          }, 0);
        const incomplete = (mappedProducts || []).some((i) => {
          const it = Object.keys(formData)
            .filter((k) => k.startsWith("distribucionPorcentaje"))
            .reduce((a, kk) => {
              const ik = kk.replace(
                "distribucionPorcentaje",
                "selectedProduct",
              );
              return formData[ik] === i.value
                ? a + Number(formData[kk] || 0)
                : a;
            }, 0);
          return it < 100;
        });
        if (totalPercentage < 100) return true;
        if (itemPct < 100) return true;
        if (val > 0) return true;
        if (itemPct === 100 && incomplete) return true;
        return false;
      }
      return false;
    },
  );

  const handleFinalAprobar = async () => {
    const allFieldsFilled = sectionsToRender.every((_, index) => {
      return (
        formData[`centroCostos${index}`]?.trim() &&
        formData[`distribucionPorcentaje${index}`]?.trim()
      );
    });
    if (!allFieldsFilled) {
      alert(
        "Por favor, asegúrese de que todos los campos generados tengan valores seleccionados.",
      );
      return;
    }
    const allSelected =
      caller === "costos"
        ? groupOptions.every((o) =>
            sectionsToRender.some(
              (_, index) => formData[`distribucionxGrupos${index}`] === o.value,
            ),
          )
        : caller === "noAgrp"
          ? (mappedProducts || []).every((o) =>
              sectionsToRender.some(
                (_, index) => formData[`selectedProduct${index}`] === o.value,
              ),
            )
          : true;
    if (!allSelected) {
      alert(
        "Tiene Items/Grupos de la factura sin Seleccionar, Por favor seleccione la totalidad de los Items a distribuir por Porcentaje",
      );
      return;
    }
    let generatedJson;
    if (caller === "noAgrp") {
      const itemsMap = {};
      sectionsToRender.forEach((_, index) => {
        const pid = formData[`selectedProduct${index}`];
        const p = parseInt(formData[`distribucionPorcentaje${index}`], 10);
        const ccValue =
          centrosOptions.find(
            (opt) => opt.label === formData[`centroCostos${index}`],
          )?.value || null;
        if (!itemsMap[pid]) {
          itemsMap[pid] = { id: pid, centros_costos: [] };
        }
        itemsMap[pid].centros_costos.push({
          porcentaje: p,
          centro_costos_id: ccValue,
        });
      });
      generatedJson = { tipo: "item", items: Object.values(itemsMap) };
    } else if (caller === "costos") {
      const grupos = groupedArray?.grupos || [];
      grupos.forEach((gr) => {
        if (!gr.centros_costos) gr.centros_costos = [];
        sectionsToRender.forEach((_, index) => {
          if (formData[`distribucionxGrupos${index}`] === gr.grupo_id) {
            const ccValue =
              centrosOptions.find(
                (opt) => opt.label === formData[`centroCostos${index}`],
              )?.value || null;
            if (!gr.items) gr.items = [];
            gr.items.push({ id: formData[`selectedProduct${index}`] });
            gr.centros_costos.push({
              porcentaje: parseInt(
                formData[`distribucionPorcentaje${index}`],
                10,
              ),
              centro_costos_id: ccValue,
            });
          }
        });
      });
      generatedJson = { tipo: "grupo", grupos };
    }
    function cleanUndefinedObjects(obj) {
      if (Array.isArray(obj)) {
        return obj
          .map(cleanUndefinedObjects)
          .filter(
            (x) =>
              x &&
              typeof x === "object" &&
              Object.keys(x).length > 0 &&
              !Object.values(x).includes(undefined),
          );
      } else if (obj !== null && typeof obj === "object") {
        const cleaned = {};
        Object.entries(obj).forEach(([k, v]) => {
          const c = cleanUndefinedObjects(v);
          if (
            c !== undefined &&
            c !== null &&
            (typeof c !== "object" || Object.keys(c).length > 0)
          ) {
            cleaned[k] = c;
          }
        });
        return cleaned;
      }
      return obj !== undefined ? obj : null;
    }
    generatedJson = cleanUndefinedObjects(generatedJson);
    console.log("JSON Generado Limpio:", generatedJson);
    setIsLoading(true);
    try {
      const resp = await guardarDistribucionCostos(cufe, generatedJson);
      console.log("Respuesta de la API:", resp);
      if (resp?.message === "Distribución de costos guardada exitosamente.") {
        // Actualizar el estado local de documentos usando setLocalDocuments
        if (window.updateLocalDocuments) {
          window.updateLocalDocuments((prevDocs) =>
            prevDocs.filter((doc) => doc.cufe !== cufe),
          );
        }

        // Encontrar y animar la fila correspondiente
        const rows = document.querySelectorAll(".ReactVirtualized__Table__row");
        rows.forEach((row) => {
          if (row.innerHTML.includes(cufe)) {
            row.classList.add("row-fade-out");
          }
        });

        triggerAlert("invoiceApproved", {});
        setTimeout(() => {
          console.log("Cerrando componente después de la aprobación...");
          setIsLoading(false);
          onClose();
        }, 2000);
      } else {
        console.warn("Respuesta inesperada del API:", resp);
        alert(
          "La distribución de costos se guardó, pero hubo un problema. Verifique los datos.",
        );
      }
    } catch (error) {
      console.error("Error al llamar al API service:", error);
      alert(
        "Hubo un error al guardar la distribución de costos. Inténtelo de nuevo.",
      );
      setIsLoading(false);
      return;
    }
    if (caller === "costos") {
      console.log("Se aprobó para la caller 'costos'");
    } else if (caller === "noAgrp") {
      console.log("Se aprobó para la caller 'noAgrp'");
    } else {
      console.log("Caller no identificado");
    }
    setIsLoading(false);
  };

  return (
    <div className="tramite-factura-container">
      {isLoading && <Loading />}
      <div className="form-header-alerts">
        <p className={className}>{text}</p>
        {caller === "noAgrp" ? (
          <Link
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
          >
            <IconButton aria-label="back" className="back-button">
              <KeyboardReturnIcon />
            </IconButton>
          </Link>
        ) : caller === "costos" ? (
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              setAlertType("tramiteFactura");
            }}
          >
            <IconButton aria-label="back" className="back-button">
              <KeyboardReturnIcon />
            </IconButton>
          </Link>
        ) : null}
      </div>
      <div className="cont-factura">
        <div className="cont-factura-2">
          <p className={className}>EMISOR: {emisor}</p>
          <div className="three-column-grid">
            <p className={className}>
              {caller === "costos"
                ? "Grupos"
                : caller === "noAgrp"
                  ? "Items"
                  : ""}
            </p>
            <p className={className}>Centro de Costos</p>
            <p className={className}>{totalPercentage}% = 100%</p>
          </div>
          {sectionsToRender.map((_, index) => (
            <div className="three-column-grid" key={index}>
              {caller === "costos" && (
                <CustomAutocomplete
                  className="item-3"
                  options={groupOptions
                    .filter(
                      (g) =>
                        g.value === formData[`distribucionxGrupos${index}`] ||
                        getTotalAssignedForGroup(g.value) < 100,
                    )
                    .map((o) => ({ label: o.label, value: o.value }))}
                  value={
                    groupOptions.find(
                      (o) =>
                        o.value === formData[`distribucionxGrupos${index}`],
                    ) || null
                  }
                  onChange={(event, value) => {
                    if (value) {
                      onFieldChange(`distribucionxGrupos${index}`, value.value);
                    }
                  }}
                  label={`Seleccione Grupo ${index + 1}`}
                  required={true}
                />
              )}
              {caller === "noAgrp" && (
                <CustomAutocomplete
                  className="item-3"
                  options={(mappedProducts || []).filter(
                    (i) =>
                      i.value === formData[`selectedProduct${index}`] ||
                      getTotalAssignedForItem(i.value) < 100,
                  )}
                  value={
                    (mappedProducts || []).find(
                      (o) => o.value === formData[`selectedProduct${index}`],
                    ) || null
                  }
                  onChange={(event, value) => {
                    if (value) {
                      onFieldChange(`selectedProduct${index}`, value.value);
                    } else {
                      onFieldChange(`selectedProduct${index}`, null);
                    }
                  }}
                  label={`Seleccione Item ${index + 1}`}
                  required={true}
                />
              )}
              <CustomAutocomplete
                className="item-3"
                options={(() => {
                  if (caller === "costos") {
                    const sg = formData[`distribucionxGrupos${index}`];
                    if (!sg) return centrosOptions;
                    const used = getUsedCostCentersForGroup(sg);
                    return centrosOptions.filter(
                      (c) =>
                        c.label === formData[`centroCostos${index}`] ||
                        !used.includes(c.label),
                    );
                  } else if (caller === "noAgrp") {
                    const si = formData[`selectedProduct${index}`];
                    if (!si) return centrosOptions;
                    const used = getUsedCostCentersForItem(si);
                    return centrosOptions.filter(
                      (c) =>
                        c.label === formData[`centroCostos${index}`] ||
                        !used.includes(c.label),
                    );
                  }
                  return centrosOptions;
                })()}
                value={
                  centrosOptions.find(
                    (o) => o.label === formData[`centroCostos${index}`],
                  ) || null
                }
                onChange={(event, value) => {
                  onFieldChange(`centroCostos${index}`, value?.label || "");
                }}
                label={`Seleccione Centro de Costos ${index + 1}`}
                required={true}
              />
              <CustomTextField
                id={`distribucionPorcentaje${index}`}
                label="Porcentaje"
                className="item-2"
                autoComplete="off"
                value={formData[`distribucionPorcentaje${index}`] || ""}
                onChange={(e) => handlePercentageChange(e, index)}
                required={true}
              />
            </div>
          ))}
        </div>
        <Alert />
      </div>
      <div className="button-container-tramite">
        <button onClick={onClose} className="alert-button red">
          <X className="icon" />
          Cerrar
        </button>
        <button className="alert-button green" onClick={handleFinalAprobar}>
          <Check className="icon" />
          Aprobar
        </button>
      </div>
    </div>
  );
};

export default DistribucionCostos;
