import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/stylesOficial.css";
import { fetchEmpresas } from "../services/empresaService";
import Loading from "../hooks/loading";
import { useGlobalContext } from "../context/GlobalContext";
import NotificationList from "../hooks/ListarNotificacion"; // Asegúrate de que la ruta sea correcta

const Home = () => {
  const { setCompany, isUserLoggedIn, isLoadingUserInfo, setShouldReload } =
    useGlobalContext();
  const [empresas, setEmpresas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const getEmpresas = async () => {
      try {
        const data = await fetchEmpresas();
        setEmpresas(data);
        setLoading(false);
      } catch (err) {
        setError("No se pudieron cargar las empresas.");
        setLoading(false);
      }
    };

    getEmpresas();
  }, []);

  useEffect(() => {
    if (!isLoadingUserInfo && !isUserLoggedIn) {
      console.log("Usuario no autenticado. Forzando recarga.");
      setShouldReload(true); // Solicitar recarga si los datos no están sincronizados
    }
  }, [isLoadingUserInfo, isUserLoggedIn]);

  if (loading || isLoadingUserInfo) {
    return (
      <div className="containerGeneral">
        <Loading />
      </div>
    );
  }

  if (error) {
    return <div className="containerGeneral">{error}</div>;
  }

  return (
    <div className="containerGeneral">
      <div className="containerframe1">
        <h2 className="titless">Selecciona una Empresa</h2>
        <div className="buttoncontainerempresa">
          {empresas.map((empresa) => (
            <button
              key={empresa.empresa_id}
              className="companybuttonempresa"
              disabled={!isUserLoggedIn} // Deshabilita si el usuario no está autenticado
              onClick={() => {
                setCompany(empresa.empresa.razon_social, empresa.empresa_id);
                navigate("/HomeWelcomeBoard");
              }}
            >
              {empresa.empresa.razon_social}
            </button>
          ))}
        </div>
        {!isUserLoggedIn && !isLoadingUserInfo && (
          <p className="loading-message">
            El usuario no está autenticado. Por favor, espere un momento.
          </p>
        )}
      </div>

      {/* Integración del componente de notificaciones */}
      <div className="containerframenotification">
        <NotificationList />
      </div>
    </div>
  );
};

export default Home;
