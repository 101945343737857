import React, { useEffect } from "react";
import { Check } from "lucide-react";
import IconButton from "@mui/material/IconButton";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { Link } from "react-router-dom"; // Importa Link de react-router-dom
import { fetchItemsFacturados } from "../../../services/OpenService"; // Ajusta la ruta del servicio

const TramiteFactura = ({
  emisor,
  className,
  text,
  selectedProducts,
  disabledCheckboxes,
  groupedProducts,
  allDisabled,
  groupingByAllClicked,
  groupingByGroupClicked,
  handAgruparxItems,
  handAgruparTodo,
  onClose,
  cufe,
  setCaller,
  setAlertType,
  setGroupedArray, // Recibe esta prop
}) => {
  useEffect(() => {
    console.log(`Este es el CUFE recibido: ${cufe}, y el emisor: ${emisor}`);

    if (cufe) {
      // Llama al servicio para obtener ítems facturados
      fetchItemsFacturados(cufe)
        .then((data) => {
          console.log(
            "Ítems facturados para el CUFE - en distribucion de items x  grupos:",
            data.items_facturados,
          );
          // Aquí puedes manejar los datos de los ítems facturados si es necesario
        })
        .catch((error) => {
          console.error("Error al obtener ítems facturados:", error);
        });
    }
  }, [cufe, emisor]);

  const handleCostos = () => {
    // Crear la estructura jerárquica
    const groupedData = {
      tipo: "grupo",
      grupos: [],
    };

    // Transformar `groupedProducts` a la estructura requerida
    const groupedArray = Object.keys(groupedProducts).reduce(
      (acc, productId) => {
        const group = groupedProducts[productId];
        if (!acc[group]) {
          acc[group] = {
            grupo_id: group,
            items: [],
          };
        }
        acc[group].items.push({ id: productId }); // Agrega el producto como objeto con su ID
        return acc;
      },
      {},
    );

    // Convertir el objeto en un array para cumplir con la estructura jerárquica
    groupedData.grupos = Object.values(groupedArray);

    console.log(
      "Productos agrupados organizados en la estructura jerárquica:",
      groupedData,
    );

    // Actualiza el estado solo si hay cambios
    setGroupedArray((prev) => {
      if (JSON.stringify(prev) !== JSON.stringify(groupedData)) {
        return groupedData; // Solo actualiza si el estado es diferente
      }
      return prev; // No actualizar si no hay cambios
    });

    // Cambiar caller a "costos" y alertType a "distribucionCostos"
    setCaller("costos");
    setAlertType("distribucionCostos");
  };

  return (
    <div className="tramite-factura-container">
      {/* Contenedor específico para tramiteFactura */}
      <div className="form-header-alerts">
        <p className={className}>{text}</p>
        <Link
          onClick={(event) => {
            event.preventDefault(); // Evita la redirección
            onClose(); // Llama correctamente a la función pasada como prop
          }}
        >
          <IconButton aria-label="back" className="back-button">
            <KeyboardReturnIcon />
          </IconButton>
        </Link>
      </div>
      <div className="cont-factura">
        <div className="cont-factura-2">
          <p className={className}>EMISOR: {emisor}</p>

          {/* Mostrar los productos seleccionados */}
          {selectedProducts && selectedProducts.length > 0 ? (
            selectedProducts.map((product, index) => (
              <div key={index} className="three-column-grid">
                {" "}
                {/* Usamos `index` en el `key` */}
                <label
                  className={`checkbox-label ${
                    disabledCheckboxes.includes(product.id)
                      ? "disabled-label"
                      : ""
                  }`}
                >
                  <input
                    id={product.id}
                    className="form-checkbox"
                    type="checkbox"
                    value={product.id} // Usamos el ID como valor
                    disabled={disabledCheckboxes.includes(product.id)} // Deshabilitar si ya está agrupado
                  />
                  {product.descripcion} {/* Mostramos solo la descripción */}
                </label>
                {/* Mostrar el grupo asignado */}
                {groupedProducts[product.id] && (
                  <span className="group-label">
                    {groupedProducts[product.id]}
                  </span>
                )}
              </div>
            ))
          ) : (
            <p>No hay productos seleccionados.</p>
          )}
        </div>
      </div>
      <div className="button-container-tramite">
        <button
          onClick={handleCostos}
          className={`alert-button green ${allDisabled ? "" : "disabled-label"}`}
          disabled={!allDisabled}
        >
          <Check className="icon" />
          Costos
        </button>

        <button
          className={`alert-button green ${
            groupingByAllClicked ? "disabled-label" : ""
          }`}
          disabled={groupingByAllClicked} // Deshabilita el botón si "Agrupar Todo" fue usado
          onClick={handAgruparxItems} // Agrupa los productos seleccionados
        >
          <Check className="icon" />
          Distribuir por Grupo
        </button>

        <button
          className={`alert-button green ${
            groupingByGroupClicked ? "disabled-label" : ""
          }`}
          disabled={groupingByAllClicked} // Deshabilita si ya se hizo clic en "Agrupar Todo"
          onClick={handAgruparTodo}
        >
          <Check className="icon" />
          Agrupar Todo
        </button>
      </div>
    </div>
  );
};

export default TramiteFactura;
