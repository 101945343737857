import React, { createContext, useContext, useState } from "react";

// Crear el contexto
const TableFacturaContext = createContext();

// Hook personalizado para acceder al contexto
export const useTableFacturaContext = () => useContext(TableFacturaContext);

// Proveedor del contexto
export const TableFacturaProvider = ({ children }) => {
  const [rows, setRows] = useState([
    {
      orden: 1,
      centroCosto: null, // No asignado
      emisor: "Whirpool",
      fecha: "13/12/2024",
      valor: "$500.00",
      estadoFactura: "Pendiente",
      documento: "/detalle-orden/1",
      productos: ["Laptop", "Monitor", "Teclado"],
    },
    {
      orden: 2,
      centroCosto: null, // No asignado
      emisor: "Netflix",
      fecha: "12/12/2024",
      valor: "$250.00",
      estadoFactura: "Pendiente",
      documento: "/detalle-orden/2",
      productos: ["Smartphone", "Tablet", "Mouse"],
    },
    {
      orden: 3,
      centroCosto: null, // No asignado
      emisor: "Temuu",
      fecha: "11/12/2024",
      valor: "$700.00",
      estadoFactura: "Pendiente",
      documento: "/detalle-orden/3",
      productos: ["Monitor", "Headphones", "Webcam"],
    },
    {
      orden: 4,
      centroCosto: null, // No asignado
      emisor: "Bancolombia",
      fecha: "10/12/2024",
      valor: "$1000.00",
      estadoFactura: "Pendiente",
      documento: "/detalle-orden/4",
      productos: ["Smartwatch", "Laptop Stand", "Portable Charger"],
    },
    {
      orden: 5,
      centroCosto: null, // No asignado
      emisor: "BancoBogota",
      fecha: "09/12/2024",
      valor: "$1500.00",
      estadoFactura: "Pendiente",
      documento: "/detalle-orden/5",
      productos: ["Drone", "Gaming Headset", "Graphics Card"],
    },
  ]);

  const handleCentroCostoChange = (updatedRow) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.orden === updatedRow.orden
          ? { ...updatedRow, disabled: true }
          : row,
      ),
    );
  };

  // Filtrar solo filas asignadas a Contabilidad
  const contabilidadRows = rows.filter(
    (row) => row.centroCosto === "Contabilidad",
  );

  return (
    <TableFacturaContext.Provider
      value={{ rows, contabilidadRows, handleCentroCostoChange }}
    >
      {children}
    </TableFacturaContext.Provider>
  );
};
