// src/services/OrdenCompraService.js
import axios from "axios";
import Cookies from "js-cookie";

// Usar la variable de entorno para la URL base
const API_BASE_URL = process.env.REACT_APP_API_URL;
const token = Cookies.get("token");

export const fetchFormasPago = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}formas-pago`);
    return response.data.formas_pago;
  } catch (error) {
    console.error("Error al obtener las formas de pago:", error);
    throw error;
  }
};

export const fetchCentrosCostos = async (userInfo) => {
  if (userInfo?.centros_costos) {
    return userInfo.centros_costos; // Devuelve los centros de costo
  }
  return []; // Si no hay centros de costo, devuelve un arreglo vacío
};

export const searchProveedor = async (query) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}asociado_de_negocio/search/?search_query=${query}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error al buscar el proveedor:", error);
    throw error;
  }
};

export const enviarOrdenCompra = async (formData, files) => {
  try {
    // Adaptar los datos al formato esperado por el backend
    const adaptedFormData = {
      iva: parseFloat(formData.iva) || 0,
      fecha: new Date().toISOString().split("T")[0],
      items: Array.isArray(formData.items)
        ? formData.items.map((item) => ({
            cantidad: parseFloat(item.cant) || 0,
            unidad_de_medida: item.u_medida || "Und",
            descripcion: item.description || "",
            valor_unitario: parseFloat(item.price_unit) || 0,
            valor_total: parseFloat(item.v_total) || 0,
          }))
        : [],
      total: parseFloat(formData.total) || 0,
      subtotal: parseFloat(formData.subtotal) || 0,
      telefono: formData.telefono || "",
      proveedor: {
        asociado_de_negocio_id: formData.proveedor?.asociado_de_negocio_id || 0,
      }, // Usamos el ID directamente de formData
      estados_id: 2,
      metodoPago: formData.metodoPago || "",
      sucursales: formData.sucursales || "",
      centroCostos: formData.centroCostos || "",
      rolCentroCostos: formData.rolCentroCostos || "",
      justificacion: formData.justificacion || "",
      observaciones: formData.observaciones || "",
      fecha_anterior_compra: formData.fechaAnteriorCompra || null,
      fecha_de_entrega:
        formData.fechaEntrega?.toISOString().split("T")[0] || "",
      empresa_que_solicita: {
        empresa_id:
          JSON.parse(localStorage.getItem("selectedCompany"))?.id || 0,
      },
    };

    // Crear el objeto FormData para incluir adjuntos si existen
    const formDataToSend = new FormData();
    formDataToSend.append("orden", JSON.stringify(adaptedFormData));

    if (files && files.length > 0) {
      files.forEach((file) => {
        formDataToSend.append("files", file);
      });
    }

    // Enviar la solicitud al backend
    const response = await axios.post(
      `${API_BASE_URL}orden-compra/`,
      formDataToSend,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      },
    );

    console.log("Respuesta del servidor:", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Error al enviar la orden de compra:",
      error.response?.data || error,
    );
    throw error;
  }
};

export const fetchUltimaOrdenCompra = async (asociado_de_negocio_id) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}orden-compra/ultima/${asociado_de_negocio_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data.ultima_fecha; // Devuelve solo la fecha
  } catch (error) {
    console.error("Error al consultar la última orden de compra:", error);
    throw error;
  }
};

// Función para obtener los estados de orden de compra
export const fetchEstadosOrdenCompra = async (estadoId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}estados-orden-compra`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: estadoId ? { estado_id: estadoId } : {}, // Agrega el parámetro si está definido
    });
    return response.data; // Devuelve la lista de estados
  } catch (error) {
    console.error("Error al consultar los estados de orden de compra:", error);
    throw error;
  }
};

// Aprobar orden de compra
export const approveOrdenCompra = async (ordenCompraId) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}orden-compra/${ordenCompraId}/aprobar`,
      {}, // No se envía cuerpo en la solicitud PUT
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data; // Devuelve la respuesta de la API
  } catch (error) {
    console.error("Error al aprobar la orden de compra:", error);
    throw error;
  }
};

// Rechazar orden de compra
export const rejectOrdenCompra = async (ordenCompraId) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}orden-compra/${ordenCompraId}/rechazar`,
      {}, // No se envía cuerpo en la solicitud PUT
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data; // Devuelve la respuesta de la API
  } catch (error) {
    console.error("Error al rechazar la orden de compra:", error);
    throw error;
  }
};
