const API_URL = process.env.REACT_APP_API_URL;
import Cookies from "js-cookie";
import axios from "axios";

export const fetchTiposIdentificacion = async () => {
  try {
    const response = await fetch(`${API_URL}tipos-identificacion`);
    if (!response.ok) {
      throw new Error("Error al obtener tipos de identificación");
    }
    const result = await response.json();
    return result.tipos_identificacion || []; // Asegúrate de retornar un arreglo
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchCiiu = async () => {
  try {
    const response = await fetch(`${API_URL}ciiu/`);
    if (!response.ok) {
      throw new Error("Error al obtener la lista de CIIU");
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchPaises = async () => {
  try {
    const response = await fetch(`${API_URL}ubicacion/listado-paises/`);
    if (!response.ok) {
      throw new Error("Error al obtener la lista de países");
    }
    const data = await response.json();
    return data.paises || [];
  } catch (error) {
    console.error("Error al cargar los países:", error);
    return [];
  }
};

export const fetchCiudadesPorPais = async (pais) => {
  try {
    const response = await fetch(`${API_URL}ubicacion/${pais}`);
    if (!response.ok) {
      throw new Error("Error al obtener las ciudades");
    }
    const data = await response.json();
    const ciudades = data.departamentos.flatMap((departamento) =>
      departamento.ciudades.map((ciudad) => ({
        label: ciudad.nombre,
        value: ciudad.codigo, // Cambiar de zipcode a codigo
      })),
    );
    return ciudades;
  } catch (error) {
    console.error("Error al cargar las ciudades:", error);
    return [];
  }
};

export const fetchFormasPago = async () => {
  try {
    const response = await fetch(`${API_URL}formas-pago`);
    if (!response.ok) throw new Error("Error al obtener formas de pago");
    const data = await response.json();
    return data.formas_pago.map((item) => ({
      label: item.descripcion,
      value: item.codigo,
    }));
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchClasesProveedor = async () => {
  try {
    const response = await fetch(`${API_URL}clases-proveedor`);
    if (!response.ok) throw new Error("Error al obtener clases de proveedor");
    const data = await response.json();

    // Filtrar los datos para que solo se incluyan los códigos "001" y "004"
    const filteredData = data.clases_proveedor.filter(
      (item) => item.codigo === "001" || item.codigo === "004",
    );

    // Mapear los datos filtrados
    return filteredData.map((item) => ({
      label: item.descripcion,
      value: item.codigo,
    }));
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchBancos = async () => {
  try {
    const response = await fetch(`${API_URL}bancos`);
    if (!response.ok) throw new Error("Error al obtener bancos");
    const data = await response.json();
    return data.bancos.map((item) => ({
      label: item.nombre,
      value: item.codigo,
    }));
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchTiposProveedor = async () => {
  try {
    const response = await fetch(`${API_URL}tipos-proveedor`);
    if (!response.ok) throw new Error("Error al obtener tipos de proveedor");
    const data = await response.json();
    return data.tipos_proveedor.map((item) => ({
      label: item.descripcion,
      value: item.codigo,
    }));
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchCentrosCostos = async () => {
  try {
    const response = await fetch(`${API_URL}informativa/centros`);
    if (!response.ok) throw new Error("Error al obtener los centros de costos");
    const data = await response.json();

    // Estructura los datos en el formato requerido para la lista
    return data.data.flatMap((centro) =>
      centro.centro_costos.map((cc) => ({
        label: `${cc.tipo_centro_costos} (${cc.centro_costos_id}) - ${centro.nombre_centro_operaciones} (${centro.centro_operaciones_id})`,
        value: cc.centro_costos_id, // ID que se enviará al backend
        estado: cc.estado,
        lider: cc.lider_centro_costos,
        subLider: cc.sub_lider_centro_costos,
      })),
    );
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const enviarAsociadoNegocio = async (formData) => {
  try {
    const token = Cookies.get("token"); // Obtén el token desde las cookies

    if (!token) {
      throw new Error("No se encontró el token de autenticación.");
    }
    // Construir el payload
    const payload = {
      asociado_negocio: {
        tipo_persona: formData.isNatural ? "NATURAL" : "JURÍDICA",
        tipo_documento: formData.tipoDocumento,
        numero_identificacion: formData.numero,
        razon_social: formData.isJuridica ? formData.razonSocial : undefined,
        primer_nombre: formData.isNatural ? formData.primerNombre : undefined,
        segundo_nombre: formData.isNatural ? formData.segundoNombre : undefined,
        primer_apellido: formData.isNatural
          ? formData.primerApellido
          : undefined,
        segundo_apellido: formData.isNatural
          ? formData.segundoApellido
          : undefined,
        correo_electronico: formData.correo,
        telefono: formData.phone1,
        forma_pago: formData.metodoPago,
        es_proveedor: formData.isProveedor,
        id_centro_costos: formData.centroCostos,
        centro_operaciones: [
          {
            nombre_centro_operaciones: formData.name,
            direccion: formData.address,
            ubicacion: formData.city,
            pais: formData.country,
            zipcode: formData.zipcode || "",
            email_facturacion_electronica_proveedor: [formData.email],
            telefonos: [formData.phone1, formData.phone2].filter(Boolean),
          },
        ],
        adjuntos: Object.keys(formData.adjuntos).map((key) => ({
          ruta_archivo: formData.adjuntos[key]?.path || "",
          nombre_archivo: formData.adjuntos[key]?.name || "",
        })),
        responsabilidades: {
          autoretenedor_renta: !!formData.isAutoretenedor,
          autoretenedor_reteica: !!formData.isAutoretenedorICA,
          responsable_iva: !!formData.isResponsableIVA,
          resolucion_autoretenedor: formData.numeroResolucion || undefined,
        },
        clase_proveedor: formData.claseProveedor || "",
        tipo_proveedor: formData.tipoProveedor || "",
        proveedor_calificado: formData.isProveedorCertificado,
      },
      asociado_negocio_formato_id: 1,
      ciiu_id: formData.ciiu || null,
    };

    // Agregar información bancaria si es proveedor
    if (formData.isProveedor) {
      payload.asociado_negocio.informacion_bancaria = {
        detalle_metodo_pago: [
          {
            tipo_cuenta: formData.tipoCuenta || "",
            nombre_banco: formData.banco || "",
            numero_cuenta: formData.numeroCuenta || "",
            titular_cuenta: formData.titularCuenta || "",
          },
        ],
      };
    }

    // Construir el formulario con archivos
    const requestData = new FormData();
    requestData.append("asociado_data", JSON.stringify(payload));
    Object.keys(formData.adjuntos).forEach((key) => {
      if (formData.adjuntos[key]?.file) {
        requestData.append("files", formData.adjuntos[key].file);
      }
    });

    // Construir la URL desde el .env
    const API_URL = `${process.env.REACT_APP_API_URL}asociado_de_negocio/`;

    // Enviar la solicitud
    const response = await axios.post(API_URL, requestData, {
      headers: {
        Authorization: `Bearer ${token}`, // Incluye el token en el encabezado
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error(
      "Error al enviar el formulario:",
      error.response?.data || error.message,
    );
    throw error;
  }
};

export const verificarAsociadoPorNit = async (numeroIdentificacion) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}asociado_de_negocio/existe/${numeroIdentificacion}`,
    );
    return response.data; // Retorna true si existe, false si no
  } catch (error) {
    console.error(
      "Error al verificar el asociado:",
      error.response?.data || error.message,
    );
    return false; // Devuelve false si ocurre un error
  }
};

export const fetchOrdenesCompra = async (
  centrosCostos,
  fechaGenerada = "",
  fechaEntrega = "",
) => {
  try {
    // Construir parámetros de la URL
    const queryParams = new URLSearchParams();
    centrosCostos.forEach((centro) =>
      queryParams.append("centros_costos", centro),
    );
    if (fechaGenerada) queryParams.append("fecha_generada", fechaGenerada);
    if (fechaEntrega) queryParams.append("fecha_entrega", fechaEntrega);

    const response = await fetch(
      `${API_URL}ordenes-compra?${queryParams.toString()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (!response.ok) {
      throw new Error("Error al obtener la lista de órdenes de compra");
    }

    const data = await response.json();
    return data; // Retorna la lista de órdenes de compra
  } catch (error) {
    console.error("Error en fetchOrdenesCompra:", error.message);
    return []; // Retorna un arreglo vacío si falla
  }
};

export const fetchOrdenCompraById = async (ordenCompraId) => {
  try {
    // Realiza la petición GET al endpoint específico con el ID
    const response = await fetch(`${API_URL}orden-compra/${ordenCompraId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Error al obtener la orden de compra");
    }

    const data = await response.json();
    return data; // Retorna la orden de compra obtenida
  } catch (error) {
    console.error("Error en fetchOrdenCompraById:", error.message);
    return null; // Retorna null si falla
  }
};

// Función para buscar un asociado de negocio por su ID
export const fetchAsociadoById = async (asociadoDeNegocioId) => {
  try {
    const response = await axios.get(
      `${API_URL}asociado_de_negocio/id/${asociadoDeNegocioId}`,
    );
    return response.data; // Devuelve los datos del asociado de negocio
  } catch (error) {
    console.error("Error al buscar el asociado de negocio por ID:", error);
    throw error; // Lanza el error para que el componente lo maneje
  }
};

export const buscarProveedorPorNit = async (nit) => {
  try {
    // Validar que el NIT tenga al menos 8 caracteres
    if (!nit || nit.length < 6) {
      return { total: 0 };
    }

    const token = Cookies.get("token");
    if (!token) throw new Error("No se encontró el token de autenticación.");

    const response = await axios.get(
      `${API_URL}openetl/buscar-proveedor?buscar=${nit}`,
      { headers: { Authorization: `Bearer ${token}` } },
    );

    // Validar que la respuesta contenga datos
    const proveedor = response.data?.data?.[0];
    const total = response.data?.total || 0; // Capturar el total de resultados

    if (proveedor) {
      const razonSocial = proveedor.pro_razon_social;
      const direccion = proveedor.pro_direccion;
      const correo = proveedor.pro_correo;
      const telefono = proveedor.pro_telefono;
      const primerNombre = proveedor.pro_primer_nombre;
      const primerApellido = proveedor.pro_primer_apellido;
      const segundoApellido = proveedor.pro_segundo_apellido;
      const pais = proveedor.pais_descripcion;
      const razonSocialjuridico = proveedor.pro_razon_social;
      const cod_depto = proveedor.codigo_departamento;
      const cod_mun = proveedor.codigo_municipio;
      const ciudad = cod_depto + cod_mun;

      return {
        total, // Agregamos el total aquí
        razonSocial,
        direccion,
        correo,
        telefono,
        primerNombre,
        primerApellido,
        segundoApellido,
        razonSocialjuridico,
        pais,
        ciudad,
      };
    } else {
      console.warn("No se encontró información del proveedor.");
      return { total: 0 };
    }
  } catch (error) {
    console.error(
      "Error al buscar proveedor por NIT:",
      error.response?.data || error.message,
    );
    return { total: 0 };
  }
};
