import React, { useState, useMemo } from "react";
import IconButton from "@mui/material/IconButton";
import Dates3 from "../components/OrdenCompraForms/Dates1Consult.js";
import "../styles/pages/OrdenCompra.pages.css";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useGlobalContext } from "../context/GlobalContext.js";
import TableFactura from "../components/OrdenCompraForms/TableFacturaConsult.js";
import { Link } from "react-router-dom";

const FormOrdenCompra = () => {
  const [filters, setFilters] = useState({
    fechaordenDesde: null,
    fechaordenHasta: null,
    estadOrden: "Pendiente",
  });

  const { user, company } = useGlobalContext();

  const [formData, setFormData] = useState({
    subtotal: 0,
    iva: 19,
    total: "",
    fechaordenDesde: null,
    fechaordenHasta: null,
    tableData: [
      {
        id: 1,
        cant: "",
        u_medida: "",
        description: "",
        price_unit: "",
        v_total: "",
      },
    ],
  });

  const handleFormDataChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const Dates3Component = useMemo(
    () => (
      <Dates3
        handleFormDataChange={handleFormDataChange}
        onBuscar={(newFilters) => setFilters(newFilters)}
      />
    ),
    [],
  );

  // Función para validar el formulario
  const isFormValid = () => {
    const requiredFields = [
      "centroCostos",
      "nit",
      "senores",
      "sucursales",
      "telefono",
      "metodoPago",
      "fechaEntrega",
      "fechaAnteriordeCompra",
      "justificacion",
    ];

    for (const field of requiredFields) {
      if (
        !formData[field] ||
        (typeof formData[field] === "string" && formData[field].trim() === "")
      ) {
        return false;
      }
    }

    // Validar si al menos una fila de la tabla está completa
    const isTableValid = formData.tableData.some(
      (row) =>
        row.cant &&
        row.u_medida &&
        row.description &&
        row.price_unit &&
        row.v_total,
    );

    return isTableValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      alert(
        "Por favor, completa todos los campos requeridos antes de generar la orden.",
      );
      return;
    }
  };

  return (
    <div className="creation-form-container">
      <div className="form-header-ordencompra">
        <p>
          <strong>{company?.name}</strong>
        </p>
        <h1 className="form-title-ordencompra">
          Consultar Orden de Compra y/o Servicios
        </h1>
        <Link to="/HomeWelcomeBoard">
          <IconButton aria-label="back" className="back-button">
            <KeyboardReturnIcon />
          </IconButton>
        </Link>
      </div>
      <form className="form-content" onSubmit={handleSubmit}>
        <div className="form-date-basic---orden">
          {Dates3Component}
          <TableFactura filters={filters} />
          <div className="factura-section">
            <p>
              En este Panel podrá consultar las Ordenes de Compra que los
              usuarios han generado, y el Jefe de Proceso{" "}
              <strong>{user}</strong> podrá Aprobar o Rechazar las Ordenes de
              compra. Inicialmente de primeras apareceran las Ordenes de Compra
              que su estado se encuentren en (Pendiente).
              <br /> Pasos: 1. Si visualiza Ordenes en estado Pendiente, por
              favor dar Click en Ver Orden, para que pueda Gestionar el proceso
              de la Orden de Compra (Aprobar o Rechazar). <br />
              2. Ingresar un Rango de fechas del que quiere consultar las
              Ordenes de Compra Generadas.
              <br /> 3. En Estado Marcar el cual quiere filtrar unicamente
              dentro del Rango de fechas.
              <br /> 4. Dar Click en Buscar y renderizará las Ordenes de Compra
              en la tabla posterior.
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormOrdenCompra;
