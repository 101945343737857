import { useState, useEffect } from "react";
import Cookies from "js-cookie";

export function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!Cookies.get("token"),
  );
  const [token, setToken] = useState(Cookies.get("token"));
  const [refreshToken, setRefreshToken] = useState(Cookies.get("refreshToken"));
  const [error, setError] = useState(null);

  const URL_BASE = process.env.REACT_APP_API_URL;
  const loginUrl = `${URL_BASE}auth/login`;
  const logoutUrl = `${URL_BASE}auth/logout`;
  const forceLogoutUrl = `${URL_BASE}auth/force/logout`;
  const refreshUrl = `${URL_BASE}auth/refresh_token`;

  useEffect(() => {
    const storedToken = Cookies.get("token");
    const storedRefreshToken = Cookies.get("refreshToken");

    if (storedToken) {
      setIsAuthenticated(true);
      setToken(storedToken);
      setRefreshToken(storedRefreshToken);
    } else {
      setIsAuthenticated(false);
    }
  }, []); // Se ejecuta solo una vez al montar.
  useEffect(() => {
    if (URL_BASE) {
      console.log("Base URL for API requests:", URL_BASE);
    }
  }, [URL_BASE]);

  const setCookie = (name, value) => {
    Cookies.set(name, value, {
      expires: 7,
      secure: true,
      sameSite: "Strict",
    });
  };

  const removeCookie = (name) => {
    Cookies.remove(name);
  };

  const login = async (email, password, showAlert) => {
    try {
      const response = await fetch(loginUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.status === 409) {
        if (data.detail && data.detail.access_token) {
          setToken(data.detail.access_token);
          setCookie("token", data.detail.access_token);
          setIsAuthenticated(false);
        }
        showAlert(true);
        throw new Error(
          data.detail.message ||
            "Conflict: el usuario ya se encuentra en sesión",
        );
      }

      if (!response.ok) {
        throw new Error(data.detail || "Failed to login");
      }

      setToken(data.access_token);
      setRefreshToken(data.refresh_token);
      setIsAuthenticated(true);
      setCookie("token", data.access_token);
      setCookie("refreshToken", data.refresh_token);
      setError(null);

      // Dispatch custom event for successful login
      window.dispatchEvent(new CustomEvent("loginSuccess"));

      return true;
    } catch (error) {
      setError(error.message);
      return false;
    }
  };

  const refreshAuthToken = async () => {
    try {
      const refreshToken = Cookies.get("refreshToken");

      const response = await fetch(refreshUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${refreshToken}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        console.error("Error in response:", response.statusText);
        return false;
      }

      let data;
      try {
        data = await response.json();
      } catch (error) {
        console.error("Failed to parse JSON response:", error);
        return false;
      }

      if (data.access_token && data.refresh_token) {
        setToken(data.access_token);
        setRefreshToken(data.refresh_token);
        Cookies.set("token", data.access_token, {
          expires: 7,
          secure: true,
          sameSite: "Strict",
        });
        Cookies.set("refreshToken", data.refresh_token, {
          expires: 7,
          secure: true,
          sameSite: "Strict",
        });
        console.log("refresh token funcionando", data);
        return true;
      } else {
        console.error("Invalid token data:", data);
        return false;
      }
    } catch (error) {
      console.error("Error during token refresh:", error);
      return false;
    }
  };

  const logout = async () => {
    try {
      const token = Cookies.get("token");

      const response = await fetch(logoutUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        resetAuthState();
      } else {
        console.error("Logout failed with status:", response.status);
        if (response.status === 404) {
          resetAuthState();
        }
      }

      return response;
    } catch (error) {
      resetAuthState();
      return { ok: false, status: 500, message: "Error during logout" };
    }
  };

  const forcelogout = async () => {
    try {
      const token = Cookies.get("token");

      const response = await fetch(forceLogoutUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        resetAuthState();
      } else {
        console.error("Force logout failed with status:", response.status);
      }

      return response;
    } catch (error) {
      resetAuthState();
      return {
        ok: false,
        status: 500,
        message: "Network error during force logout",
      };
    }
  };

  const resetAuthState = () => {
    setIsAuthenticated(false);
    setToken(null);
    setRefreshToken(null);
    removeCookie("token");
    removeCookie("refreshToken");

    // Dispatch event for logout
    window.dispatchEvent(new CustomEvent("logout"));
  };

  return {
    isAuthenticated,
    login,
    logout,
    refreshAuthToken,
    forcelogout,
    token,
    refreshToken,
    error,
  };
}

export function getTokens() {
  const token = Cookies.get("token");
  const refreshToken = Cookies.get("refreshToken");
  return { token, refreshToken };
}
