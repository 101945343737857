import React, { useEffect, useState, useMemo, memo } from "react";
import { FileText } from "lucide-react";
import ReactVirtualizedTable from "../../ReactVirtualizedTable";
import PaginationComponent from "../../PaginationComponent";
import "../../../styles/components/tableFacturaInt.css";
import IconButton from "@mui/material/IconButton";
import Loading from "../../../hooks/loading";
import { CustomAutocomplete } from "../../Forms/CustomAutocomplete";
import {
  fetchPresignedFileUrl,
  fetchCentrosOperacion,
  moverDocumentoCentroCostos,
} from "../../../services/OpenService";
import { calculateTimeDifference } from "./Dates1FacturasGen"; // Importar la función de cálculo de tiempo

const TableFacturaGeneral = memo(
  ({ customColumns, documents, mappedDocumentos }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [centrosOptions, setCentrosOptions] = useState([]);
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [localDocuments, setLocalDocuments] = useState(documents);
    const rowsPerPage = 10;

    useEffect(() => {
      const fetchCentros = async () => {
        try {
          const centros = await fetchCentrosOperacion();
          const options = centros.map((centro) => ({
            label: centro.tipo_centro_costos,
            value: centro.centro_costos_id,
          }));
          setCentrosOptions(options);
        } catch (error) {
          console.error("Error al obtener los centros de operación:", error);
        }
      };

      fetchCentros();
    }, []);

    useEffect(() => {
      setLocalDocuments(documents);
    }, [documents]);

    useEffect(() => {
      console.log("Documentos filtrados recibidos en la tabla:", documents);
      console.log(
        "Todos los documentos recibidos por defecto en la tabla desde el EndPoint del primer componente:",
        mappedDocumentos,
      );
    }, [mappedDocumentos, documents]);
    useEffect(() => {
      const timer = setInterval(() => {
        setCurrentDateTime(new Date());
      }, 1000);

      return () => clearInterval(timer);
    }, []);

    const getPaginatedRows = useMemo(() => {
      const startIndex = (currentPage - 1) * rowsPerPage;
      const endIndex = startIndex + rowsPerPage;
      return localDocuments.slice(startIndex, endIndex);
    }, [currentPage, rowsPerPage, localDocuments]);

    const handleChangePage = (page) => {
      setCurrentPage(page);
    };

    const getSemaforoColor = (rowData) => {
      const tiempoTranscurrido = calculateTimeDifference(
        rowData.fecha,
        rowData.hora,
        currentDateTime,
      );

      if (!tiempoTranscurrido) return "gris"; // Si no hay tiempo transcurrido, color gris

      const { total } = tiempoTranscurrido;
      const totalHours = total.hours;

      if (totalHours <= 24) return "verde";
      if (totalHours <= 48) return "amarillo";
      return "rojo";
    };

    const handleFetchPresignedUrl = async (fileKey) => {
      try {
        setIsLoading(true);
        const url = await fetchPresignedFileUrl(fileKey);
        window.open(url, "_blank");
      } catch (error) {
        console.error("Error al obtener la URL firmada:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const handleMoveDocument = async (cufe, centroCostosId) => {
      try {
        const response = await moverDocumentoCentroCostos(cufe, centroCostosId);
        console.log("Respuesta de moverDocumentoCentroCostos:", response);
      } catch (error) {
        console.error(
          "Error al mover el documento al centro de costos:",
          error,
        );
        console.error("CUFE:", cufe);
        console.error("Centro de Costos ID:", centroCostosId);
      }
    };

    const updateDocumentLocally = (cufe, newCentro) => {
      console.log(`Centro de Costos seleccionado: ${newCentro.label}`);

      // Primero actualizamos el documento para mostrar la animación
      setLocalDocuments((prevDocs) =>
        prevDocs.map((doc) => {
          if (doc.cufe === cufe) {
            return {
              ...doc,
              dirigir: newCentro.label,
              isDisabled: true,
              selectedValue: newCentro.value,
              fadeOut: true, // Marca para la animación de desvanecimiento
            };
          }
          return doc;
        }),
      );

      // Después de la animación, removemos el documento
      setTimeout(() => {
        setLocalDocuments((prevDocs) =>
          prevDocs.filter((doc) => doc.cufe !== cufe),
        );
      }, 500); // 500ms = duración de la animación
    };

    const uniqueCentrosOptions = Array.from(
      new Map(centrosOptions.map((centro) => [centro.label, centro])).values(),
    );

    const formatValueWithThousandSeparator = (value) => {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    const customRenderers = useMemo(
      () => ({
        documento: (rowData) => (
          <IconButton
            onClick={() => handleFetchPresignedUrl(rowData.archivo)}
            title="Ver documento"
          >
            <FileText size={20} />
          </IconButton>
        ),

        dirigir: (rowData) => {
          if (!Object.prototype.hasOwnProperty.call(rowData, "isDisabled")) {
            rowData.isDisabled = false; // Inicializa la propiedad si no existe.
          }

          if (!Object.prototype.hasOwnProperty.call(rowData, "selectedValue")) {
            rowData.selectedValue = null; // Inicializa el valor seleccionado
          }

          const isGenerico = rowData.dirigir === "Generico";

          return isGenerico ? (
            <div
              className={`dirigir-container ${rowData.fadeOut ? "row-fade-out" : ""}`}
            >
              <CustomAutocomplete
                options={uniqueCentrosOptions}
                value={
                  rowData.selectedValue
                    ? uniqueCentrosOptions.find(
                        (option) => option.value === rowData.selectedValue,
                      )
                    : null
                }
                onChange={async (event, value) => {
                  if (value) {
                    try {
                      await handleMoveDocument(rowData.cufe, value.value);
                      updateDocumentLocally(rowData.cufe, value);
                    } catch (error) {
                      console.error("Error al mover el documento:", error);
                      // Aquí podrías mostrar un mensaje de error al usuario
                    }
                  }
                }}
                label={
                  rowData.isDisabled
                    ? uniqueCentrosOptions.find(
                        (option) => option.value === rowData.selectedValue,
                      )?.label || "Generico"
                    : "Generico"
                }
                disabled={rowData.isDisabled} // Deshabilita si ya está seleccionado
              />
            </div>
          ) : (
            <span style={{ color: "#757575", fontStyle: "italic" }}>
              {rowData.dirigir || "Sin información"}
            </span>
          );
        },

        alerta: (rowData) => (
          <div className={`semaforo ${getSemaforoColor(rowData)}`}>
            <span
              className={`semaforo-circulo ${getSemaforoColor(rowData)}`}
              title="Semáforo"
            ></span>
          </div>
        ),

        valor: (rowData) => (
          <span>{formatValueWithThousandSeparator(rowData.valor)}</span>
        ),
      }),
      [currentDateTime, localDocuments, centrosOptions],
    );

    return (
      <div className="table-section up">
        {isLoading && <Loading />}
        <ReactVirtualizedTable
          columns={
            customColumns || [
              { dataKey: "dirigir", label: "DIRIGIR", width: "auto" },
              { dataKey: "emisor", label: "EMISOR", width: "auto" },
              { dataKey: "numfactura", label: "#FACTURA", width: "auto" },
              { dataKey: "fecha", label: "FECHA", width: "auto" },
              { dataKey: "valor", label: "VALOR", width: "auto" },
              { dataKey: "estadoFactura", label: "ESTADO", width: "auto" },
              { dataKey: "documento", label: "PDF", width: "auto" },
              { dataKey: "alerta", label: "ALERTA", width: "auto" },
            ]
          }
          data={getPaginatedRows}
          customRenderers={customRenderers}
        />

        <PaginationComponent
          totalItems={documents.length}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          onPageChange={handleChangePage}
        />
      </div>
    );
  },
);

TableFacturaGeneral.displayName = "TableFacturaGeneral";

export default TableFacturaGeneral;
