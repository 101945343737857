import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactVirtualizedTable from "../ReactVirtualizedTable";
import PaginationComponent from "../PaginationComponent";
import { fetchOrdenesCompra } from "../../services/apiService";
import { useGlobalContext } from "../../context/GlobalContext";

const TableFactura = ({ filters }) => {
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const { userInfo, isLoadingUserInfo } = useGlobalContext();
  const centrosCostos =
    userInfo?.centros_costos?.map((centro) => centro.centro_costos_id) || [];

  useEffect(() => {
    const cargarOrdenes = async () => {
      if (isLoadingUserInfo || centrosCostos.length === 0) {
        return;
      }

      try {
        const fechaGenerada = filters.fechaordenDesde || "";
        const fechaEntrega = filters.fechaordenHasta || "";
        const estado = filters.estadOrden || "Revisión";

        const data = await fetchOrdenesCompra(
          centrosCostos,
          fechaGenerada,
          fechaEntrega,
        );

        const filteredData = data.filter(
          (item) => item.estado.toLowerCase() === estado.toLowerCase(),
        );

        const mappedData = filteredData.map((item) => ({
          orden: item.numero_orden,
          fecha_generada: item.fecha_generada,
          fecha_entrega: item.fecha_entrega,
          centro_costos: item.centro_costos,
          estado: item.estado,
          id: item.numero_orden,
        }));

        setRows(mappedData);
      } catch (error) {
        console.error("Error al cargar las órdenes de compra:", error);
      }
    };

    cargarOrdenes();
  }, [filters]); // Solo recargar cuando los filtros cambien al presionar el botón

  const getPaginatedRows = () => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return rows.slice(startIndex, endIndex);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    { dataKey: "orden", label: "ORDEN", width: "auto" },
    { dataKey: "fecha_generada", label: "FECHA GENERADA", width: "auto" },
    { dataKey: "fecha_entrega", label: "FECHA DE ENTREGA", width: "auto" },
    { dataKey: "centro_costos", label: "CENTRO DE COSTOS", width: "auto" },
    { dataKey: "estado", label: "ESTADO", width: "auto" },
    {
      dataKey: "documento",
      label: "DOCUMENTO",
      width: "auto",
      cellRenderer: ({ rowData }) => (
        <Link to={`/detalle-orden/${rowData.id}`} className="link">
          Ver Orden
        </Link>
      ),
    },
  ];

  if (isLoadingUserInfo) {
    return <div>Cargando información del usuario...</div>;
  }

  return (
    <div className="table-section up">
      <ReactVirtualizedTable
        columns={columns}
        data={getPaginatedRows()}
        customRenderers={{
          documento: (rowData) => (
            <Link to={`/detalle-orden/${rowData.id}`} className="link">
              Ver Orden
            </Link>
          ),
        }}
      />
      <PaginationComponent
        totalItems={rows.length}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onPageChange={handleChangePage}
      />
    </div>
  );
};

export default TableFactura;
