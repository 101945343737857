import React, { useState, useEffect } from "react";
import ReactVirtualizedTable from "../ReactVirtualizedTable";
import TextField from "@mui/material/TextField";

const TableFactura = ({ formData, setFormData }) => {
  const [tableData, setTableData] = useState(formData.tableData || []);

  useEffect(() => {
    setTableData(formData.tableData); // Actualiza la tabla cuando formData cambia
  }, [formData.tableData]);

  const handleInputChange = (id, field, value) => {
    const updatedTable = tableData.map((row) =>
      row.id === id && !row.disabled
        ? {
            ...row,
            [field]: value,
            ...(field === "cant" || field === "price_unit"
              ? {
                  v_total:
                    (field === "cant" ? value : row.cant) *
                    (field === "price_unit" ? value : row.price_unit),
                }
              : {}),
          }
        : row,
    );

    setTableData(updatedTable);
    setFormData((prevData) => ({ ...prevData, tableData: updatedTable }));
  };

  const columns = [
    { dataKey: "cant", label: "CANTIDAD", width: "auto" },
    { dataKey: "u_medida", label: "UNIDAD DE MEDIDA", width: "auto" },
    { dataKey: "description", label: "DESCRIPCIÓN", width: "auto" },
    {
      dataKey: "price_unit",
      label: "VALOR UNITARIO",
      numeric: true,
      width: "auto",
    },
    { dataKey: "v_total", label: "VALOR TOTAL", numeric: true, width: "auto" },
  ];

  const customRenderers = {
    cant: (row) => (
      <TextField
        type="number"
        value={row.cant}
        variant="standard"
        size="small"
        disabled={row.disabled}
        onChange={(e) => handleInputChange(row.id, "cant", e.target.value)}
      />
    ),
    u_medida: (row) => (
      <TextField
        type="text"
        value={row.u_medida}
        variant="standard"
        size="small"
        disabled={row.disabled}
        onChange={(e) => handleInputChange(row.id, "u_medida", e.target.value)}
      />
    ),
    description: (row) => (
      <TextField
        type="text"
        value={row.description}
        variant="standard"
        size="small"
        disabled={row.disabled}
        onChange={(e) =>
          handleInputChange(row.id, "description", e.target.value)
        }
      />
    ),
    price_unit: (row) => (
      <TextField
        type="number"
        value={row.price_unit}
        variant="standard"
        size="small"
        disabled={row.disabled}
        onChange={(e) =>
          handleInputChange(row.id, "price_unit", e.target.value)
        }
      />
    ),
    v_total: (row) => (
      <TextField
        type="number"
        value={row.v_total}
        variant="standard"
        size="small"
        disabled
      />
    ),
  };

  return (
    <div className="table-section">
      {tableData.length > 0 ? (
        <ReactVirtualizedTable
          columns={columns}
          data={tableData}
          customRenderers={customRenderers}
        />
      ) : (
        <p>No hay filas disponibles.</p>
      )}
    </div>
  );
};

export default TableFactura;
