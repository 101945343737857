import React, { createContext, useContext } from "react";
import { fetchTiposIdentificacion, fetchCiiu } from "../services/apiService";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const value = {
    fetchTiposIdentificacion,
    fetchCiiu,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export const useDataContext = () => useContext(DataContext);
