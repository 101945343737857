// src/components/CausacionDetails.js
import React, { useEffect, useState, useMemo } from "react";
import { X, Check } from "lucide-react";
import IconButton from "@mui/material/IconButton";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import {
  CustomAutocomplete,
  CustomTextField,
} from "../../Forms/CustomAutocomplete";
import { useNavigate } from "react-router-dom"; // Importa el hook de navegación
import {
  fetchPucCausacion,
  validarYMapearCausacion,
} from "../../../services/OpenService";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const CausacionDetails = ({
  className,
  onClose,
  cufe,
  caller,
  emisor,
  causacionResponse,
  onFieldChange,
}) => {
  const [formValues, setFormValues] = useState({
    fecha: "",
    centro_operaciones: "",
    tipo_documento: "",
    estado: "",
    notas: "",
    unidad_de_negocio: "",
    numero_factura: "",
    nit_proveedor: "",
    razon_social: "",
    item_id: "",
    grupo_id: "",
    descripcion: "",
    monto_total: "",
    tipo: "",
    monto: "",
    cuenta: "",
    descripcion_movimiento: "",
    base_gravable: "",
    centro_costos_id: "", // Agregado al estado inicial
  });
  const [causacionSugerida, setCausacionSugerida] = useState([]);
  const [showReteicaFields, setShowReteicaFields] = useState(false); // Estado inicial para controlar visibilidad
  const [showRetencionesFields, setShowRetencionesFields] = useState(false);
  const [showButton, setShowButton] = useState(false); // Estado inicial: invisible
  const navigate = useNavigate(); // Inicializa el hook de navegación
  const [responsabilidades, setResponsabilidades] = useState({
    responsable_iva: false,
    autoretenedor_renta: false,
    autoretenedor_reteica: false,
  });
  const [showImpuestosGen, setShowImpuestosGen] = useState(false); // Estado para mostrar los elementos de impuestos generales
  const [isSixColumn, setIsSixColumn] = useState(false); // Estado inicial
  const [proveedorExists, setProveedorexist] = useState({
    proveedor_existe: false,
  });
  const [isEditing, setIsEditing] = useState(false); // Estado para controlar la edición
  const [showCausar, setShowCausar] = useState(false); // Estado para controlar la visibilidad del botón Causar
  const [pucData, setPucData] = useState({
    retetica: [],
    iva: [],
    retenciones: [],
    puc: [],
  });
  // Estados para RETENCIONES
  const [retencionValue, setRetencionValue] = useState(null);
  const [retencionesTotal, setRetencionesTotal] = useState("");
  const [retencionesNaturaleza, setRetencionesNaturaleza] = useState("");
  const [retencionesBaseGravable, setRetencionesBaseGravable] = useState("");

  // Estados para RETEICA
  const [reteicaValue, setReteicaValue] = useState(null);
  const [reteicaTotal, setReteicaTotal] = useState("");
  const [reteicaNaturaleza, setReteicaNaturaleza] = useState("");
  const [reteicaBaseGravable, setReteicaBaseGravable] = useState("");

  const [isRetFilterDisabled, setIsRetFilterDisabled] = useState(false); // Control para deshabilitar RET_ const
  const [isRetIcaFilterDisabled, setIsRetIcaFilterDisabled] = useState(false); // Control para deshabilitar RET_ICA

  const [showRetencionesButton, setShowRetencionesButton] = useState(false);
  const [showReteicaButton, setShowReteicaButton] = useState(true);
  const [autocompleteValues, setAutocompleteValues] = useState({}); // Valores seleccionados
  const [retencionIdToRemove, setRetencionIdToRemove] = useState(null);
  const [reteICAIdToRemove, setReteICAIdToRemove] = useState(null);
  const [isSelectionMade, setIsSelectionMade] = useState(false); // Bandera para controlar el menú desplegable
  const [isSelectionDesple, setIsSelectionDesple] = useState(false); // Bandera para controlar el menú desplegable
  const [lastCausacionUpdate, setLastCausacionUpdate] = useState(null);

  useEffect(() => {
    const getPucData = async () => {
      try {
        const data = await fetchPucCausacion();
        setPucData(data);
      } catch (err) {
        console.error(err);
      }
    };

    if (isEditing) {
      getPucData();
    }
  }, [isEditing, fetchPucCausacion]);
  useEffect(() => {
    console.log("Caller recibido en CausacionDetails:", caller);
    console.log("CUFE recibido en CausacionDetails:", cufe);
    console.log(
      "Respuesta de causación recibida:",
      JSON.stringify(causacionResponse, null, 2),
    );

    if (!causacionResponse) return;

    // Actualiza los valores generales de la factura si existen
    setFormValues((prev) => ({
      ...prev,
      fecha: causacionResponse.fecha || prev.fecha,
      centro_operaciones:
        causacionResponse.centro_operaciones || prev.centro_operaciones,
      tipo_documento: causacionResponse.tipo_documento || prev.tipo_documento,
      estado: causacionResponse.estado || prev.estado,
      notas: causacionResponse.notas || prev.notas,
      unidad_de_negocio:
        causacionResponse.unidad_de_negocio || prev.unidad_de_negocio,
      numero_factura: causacionResponse.numero_factura || prev.numero_factura,
      nit_proveedor: causacionResponse.nit_proveedor || prev.nit_proveedor,
      razon_social: causacionResponse.razon_social || prev.razon_social,
    }));

    // Manejo del caller "item"
    if (caller === "item") {
      const pagoProveedor = causacionResponse?.causacion_sugerida.find(
        (item) => item.item_id === "PAGO_PROVEEDOR",
      );

      if (pagoProveedor) {
        const centroCostos = pagoProveedor.centros_costos[0] || {};
        const movimientoContable = centroCostos.movimientos_contables[0] || {};

        setFormValues((prev) => ({
          ...prev,
          item_id: pagoProveedor.item_id || "",
          descripcion: pagoProveedor.descripcion || "",
          monto_total: pagoProveedor.monto_total || "",
          tipo: movimientoContable.tipo || "",
          monto: centroCostos.monto || "",
          porcentaje: centroCostos.porcentaje || "",
          centro_costos_id: centroCostos.centro_costos_id || "",
          cuenta: movimientoContable.cuenta || "",
          descripcion_movimiento: movimientoContable.descripcion || "",
          base_gravable: movimientoContable.base_gravable || "",
        }));
      }
    }

    // Manejo del caller "grupo"
    if (caller === "grupo") {
      const grupo = causacionResponse?.causacion_sugerida.find(
        (item) => item.item_id === "PAGO_PROVEEDOR", // Ajusta el grupo según tu lógica
      );

      if (grupo) {
        const centroCostos = grupo.centros_costos[0] || {};
        const movimientoContable = centroCostos.movimientos_contables[0] || {};

        setFormValues((prev) => ({
          ...prev,
          grupo_id: grupo.grupo_id || "",
          descripcion: grupo.descripcion || "",
          monto_total: grupo.monto_total || "",
          tipo: movimientoContable.tipo || "",
          monto: centroCostos.monto || "",
          porcentaje: centroCostos.porcentaje || "",
          centro_costos_id: centroCostos.centro_costos_id || "",
          cuenta: movimientoContable.cuenta || "",
          descripcion_movimiento: movimientoContable.descripcion || "",
          base_gravable: movimientoContable.base_gravable || "",
        }));
      }
    }

    // Buscar los items o grupos con retención asociada
    if (causacionResponse?.causacion_sugerida) {
      const itemsConRetencion = causacionResponse.causacion_sugerida
        .map((item) => {
          const centroConRetencion = item.centros_costos.find((centro) =>
            centro.movimientos_contables.some((movimiento) => {
              const cuenta = pucData.puc.find(
                (pucItem) =>
                  pucItem.auxiliar === movimiento.cuenta &&
                  pucItem.retencion_asociada &&
                  pucItem.retencion_asociada.trim() !== "",
              );

              return cuenta;
            }),
          );

          if (centroConRetencion) {
            const movimiento = centroConRetencion.movimientos_contables.find(
              (mov) => {
                const cuenta = pucData.puc.find(
                  (pucItem) =>
                    pucItem.auxiliar === mov.cuenta &&
                    pucItem.retencion_asociada &&
                    pucItem.retencion_asociada.trim() !== "",
                );

                return cuenta;
              },
            );

            if (movimiento) {
              const cuenta = pucData.puc.find(
                (pucItem) =>
                  pucItem.auxiliar === movimiento.cuenta &&
                  pucItem.retencion_asociada &&
                  pucItem.retencion_asociada.trim() !== "",
              );

              const retencion = pucData.retenciones.find(
                (ret) => ret.auxiliar === cuenta.retencion_asociada,
              );

              return {
                id: item.item_id || item.grupo_id, // Usa el identificador adecuado según el caller
                retencion_asociada: cuenta.retencion_asociada,
                detalle_retencion: retencion || null,
              };
            }
          }

          return null;
        })
        .filter((result) => result !== null);

      console.log("Items o grupos con retención asociada:", itemsConRetencion);
    }

    // Actualiza responsabilidades del proveedor
    if (causacionResponse?.responsabilidades) {
      setResponsabilidades({
        responsable_iva: causacionResponse.responsabilidades.responsable_iva,
        autoretenedor_renta:
          causacionResponse.responsabilidades.autoretenedor_renta,
        autoretenedor_reteica:
          causacionResponse.responsabilidades.autoretenedor_reteica,
      });
    }

    // Actualiza el estado de existencia del proveedor
    if ("proveedor_existe" in causacionResponse) {
      setProveedorexist({
        proveedor_existe: causacionResponse.proveedor_existe,
      });
    }

    // Establece la lista completa de `causacion_sugerida` para mapeos adicionales
    if (causacionResponse?.causacion_sugerida) {
      setCausacionSugerida(causacionResponse.causacion_sugerida);
    }
  }, [caller, causacionResponse, pucData]);

  // Inicializa causacionSugerida una sola vez desde causacionResponse
  useEffect(() => {
    if (!lastCausacionUpdate) return;

    console.log(
      "Última actualización de causacionSugerida:",
      lastCausacionUpdate,
    );

    const pagoProveedorIndex = lastCausacionUpdate.findIndex(
      (item) => item.item_id === "PAGO_PROVEEDOR",
    );

    if (pagoProveedorIndex !== -1) {
      const nuevoMontoTotal =
        lastCausacionUpdate[pagoProveedorIndex].monto_total;

      setFormValues((prev) => {
        const montoAnterior = parseFloat(prev.monto_total || 0).toFixed(2);
        const montoNuevo = parseFloat(nuevoMontoTotal || 0).toFixed(2);

        if (montoAnterior !== montoNuevo) {
          console.log(
            `Actualizando monto_total: previo=${montoAnterior}, nuevo=${montoNuevo}`,
          );

          setCausacionSugerida((prevCausacionSugerida) => {
            const updatedCausacion = [...prevCausacionSugerida];

            updatedCausacion[pagoProveedorIndex].monto_total = montoAnterior;

            updatedCausacion[pagoProveedorIndex].centros_costos =
              updatedCausacion[pagoProveedorIndex].centros_costos.map(
                (centro) => ({
                  ...centro,
                  monto: montoAnterior,
                  movimientos_contables: centro.movimientos_contables.map(
                    (movimiento) => ({
                      ...movimiento,
                      monto: montoAnterior,
                    }),
                  ),
                }),
              );

            console.log(
              "CausacionSugerida actualizada con nuevo monto_total y montos relacionados:",
              updatedCausacion,
            );

            return updatedCausacion;
          });

          return {
            ...prev,
            monto_total: montoAnterior,
          };
        }

        return prev;
      });
    }
  }, [lastCausacionUpdate]); // Dependemos de lastCausacionUpdate

  const handleFieldChange = (fieldPath, value) => {
    setCausacionSugerida((prev) => {
      // Valida que `prev` sea un objeto o array antes de procesarlo
      if (!prev || typeof prev !== "object") {
        console.error("Estado previo no válido:", prev);
        return prev; // Retorna el mismo estado si no es válido
      }

      // Realiza una copia profunda del estado previo
      const updatedData = Array.isArray(prev) ? [...prev] : { ...prev };

      // Convierte el `fieldPath` en un array de claves para manipular el estado anidado
      const keys = fieldPath
        .replace(/\[(\d+)\]/g, ".$1") // Convierte índices de arrays a notación de punto
        .split("."); // Divide el path en partes

      let current = updatedData;
      for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];

        // Si el nivel intermedio no existe, inicialízalo como un objeto o array
        if (!current[key] || typeof current[key] !== "object") {
          current[key] = isNaN(Number(keys[i + 1])) ? {} : [];
        }

        // Crea una copia superficial de cada nivel intermedio
        current[key] = Array.isArray(current[key])
          ? [...current[key]]
          : { ...current[key] };
        current = current[key]; // Navega al siguiente nivel
      }

      // Actualiza el valor final
      current[keys[keys.length - 1]] = value;

      // Devuelve el estado actualizado
      return updatedData;
    });

    // Callback opcional para cambios
    if (onFieldChange) {
      onFieldChange(fieldPath, value);
    }
  };

  const handleImpuestoClick = (buttonName) => {
    console.log("Botón presionado:", buttonName);
    setIsEditing(false); // Salir del modo edición
    setShowCausar(true); // Mostrar el botón Causar
  };
  const handleImpuestoGeneralClick = () => {
    handleImpuestoClick("Impuesto General"); // Lógica existente
    setShowImpuestosGen(true); // Muestra la sección de impuestos generales
    setShowButton(true); // Cambia la visibilidad del botón a true
    setIsSixColumn(true); // Cambia la clase a six-column-grid
  };

  const handleEditClick = () => {
    setIsEditing(true);
    console.log("PUC completo:", pucData);
  };
  const handleReteicaClick = () => {
    setShowReteicaFields(true); // Cambia el estado para mostrar los campos dinámicos
    setShowReteicaButton(false);
    setIsSelectionDesple(false);
  };
  const handleRetencionesClick = () => {
    setShowRetencionesFields(true);
    setShowRetencionesButton(false);
    setIsSelectionMade(false);
  };

  const handleRemoveReteica = () => {
    console.log("handleRemoveReteica fue llamado.");

    const reteicaId = reteICAIdToRemove;

    setCausacionSugerida((prev) => {
      if (!prev || !Array.isArray(prev)) {
        console.warn("El estado causacionSugerida está vacío o no definido.");
        return [];
      }

      // Filtrar el elemento a eliminar usando el item_id
      const updated = prev.filter((item) => item.item_id !== reteicaId);
      console.log("Nueva estructura tras eliminación de RETEICA:", updated);

      return updated; // Actualizar el estado
    });

    setFormValues((prev) => {
      const montoAnterior = parseFloat(prev.monto_total || 0);
      const montoReteica = parseFloat(reteicaTotal || 0);
      const nuevoMontoTotal = (montoAnterior + montoReteica).toFixed(2);

      console.log(
        `Monto total actualizado tras eliminar RETEICA: ${montoAnterior} + ${montoReteica} = ${nuevoMontoTotal}`,
      );

      return {
        ...prev,
        monto_total: nuevoMontoTotal,
      };
    });

    console.log("Estructura de RETEICA eliminada:", {
      item_id: reteicaId,
      descripcion: reteicaValue?.fullItem?.descripcion || "Sin descripción",
      monto_total: reteicaTotal || "Sin monto",
      base_gravable: reteicaBaseGravable || "Sin base gravable",
    });

    // Ocultar los campos de RETEICA
    setShowReteicaFields(false);

    // Limpiar los valores de los estados relacionados con RETEICA
    setReteicaTotal(""); // Limpia el campo TOTAL
    setReteicaBaseGravable(""); // Limpia el campo BASE GRAVABLE
    setReteicaNaturaleza(""); // Limpia el campo NATURALEZA
    setReteicaValue(null); // Limpia el valor seleccionado en RETEICA
    setShowReteicaButton(true); // Muestra el botón para volver a agregar RETEICA
  };
  const handleRemoveRetencion = () => {
    console.log("handleRemoveRetencion fue llamado.");

    const retencionId = retencionIdToRemove; // Usamos el ID almacenado previamente

    setCausacionSugerida((prev) => {
      if (!prev || !Array.isArray(prev)) {
        console.warn("El estado causacionSugerida está vacío o no definido.");
        return [];
      }

      // Filtrar el elemento a eliminar usando el item_id pasado como argumento
      const updated = prev.filter((item) => item.item_id !== retencionId);
      console.log("Nueva estructura tras eliminación:", updated);

      return updated; // Actualizar el estado
    });

    setFormValues((prev) => {
      const montoAnterior = parseFloat(prev.monto_total || 0);
      const montoRetencion = parseFloat(retencionesTotal || 0);
      const nuevoMontoTotal = (montoAnterior + montoRetencion).toFixed(2);

      console.log(
        `Monto total actualizado: ${montoAnterior} + ${montoRetencion} = ${nuevoMontoTotal}`,
      );

      return {
        ...prev,
        monto_total: nuevoMontoTotal,
      };
    });

    setShowRetencionesButton(true);

    console.log("Estructura de RETENCIONES eliminada:", {
      item_id: retencionId,
      descripcion: retencionValue?.fullItem?.descripcion || "Sin descripción",
      monto_total: retencionesTotal || "Sin monto",
      base_gravable: retencionesBaseGravable || "Sin base gravable",
    });

    // Limpiar campos relacionados
    setShowRetencionesFields(false);
    setRetencionesTotal("");
    setRetencionesBaseGravable("");
    setRetencionesNaturaleza("");
    setRetencionValue(null);
    setShowRetencionesButton(true);
  };
  // Nueva función para manejar la actualización de setCausacionSugerida
  const updateCausacionWithRetencion = (
    estructura,
    montoTotal,
    previousRetencion,
  ) => {
    updateCausacionSugeridaSync((prevCausacionSugerida) => {
      const updatedCausacion = [...prevCausacionSugerida];

      if (previousRetencion) {
        console.log(
          "Dato adicional recibido (retención previa):",
          previousRetencion,
        );

        // Mapear el auxiliar con el prefijo "RET_"
        const previousAuxiliar = `RET_${previousRetencion.auxiliar}`;

        // Buscar y eliminar la estructura existente en updatedCausacion
        const indexToRemove = updatedCausacion.findIndex(
          (item) => item.item_id === previousAuxiliar,
        );

        if (indexToRemove !== -1) {
          console.log(
            `Eliminando la estructura con item_id=${previousAuxiliar}`,
          );
          updatedCausacion.splice(indexToRemove, 1); // Eliminar la estructura
        } else {
          console.warn(
            `No se encontró una estructura con item_id=${previousAuxiliar} para eliminar.`,
          );
        }
      } else {
        console.warn("No se recibió una retención previa válida.");
      }

      // Preparar la nueva estructura
      const formattedEstructura = {
        ...estructura,
        item_id: `RET_${estructura.centros_costos[0]?.movimientos_contables[0]?.cuenta}`,
        descripcion: estructura.descripcion.trim(),
        monto_total: parseFloat(estructura.monto_total).toFixed(2),
        centros_costos: estructura.centros_costos.map((centro) => ({
          ...centro,
          monto: parseFloat(centro.monto).toFixed(2),
          movimientos_contables: centro.movimientos_contables.map(
            (movimiento) => ({
              ...movimiento,
              monto: parseFloat(movimiento.monto).toFixed(2),
              descripcion: movimiento.descripcion.trim(),
            }),
          ),
        })),
      };

      console.log(
        "Nueva estructura formateada para agregar:",
        formattedEstructura,
      );

      // Agregar la nueva estructura
      updatedCausacion.push(formattedEstructura);

      const pagoProveedorIndex = updatedCausacion.findIndex(
        (item) => item.item_id === "PAGO_PROVEEDOR",
      );

      if (pagoProveedorIndex !== -1) {
        const pagoProveedor = { ...updatedCausacion[pagoProveedorIndex] };
        const nuevoMontoTotal =
          parseFloat(pagoProveedor.monto_total || 0) - montoTotal;

        pagoProveedor.monto_total = nuevoMontoTotal.toFixed(2);

        pagoProveedor.centros_costos = pagoProveedor.centros_costos.map(
          (centro) => ({
            ...centro,
            monto: nuevoMontoTotal.toFixed(2),
            movimientos_contables: centro.movimientos_contables.map(
              (movimiento) => ({
                ...movimiento,
                monto: nuevoMontoTotal.toFixed(2),
              }),
            ),
          }),
        );

        updatedCausacion[pagoProveedorIndex] = pagoProveedor;
      }

      console.log(
        "CausacionSugerida actualizada tras agregar la retención:",
        updatedCausacion,
      );

      return updatedCausacion;
    });
  };

  // Modificación en handleRetencionSelection para usar la nueva función
  const handleRetencionSelection = (retencionSelected, previousRetencion) => {
    if (!retencionSelected) {
      console.warn("RETENCIONES no seleccionado");
      return;
    }

    setIsSelectionMade(true);

    console.log(
      "Información recibida por handleRetencionSelection:",
      retencionSelected,
    );
    console.log("Dato adicional de retención previa:", previousRetencion);

    const subtotal = causacionSugerida
      .filter(
        (item) =>
          item.centros_costos.some((centro) =>
            centro.movimientos_contables.some(
              (movimiento) => movimiento.tipo === "debito",
            ),
          ) &&
          item.centros_costos.some(
            (centro) =>
              centro.porcentaje !== undefined &&
              !isNaN(centro.porcentaje) &&
              parseFloat(centro.porcentaje) > 0,
          ),
      )
      .reduce((acc, item) => acc + parseFloat(item.monto_total || 0), 0);

    console.log("Subtotal calculado para RETENCIONES:", subtotal);

    const montoTotal = subtotal * retencionSelected.fullItem.porcentaje;
    console.log("Monto total calculado para RETENCIONES:", montoTotal);

    setRetencionesTotal(montoTotal.toFixed(2));
    setRetencionesNaturaleza(retencionSelected.fullItem.naturaleza);
    setRetencionesBaseGravable(subtotal.toFixed(2));

    setFormValues((prev) => ({
      ...prev,
      monto_total: (parseFloat(prev.monto_total || 0) - montoTotal).toFixed(2),
    }));

    const estructura = {
      item_id: `RET_${retencionSelected.fullItem.auxiliar}`,
      descripcion: retencionSelected.fullItem.descripcion,
      monto_total: montoTotal.toFixed(2),
      centros_costos: [
        {
          monto: montoTotal.toFixed(2),
          movimientos_contables: [
            {
              tipo: retencionSelected.fullItem.naturaleza,
              monto: montoTotal.toFixed(2),
              cuenta: retencionSelected.fullItem.auxiliar,
              descripcion: retencionSelected.fullItem.descripcion,
              base_gravable: subtotal.toFixed(2),
            },
          ],
        },
      ],
    };

    console.log("Estructura generada para RETENCIONES:", estructura);
    setRetencionIdToRemove(estructura.item_id);
    updateCausacionWithRetencion(estructura, montoTotal, previousRetencion);
  };

  const handleAutocompleteChange = (
    event,
    value,
    movimiento,
    itemIndex,
    centroIndex,
    movIndex,
  ) => {
    if (!showImpuestosGen) return;

    setCausacionSugerida((prevData) => {
      const updatedData = [...prevData];

      if (
        !updatedData[itemIndex]?.centros_costos?.[centroIndex]
          ?.movimientos_contables?.[movIndex]
      ) {
        console.warn(
          `Datos incompletos en itemIndex=${itemIndex}, centroIndex=${centroIndex}, movIndex=${movIndex}`,
        );
        return prevData;
      }

      const movimientoActualizado =
        updatedData[itemIndex].centros_costos[centroIndex]
          .movimientos_contables[movIndex];

      if (value?.fullItem) {
        movimientoActualizado.cuenta = value.value;
        movimientoActualizado.descripcion = value.fullItem.descripcion;
      } else {
        movimientoActualizado.cuenta = "";
        movimientoActualizado.descripcion = "";
      }

      console.log("Estructura actualizada del item:", updatedData[itemIndex]);

      const itemsConRetencion = updatedData
        .map((item) => {
          const centroConRetencion = item.centros_costos.find((centro) =>
            centro.movimientos_contables.some((movimiento) => {
              const cuenta = pucData.puc.find(
                (pucItem) =>
                  pucItem.auxiliar === movimiento.cuenta &&
                  pucItem.retencion_asociada &&
                  pucItem.retencion_asociada.trim() !== "",
              );

              return cuenta;
            }),
          );

          if (centroConRetencion) {
            const movimiento = centroConRetencion.movimientos_contables.find(
              (mov) => {
                const cuenta = pucData.puc.find(
                  (pucItem) =>
                    pucItem.auxiliar === mov.cuenta &&
                    pucItem.retencion_asociada &&
                    pucItem.retencion_asociada.trim() !== "",
                );

                return cuenta;
              },
            );

            if (movimiento) {
              const cuenta = pucData.puc.find(
                (pucItem) =>
                  pucItem.auxiliar === movimiento.cuenta &&
                  pucItem.retencion_asociada &&
                  pucItem.retencion_asociada.trim() !== "",
              );

              const retencion = pucData.retenciones.find(
                (ret) => ret.auxiliar === cuenta.retencion_asociada,
              );

              return {
                item_id: item.item_id,
                retencion_asociada: cuenta.retencion_asociada,
                detalle_retencion: retencion || null,
              };
            }
          }

          return null;
        })
        .filter((result) => result !== null);

      console.log(
        "Items con retención asociada actualizados:",
        itemsConRetencion,
      );

      const retencionMaxima = itemsConRetencion.reduce(
        (max, current) => {
          const currentPorcentaje = current.detalle_retencion?.porcentaje || 0;
          return currentPorcentaje > max.detalle_retencion?.porcentaje
            ? current
            : max;
        },
        { detalle_retencion: { porcentaje: 0 } },
      );

      console.log(
        "La retención asociada con el porcentaje más alto es:",
        retencionMaxima,
      );
      // Verificación de `showRetencionesFields` antes de la validación del porcentaje
      if (!showRetencionesFields) {
        console.log(
          "El formulario de RETENCIONES no está activo. Omitiendo validación de porcentaje.",
        );
        return prevData; // Salimos de la función si no está activo
      }
      // Validamos si el porcentaje de la retención máxima es mayor
      const auxiliar = retencionMaxima.detalle_retencion?.auxiliar;
      const retencionCompleta = pucData.retenciones.find(
        (ret) => ret.auxiliar === auxiliar,
      );
      if (
        retencionCompleta &&
        retencionValue?.fullItem?.porcentaje < retencionCompleta.porcentaje
      ) {
        console.log(
          "El porcentaje de la retención máxima es mayor. Actualizando valores...",
        );
        // Actualizamos el valor del Autocomplete
        setRetencionValue({
          label: retencionCompleta.descripcion,
          value: retencionCompleta.cuenta,
          fullItem: retencionCompleta,
        });
      }
      if (
        retencionMaxima.detalle_retencion?.porcentaje >
        retencionValue?.fullItem?.porcentaje
      ) {
        console.log(
          "El porcentaje de la retención máxima es mayor. Se invoca handleRetencionSelection.",
        );

        handleRetencionSelection(
          {
            fullItem: retencionMaxima.detalle_retencion,
          },
          retencionValue?.fullItem, // Pasar el dato adicional
        );
      }

      return updatedData;
    });

    setAutocompleteValues((prev) => ({
      ...prev,
      [movimiento?.cuenta || ""]: value || null,
    }));
  };

  // Ejemplo de funciones para manejar eventos
  const subtotal = useMemo(() => {
    const calculatedSubtotal = causacionSugerida
      .filter(
        (item) =>
          // Verifica que el item tenga movimientos contables tipo "debito"
          item.centros_costos.some((centro) =>
            centro.movimientos_contables.some(
              (movimiento) => movimiento.tipo === "debito",
            ),
          ) &&
          // Verifica que el porcentaje exista, sea un número y sea mayor a 0
          item.centros_costos.some(
            (centro) =>
              centro.porcentaje !== undefined &&
              !isNaN(centro.porcentaje) &&
              parseFloat(centro.porcentaje) > 0,
          ),
      )
      .reduce((acc, item) => acc + item.monto_total, 0);

    console.log("Subtotal calculado:", calculatedSubtotal);
    return calculatedSubtotal;
  }, [causacionSugerida]);

  const handleReteicaSelection = (reteicaSelected) => {
    if (!reteicaSelected) {
      console.warn("RETEICA no seleccionado");
      return;
    }

    // Una vez seleccionado, activa la bandera para bloquear futuras selecciones
    setIsSelectionDesple(true);

    console.log(
      "Información recibida por handleReteicaSelection:",
      reteicaSelected,
    );

    // Cálculo del subtotal
    const subtotal = causacionSugerida
      .filter(
        (item) =>
          item.centros_costos.some((centro) =>
            centro.movimientos_contables.some(
              (movimiento) => movimiento.tipo === "debito",
            ),
          ) &&
          item.centros_costos.some(
            (centro) =>
              centro.porcentaje !== undefined &&
              !isNaN(centro.porcentaje) &&
              parseFloat(centro.porcentaje) > 0,
          ),
      )
      .reduce((acc, item) => acc + parseFloat(item.monto_total || 0), 0);

    console.log("Subtotal calculado para RETEICA:", subtotal);

    // Cálculo del monto total
    const montoTotal = subtotal * reteicaSelected.fullItem.porcentaje;
    console.log("Monto total calculado para RETEICA:", montoTotal);

    // Actualizar los valores de los campos
    setReteicaTotal(montoTotal.toFixed(2)); // Actualiza el TOTAL de RETEICA
    setReteicaNaturaleza(reteicaSelected.fullItem.naturaleza); // Actualiza NATURALEZA
    setReteicaBaseGravable(subtotal.toFixed(2)); // Actualiza BASE GRAVABLE

    // Actualizar el campo MONTO TOTAL del consolidado
    setFormValues((prev) => ({
      ...prev,
      monto_total: (parseFloat(prev.monto_total || 0) - montoTotal).toFixed(2), // Resta el monto total calculado
    }));

    // Construcción de la estructura JSON
    const estructura = {
      item_id: `RET_ICA_${reteicaSelected.fullItem.auxiliar}`, // ID con el auxiliar del RETEICA seleccionado
      descripcion: reteicaSelected.fullItem.descripcion, // Descripción del RETEICA seleccionado
      monto_total: montoTotal.toFixed(2),
      centros_costos: [
        {
          monto: montoTotal.toFixed(2),
          movimientos_contables: [
            {
              tipo: reteicaSelected.fullItem.naturaleza, // Naturaleza del RETEICA seleccionado
              monto: montoTotal.toFixed(2),
              cuenta: reteicaSelected.fullItem.auxiliar, // Usa auxiliar como cuenta
              descripcion: reteicaSelected.fullItem.descripcion, // Descripción del RETEICA seleccionado
              base_gravable: subtotal.toFixed(2), // Subtotal como base gravable
            },
          ],
        },
      ],
    };

    console.log("Estructura generada para RETEICA:", estructura);

    // Actualizar causacionSugerida
    setCausacionSugerida((prevCausacionSugerida) => {
      const updatedCausacion = [...prevCausacionSugerida];
      updatedCausacion.push(estructura);

      const pagoProveedorIndex = updatedCausacion.findIndex(
        (item) => item.item_id === "PAGO_PROVEEDOR",
      );

      if (pagoProveedorIndex !== -1) {
        const pagoProveedor = updatedCausacion[pagoProveedorIndex];
        const nuevoMontoTotal =
          parseFloat(pagoProveedor.monto_total || 0) - montoTotal;

        pagoProveedor.monto_total = nuevoMontoTotal.toFixed(2);

        pagoProveedor.centros_costos = pagoProveedor.centros_costos.map(
          (centro) => ({
            ...centro,
            monto: nuevoMontoTotal.toFixed(2),
            movimientos_contables: centro.movimientos_contables.map(
              (movimiento) => ({
                ...movimiento,
                monto: nuevoMontoTotal.toFixed(2),
              }),
            ),
          }),
        );
      }

      console.log(
        "CausacionSugerida actualizada tras agregar la estructura de RETEICA:",
        updatedCausacion,
      );

      return updatedCausacion; // Retorna la causación actualizada
    });

    setReteICAIdToRemove(estructura.item_id);
    console.log("Reteica seleccionada:", reteicaSelected);
  };

  useEffect(() => {
    if (!lastCausacionUpdate) return;

    console.log(
      "Última actualización de causacionSugerida:",
      lastCausacionUpdate,
    );

    // Sincronizar causacionSugerida con lastCausacionUpdate
    setCausacionSugerida(lastCausacionUpdate);
  }, [lastCausacionUpdate]);

  const updateCausacionSugeridaSync = (callback) => {
    setCausacionSugerida((prev) => {
      const updated = callback(prev);
      setLastCausacionUpdate(updated); // Sincronizar explícitamente lastCausacionUpdate
      return updated;
    });
  };

  const handleCausarClick = async () => {
    try {
      const updatedCausacion = getUpdatedCausacionResponse();

      if (!cufe) {
        throw new Error("CUFE no está definido en las props.");
      }

      if (
        !updatedCausacion.causacion_sugerida ||
        !Array.isArray(updatedCausacion.causacion_sugerida)
      ) {
        throw new Error("La estructura de causacion_sugerida no es válida.");
      }

      console.log(
        "Causación actualizada que se enviará al backend:",
        updatedCausacion,
      );
      console.log("CUFE que se enviará al backend:", cufe);

      const response = await validarYMapearCausacion(cufe, updatedCausacion);

      console.log("Respuesta del backend:", response);
      alert("Causación enviada y validada correctamente.");
    } catch (error) {
      console.error("Error al enviar la causación al backend:", error);
      alert(`Error al enviar la causación: ${error.message}`);
    }
  };

  const getUpdatedCausacionResponse = () => {
    return {
      ...causacionResponse,
      causacion_sugerida: causacionSugerida, // Incluye los cambios realizados en el front
    };
  };

  return (
    <div className="tramite-causacion-container">
      <div className="form-header-alerts">
        <p className={className}>Causación Final</p>
        <IconButton aria-label="back" className="back-button" onClick={onClose}>
          <KeyboardReturnIcon />
        </IconButton>
      </div>
      <div className="cont-factura-causacion">
        <div className="cont-factura-2">
          <p className={className}>EMISOR: {emisor}</p>
          <div className="three-column-grid">
            <CustomTextField
              id="fecha"
              label="Fecha"
              className="item-2"
              value={formValues.fecha}
              onChange={(e) => handleFieldChange("fecha", e.target.value)}
              required={true}
              disabled
            />

            <CustomTextField
              id="centro_operaciones"
              label="Centro Operaciones"
              className="item-2"
              value={formValues.centro_operaciones}
              onChange={(e) =>
                handleFieldChange("centro_operaciones", e.target.value)
              }
              required={true}
              disabled
            />

            <CustomTextField
              id="tipo_documento"
              label="Tipo de Documento"
              className="item-2"
              value={formValues.tipo_documento}
              onChange={(e) =>
                handleFieldChange("tipo_documento", e.target.value)
              }
              required={true}
              disabled
            />
            <CustomTextField
              id="estado"
              label="Estado"
              className="item-2"
              value={formValues.estado}
              onChange={(e) => handleFieldChange("estado", e.target.value)}
              required={true}
              disabled
            />
            <CustomTextField
              id="notas"
              label="Notas"
              className="item-2"
              value={formValues.notas}
              onChange={(e) => handleFieldChange("notas", e.target.value)}
              required={true}
              disabled
            />
            <CustomTextField
              id="unidad_de_negocio"
              label="Unidad de Negocio"
              className="item-2"
              value={formValues.unidad_de_negocio}
              onChange={(e) =>
                handleFieldChange("unidad_de_negocio", e.target.value)
              }
              required={true}
              disabled
            />
            <CustomTextField
              id="numero_factura"
              label="Número de Factura"
              className="item-2"
              value={formValues.numero_factura}
              onChange={(e) =>
                handleFieldChange("numero_factura", e.target.value)
              }
              required={true}
              disabled
            />
            <CustomTextField
              id="nit_proveedor"
              label="NIT - PROVEEDOR"
              className="item-2"
              value={formValues.nit_proveedor}
              onChange={(e) =>
                handleFieldChange("nit_proveedor", e.target.value)
              }
              required={true}
              disabled
            />
            <CustomTextField
              id="razon_social"
              label="RAZON SOCIAL"
              className="item-2"
              value={formValues.razon_social}
              onChange={(e) =>
                handleFieldChange("razon_social", e.target.value)
              }
              required={true}
              disabled
            />
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={responsabilidades.responsable_iva}
                disabled // Deshabilitar el checkbox para que no se pueda modificar
              />
              <span className="checkbox-text">Responsable IVA</span>
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={responsabilidades.autoretenedor_renta}
                disabled
              />
              <span className="checkbox-text">Autoretenedor en Renta</span>
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={responsabilidades.autoretenedor_reteica}
                disabled
              />
              <span className="checkbox-text">Autoretenedor Reteica</span>
            </label>
          </div>
          <div className="cont-causar-1">
            <p>Distribución</p>

            {causacionSugerida
              ?.filter((entry) => {
                if (caller === "item") {
                  // Verificar que entry.item_id existe antes de usar startsWith
                  return (
                    entry.item_id &&
                    !(
                      entry.item_id.startsWith("IVA_DESCONT") ||
                      entry.item_id.startsWith("RET_") ||
                      entry.item_id.startsWith("RET_ICA")
                    ) &&
                    entry.item_id !== "PAGO_PROVEEDOR" // Excluir también "PAGO_PROVEEDOR"
                  );
                } else if (caller === "grupo") {
                  // Verificar que entry.grupo_id existe antes de usar startsWith
                  return (
                    entry.grupo_id &&
                    !(
                      entry.grupo_id.startsWith("IVA_DESCONT") ||
                      entry.grupo_id.startsWith("RET_") ||
                      entry.grupo_id.startsWith("RET_ICA")
                    ) &&
                    entry.grupo_id !== "PAGO_PROVEEDOR" // Excluir también "PAGO_PROVEEDOR"
                  );
                }
                return false; // Por defecto, no filtra si no se especifica el caller
              })
              .map((entry, entryIndex) =>
                entry.centros_costos.map((centro, centroIndex) =>
                  centro.movimientos_contables.map((movimiento, movIndex) => (
                    <div
                      className="five-column-grid grid-causar"
                      key={`${entryIndex}-${centroIndex}-${movIndex}`}
                    >
                      {/* Autocomplete para la cuenta */}
                      <CustomAutocomplete
                        className="item-3"
                        id="cuenta"
                        options={pucData.puc.map((pucItem) => ({
                          label: `${pucItem.auxiliar} - ${pucItem.descripcion}`,
                          value: pucItem.auxiliar,
                          fullItem: pucItem,
                        }))}
                        value={
                          autocompleteValues[movimiento.cuenta] ||
                          (movimiento.cuenta
                            ? {
                                label: `${movimiento.cuenta} - ${
                                  pucData.puc.find(
                                    (p) => p.auxiliar === movimiento.cuenta,
                                  )?.descripcion || ""
                                }`,
                                value: movimiento.cuenta,
                              }
                            : null)
                        }
                        onChange={(event, value) =>
                          handleAutocompleteChange(
                            event,
                            value,
                            movimiento,
                            entryIndex,
                            centroIndex,
                            movIndex,
                          )
                        }
                        label="Cuenta"
                        required={true}
                      />

                      {/* Campo de descripción del movimiento */}
                      <CustomTextField
                        id="descripcion_movimiento"
                        label="Descripción Movimiento Contable"
                        className="item-2"
                        value={movimiento.descripcion}
                        onChange={(e) =>
                          handleFieldChange(
                            caller === "item"
                              ? `causacionSugerida[${entryIndex}].centros_costos[${centroIndex}].movimientos_contables[${movIndex}].descripcion`
                              : `causacionSugerida[${entryIndex}].centros_costos[${centroIndex}].movimientos_contables[${movIndex}].descripcion`,
                            e.target.value,
                          )
                        }
                        required={true}
                        disabled
                      />

                      {/* Campo de monto */}
                      <CustomTextField
                        id="monto"
                        label="Monto"
                        className="item-2"
                        value={centro.monto || ""}
                        onChange={(e) =>
                          handleFieldChange(
                            caller === "item"
                              ? `causacionSugerida[${entryIndex}].centros_costos[${centroIndex}].monto`
                              : `causacionSugerida[${entryIndex}].centros_costos[${centroIndex}].monto`,
                            e.target.value,
                          )
                        }
                        required={true}
                        disabled
                      />

                      {/* Campo de porcentaje */}
                      <CustomTextField
                        id="porcentaje"
                        label="Porcentaje"
                        className="item-2"
                        value={centro.porcentaje}
                        onChange={(e) =>
                          handleFieldChange(
                            caller === "item"
                              ? `causacionSugerida[${entryIndex}].centros_costos[${centroIndex}].porcentaje`
                              : `causacionSugerida[${entryIndex}].centros_costos[${centroIndex}].porcentaje`,
                            e.target.value,
                          )
                        }
                        required={true}
                        disabled
                      />

                      {/* Campo de tipo de centro de costos */}
                      <CustomTextField
                        id={`tipo_centro_costos-${centroIndex}`}
                        label="Centro Costos"
                        className="item-2"
                        value={centro.tipo_centro_costos || ""}
                        onChange={(e) =>
                          handleFieldChange(
                            caller === "item"
                              ? `causacionSugerida[${entryIndex}].centros_costos[${centroIndex}].tipo_centro_costos`
                              : `causacionSugerida[${entryIndex}].centros_costos[${centroIndex}].tipo_centro_costos`,
                            e.target.value,
                          )
                        }
                        required={true}
                        disabled
                      />
                    </div>
                  )),
                ),
              )}

            <div className="cont-causar-1">
              <p>SUBTOTAL: {subtotal}</p>
            </div>

            <div className="cont-causar-1">
              {showImpuestosGen && ( // Mostrar la sección de impuestos generales
                <>
                  <div className="bntncontainer-impuestos-gen">
                    {showReteicaButton && !showReteicaFields && (
                      <button
                        className="alert-button green"
                        onClick={handleReteicaClick}
                      >
                        <Check className="icon" />
                        RETEICA
                      </button>
                    )}

                    {/* Botón para mostrar RETENCIONES (visible solo si el bloque no está activo) */}
                    {/* Botón RETENCIONES */}
                    {showRetencionesButton && !showRetencionesFields && (
                      <button
                        className="alert-button green"
                        onClick={handleRetencionesClick}
                      >
                        <Check className="icon" />
                        RETENCIONES
                      </button>
                    )}
                  </div>
                </>
              )}
              <p>Impuestos Finales</p>
              {/* Botón para agregar nuevo */}
              {/* Bloque para RETENCIONES */}
              {showRetencionesFields && (
                <div className="six-column-grid grid-causar">
                  {/* Botón para cerrar el bloque */}
                  <button
                    className="btn-close"
                    onClick={handleRemoveRetencion}
                    style={{
                      color: "red",
                      background: "transparent",
                      border: "none",
                    }}
                  >
                    <RemoveCircleIcon />
                  </button>
                  {/* Campo RETENCIONES */}
                  <CustomAutocomplete
                    value={retencionValue}
                    onChange={(event, newValue) => {
                      if (!isSelectionMade) {
                        // Permite la selección solo si no se ha hecho previamente
                        setRetencionValue(newValue);
                        if (newValue?.fullItem)
                          handleRetencionSelection(newValue);
                      }
                    }}
                    options={
                      isSelectionMade
                        ? []
                        : pucData.retenciones.map((item) => ({
                            label: item.descripcion,
                            value: item.cuenta,
                            fullItem: item,
                          }))
                    } // Oculta las opciones si ya se hizo una selección
                    label="RETENCIONES"
                    required
                    open={!isSelectionMade} // Bloquea el desplegable si ya se seleccionó
                  />
                  {/* Campo TOTAL */}
                  <CustomTextField
                    label="TOTAL"
                    className="item-2"
                    required
                    disabled
                    value={retencionesTotal}
                  />
                  {/* Campo NATURALEZA */}
                  <CustomTextField
                    label="NATURALEZA"
                    className="item-2"
                    required
                    disabled
                    value={retencionesNaturaleza}
                  />
                  {/* Campo BASE GRAVABLE */}
                  <CustomTextField
                    label="BASE GRAVABLE"
                    className="item-2"
                    required
                    disabled
                    value={retencionesBaseGravable}
                  />
                </div>
              )}
              {showReteicaFields && (
                <div className="six-column-grid grid-causar">
                  {/* Botón para cerrar el bloque */}
                  <button
                    className="btn-close"
                    onClick={handleRemoveReteica}
                    style={{
                      color: "red",
                      background: "transparent",
                      border: "none",
                    }}
                  >
                    <RemoveCircleIcon />
                  </button>

                  {/* Campo RETEICA */}
                  <CustomAutocomplete
                    value={reteicaValue}
                    onChange={(event, newValue) => {
                      if (!isSelectionDesple) {
                        // Permite la selección solo si no se ha hecho previamente
                        setReteicaValue(newValue);
                        if (newValue?.fullItem)
                          handleReteicaSelection(newValue);
                      }
                    }}
                    options={
                      isSelectionDesple
                        ? []
                        : pucData.retetica.map((item) => ({
                            label: item.descripcion,
                            value: item.cuenta,
                            fullItem: item,
                          }))
                    } // Oculta las opciones si ya se hizo una selección
                    label="RETEICA"
                    required
                    open={!isSelectionDesple} // Bloquea el desplegable si ya se seleccionó
                  />

                  {/* Campo TOTAL */}
                  <CustomTextField
                    label="TOTAL"
                    className="item-2"
                    required
                    disabled
                    value={reteicaTotal}
                  />
                  {/* Campo NATURALEZA */}
                  <CustomTextField
                    label="NATURALEZA"
                    className="item-2"
                    required
                    disabled
                    value={reteicaNaturaleza}
                  />
                  {/* Campo BASE GRAVABLE */}
                  <CustomTextField
                    label="BASE GRAVABLE"
                    className="item-2"
                    required
                    disabled
                    value={reteicaBaseGravable}
                  />
                </div>
              )}
              {/* CAMPOS DE IMPUESTOS FINALES VISIBLES */}
              {causacionSugerida
                ?.filter((entry) => {
                  // Filtrar solo elementos que tienen item_id y excluir cualquier elemento que tenga grupo_id
                  if (!entry?.item_id) {
                    return false; // Excluir elementos sin item_id
                  }

                  if (isRetFilterDisabled && entry.item_id.startsWith("RET_")) {
                    return false; // No incluir ítems RET_ si está deshabilitado
                  }
                  if (
                    isRetIcaFilterDisabled &&
                    entry.item_id.startsWith("RET_ICA")
                  ) {
                    return false; // No incluir ítems RET_ICA si está deshabilitado
                  }
                  return (
                    entry.item_id.startsWith("IVA_DESCONT") ||
                    entry.item_id.startsWith("RET_") ||
                    entry.item_id.startsWith("RET_ICA")
                  );
                })
                .map((entry, entryIndex) => {
                  // Extraer centros de costos y movimientos contables
                  const centroCostos = Array.isArray(entry.centros_costos)
                    ? entry.centros_costos[0]
                    : null;
                  const movimientoContable = centroCostos?.movimientos_contables
                    ? centroCostos.movimientos_contables[0]
                    : null;

                  if (!centroCostos || !movimientoContable) {
                    return null; // Omitir elementos con datos incompletos
                  }

                  // Función para manejar la eliminación de items
                  const handleRemove = (id) => {
                    setShowRetencionesButton(true);
                    setCausacionSugerida((prev) => {
                      if (!Array.isArray(prev)) {
                        return []; // Si no es un array, retornar vacío
                      }

                      const updated = prev.filter(
                        (entry) => entry.item_id !== id,
                      );

                      return updated;
                    });

                    const eliminado = causacionSugerida.find(
                      (entry) => entry.item_id === id,
                    );
                    if (eliminado) {
                      // Deshabilitar el filtro para RET_ o RET_ICA según corresponda
                      if (id.startsWith("RET_")) {
                        setIsRetFilterDisabled(true);
                      }
                      if (id.startsWith("RET_ICA")) {
                        setIsRetIcaFilterDisabled(true);
                      }

                      setFormValues((prevFormValues) => {
                        const nuevoMontoTotal =
                          (parseFloat(prevFormValues.monto_total) || 0) +
                          (parseFloat(eliminado.monto_total) || 0);

                        return {
                          ...prevFormValues,
                          monto_total: nuevoMontoTotal.toFixed(2), // Actualizar monto_total
                        };
                      });
                    }
                  };

                  return (
                    <div
                      id={`fila-${entryIndex}`}
                      key={entry.item_id + entryIndex}
                      className={`${
                        entry.item_id.includes("IVA")
                          ? "five-column-grid"
                          : isSixColumn
                            ? "six-column-grid"
                            : "five-column-grid"
                      } grid-causar`}
                    >
                      {/* Botón de eliminar */}
                      {!entry.item_id.includes("IVA") && showButton && (
                        <button
                          className="btn-close"
                          onClick={() => handleRemove(entry.item_id)}
                          style={{
                            color: "red",
                            background: "transparent",
                            border: "none",
                          }}
                        >
                          <RemoveCircleIcon />
                        </button>
                      )}

                      {/* Campos visuales */}
                      <CustomTextField
                        id={`cuenta-${entryIndex}`}
                        label="Número de Cuenta"
                        className="item-2"
                        value={movimientoContable?.cuenta || ""}
                        disabled
                      />

                      <CustomTextField
                        id={`descripcion_movimiento-${entryIndex}`}
                        label="DESCRIPCION MOVIMIENTO CONTABLE"
                        className="item-2"
                        value={movimientoContable?.descripcion || ""}
                        disabled
                      />

                      <CustomTextField
                        id={`monto_total-${entryIndex}`}
                        label="TOTAL"
                        className="item-2"
                        value={entry.monto_total || ""}
                        disabled
                      />

                      <CustomTextField
                        id={`tipo-${entryIndex}`}
                        label="NATURALEZA"
                        className="item-2"
                        value={movimientoContable?.tipo || ""}
                        disabled
                      />

                      <CustomTextField
                        id={`base_gravable-${entryIndex}`}
                        label="BASE GRAVABLE"
                        className="item-2"
                        value={movimientoContable?.base_gravable || ""}
                        disabled
                      />
                    </div>
                  );
                })}
            </div>
            <div className="cont-causar-1">
              <p>Consolidado Final</p>
              <div className="four-column-grid">
                <CustomTextField
                  id="cuenta"
                  label="CUENTA"
                  className="item-2"
                  value={formValues.cuenta}
                  onChange={(e) => handleFieldChange("cuenta", e.target.value)}
                  required={true}
                  disabled
                />
                <CustomTextField
                  id="descripcion_movimiento"
                  label="DESCRIPCION MOVIMIENTO CONTABLE"
                  className="item-2"
                  value={formValues.descripcion_movimiento}
                  onChange={(e) =>
                    handleFieldChange("descripcion_movimiento", e.target.value)
                  }
                  required={true}
                  disabled
                />

                <CustomTextField
                  id="naturaleza"
                  label="NATURALEZA"
                  className="item-2"
                  value={formValues.tipo} // Cambiado de monto_total a tipo
                  onChange={(e) => handleFieldChange("tipo", e.target.value)}
                  required={true}
                  disabled
                />

                <CustomTextField
                  id="monto_total"
                  label="MONTO TOTAL"
                  className="item-2"
                  value={formValues.monto_total}
                  onChange={(e) =>
                    handleFieldChange("monto_total", e.target.value)
                  }
                  required={true}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bntncontainer-causacion">
        <button onClick={onClose} className="alert-button red">
          <X className="icon" />
          Cerrar
        </button>{" "}
        {!proveedorExists.proveedor_existe && (
          <button
            onClick={() => {
              console.log(
                "NIT del proveedor:",
                formValues.nit_proveedor || "No definido",
              );
              navigate("/FormCrearAsociados", {
                state: {
                  nit_proveedor: formValues.nit_proveedor || "No definido",
                }, // Envía el nit_proveedor
              });
            }}
            className="alert-button red"
          >
            <X className="icon" />
            Proveedor No Existe
          </button>
        )}
        {proveedorExists.proveedor_existe && !isEditing && !showCausar && (
          <button className="alert-button green">
            <Check className="icon" />
            Causar
          </button>
        )}
        {proveedorExists.proveedor_existe && !isEditing && !showCausar && (
          <button className="alert-button green" onClick={handleEditClick}>
            <Check className="icon" />
            Editar
          </button>
        )}
        {isEditing && ( // Mostrar estos botones solo si se está en modo edición
          <>
            <button
              className="alert-button green"
              onClick={handleImpuestoGeneralClick} // Llama a la nueva lógica para impuestos generales
            >
              <Check className="icon" />
              Impuesto General
            </button>
          </>
        )}
        {proveedorExists.proveedor_existe && showCausar && (
          <button className="alert-button green" onClick={handleCausarClick}>
            <Check className="icon" />
            Causar
          </button>
        )}
      </div>
    </div>
  );
};

export default CausacionDetails;
