import React, { useState, useEffect, useRef } from "react";
import { CustomSelect, CustomDateField } from "../../Forms/CustomAutocomplete";
import { Button } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {
  fetchEstadosCausacion,
  fetchDocumentosOpen,
} from "../../../services/OpenService";

const Dates1 = ({ handleFormDataChange, onBuscar, onFilterDocuments }) => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [formData, setFormData] = useState({
    fechaordenDesde: null,
    fechaordenHasta: null,
    estadOrden: "",
  });

  const [tipoDocumentoOptions, setTipoDocumentoOptions] = useState([]);
  const [documentos, setDocumentos] = useState([]);

  // Usar un flag para evitar solicitudes múltiples
  const documentosCargadosRef = useRef(false);

  useEffect(() => {
    const fetchDatosIniciales = async () => {
      try {
        if (documentosCargadosRef.current) return;

        documentosCargadosRef.current = true;

        const documentosData = await fetchDocumentosOpen();
        const mappedDocumentos = documentosData.map((doc) => ({
          dirigir:
            doc.ultimo_centro_costos?.trim().toLowerCase() !== "generico"
              ? doc.ultimo_centro_costos
              : "Generico",
          emisor: doc.razon_social || "Desconocido",
          fecha: doc.fecha,
          cufe: doc.cufe,
          valor: doc.valor,
          prefijo: doc.prefijo,
          consecutivo: doc.consecutivo,
          numfactura: `${doc.prefijo || ""}${doc.consecutivo || ""}`, // Concatenar prefijo y consecutivo
          estadoFactura: doc.estado_descripcion,
          archivo: doc.archivo,
          centro_costos_id: doc.ultimo_centro_costos,
          alerta: "",
          tramiteFactura:
            doc.estado_descripcion === "Causación sugerida"
              ? "pendiente"
              : "completado",
          centroCosto: null,
        }));

        console.log(
          "Todos los documentos mapeados desde el servicio:",
          mappedDocumentos,
        );

        setDocumentos(mappedDocumentos);

        const estados = await fetchEstadosCausacion();
        const filteredOptions = estados
          .filter((estado) => estado.estado_id !== 1 && estado.estado_id !== 2) // Filtrar estados con estado_id 1 y 2
          .map((estado) => ({
            value: estado.estado_id,
            label: estado.nombre,
            descripcion: estado.descripcion,
          }));

        console.log(
          "Opciones de estados mapeados y filtrados:",
          filteredOptions,
        );

        setTipoDocumentoOptions(filteredOptions);

        const estadoPorDefecto = filteredOptions.find(
          (option) => option.label === "Causación sugerida",
        );

        if (!estadoPorDefecto) {
          console.warn(
            "Estado por defecto no encontrado. Verifica el valor de 'label'.",
          );
        } else {
          console.log(
            "Descripción del estado seleccionado por defecto:",
            estadoPorDefecto.descripcion,
          );

          setFormData((prev) => ({
            ...prev,
            estadOrden: estadoPorDefecto.value,
          }));

          // Filtrar documentos donde estadoFactura coincide con la descripción del estado seleccionado
          const documentosFiltrados = mappedDocumentos.filter(
            (doc) =>
              doc.estadoFactura?.trim().toLowerCase() ===
              estadoPorDefecto.descripcion?.trim().toLowerCase(),
          );
          console.log(
            "Documentos filtrados por estado por defecto:",
            documentosFiltrados,
          );

          // Pasar documentos filtrados al componente padre
          if (onFilterDocuments) {
            onFilterDocuments(documentosFiltrados, mappedDocumentos);
          }
        }
      } catch (error) {
        console.error("Error al cargar datos iniciales:", error);
      }
    };

    fetchDatosIniciales();
  }, []);

  const handleFieldChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    handleFormDataChange(field, value);
  };

  const handleBuscar = () => {
    const fechaDesde = formData.fechaordenDesde
      ? new Date(formData.fechaordenDesde)
      : null;
    const fechaHasta = formData.fechaordenHasta
      ? new Date(formData.fechaordenHasta)
      : null;

    const estadoSeleccionado = tipoDocumentoOptions.find(
      (opt) => opt.value === formData.estadOrden,
    );

    if (!estadoSeleccionado) {
      console.warn("Estado seleccionado no encontrado.");
      return;
    }

    const documentosFiltrados = documentos.filter((doc) => {
      const fechaDocumento = new Date(doc.fecha);
      const cumpleRangoFechas =
        (!fechaDesde || fechaDocumento >= fechaDesde) &&
        (!fechaHasta || fechaDocumento <= fechaHasta);
      const cumpleEstado =
        doc.estadoFactura?.trim().toLowerCase() ===
        estadoSeleccionado.descripcion?.trim().toLowerCase();

      // Si no hay fechas seleccionadas, solo validar por estado
      if (!fechaDesde && !fechaHasta) {
        return cumpleEstado;
      }

      // Validar por estado y rango de fechas
      return cumpleRangoFechas && cumpleEstado;
    });

    console.log(
      "Documentos filtrados por rango de fechas y/o estado:",
      documentosFiltrados,
    );

    if (onFilterDocuments) {
      onFilterDocuments(documentosFiltrados, documentos);
    }

    if (onBuscar) onBuscar(formData);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const formatDate = (date) => {
    return date
      ? date.toLocaleString("es-CO", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
      : "";
  };

  return (
    <div className="orden-date1-section">
      <div className="two-column-grid">
        <p>
          <strong>Fecha: </strong> {formatDate(currentDateTime)}
        </p>
      </div>
      <div className="three-column-grid">
        <CustomDateField
          id="fecha-desde"
          label="Fecha Factura (Desde)"
          value={formData.fechaordenDesde}
          onChange={(newValue) =>
            handleFieldChange("fechaordenDesde", newValue)
          }
        />
        <CustomDateField
          id="fecha-hasta"
          label="Fecha Factura (Hasta)"
          value={formData.fechaordenHasta}
          onChange={(newValue) =>
            handleFieldChange("fechaordenHasta", newValue)
          }
        />
        <CustomSelect
          id="estado-orden"
          label="Estado"
          value={formData.estadOrden}
          options={tipoDocumentoOptions} // Pasar solo las opciones filtradas
          onChange={(e) => handleFieldChange("estadOrden", e.target.value)}
        />
      </div>
      <div className="generate-button-container-orden">
        <Button
          variant="contained"
          className="save-button"
          onClick={handleBuscar}
        >
          <SaveAltIcon />
          Buscar
        </Button>
      </div>
    </div>
  );
};

export default Dates1;
